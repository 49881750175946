import React, {useState} from "react";
import {useSwipeable} from "react-swipeable";
import {IconArrowLeft} from '../SVGs/IconArrowLeft';
import {IconArrowRight} from '../SVGs/IconArrowRight';
import PlayerCard from './PlayerCard';
import TeamCard from './TeamCard';

const CardCarousel = ({dataType = 'players', cardData}) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [rotateX, setRotateX] = useState(0);
    const [rotateY, setRotateY] = useState(0);
    const [shinePosition, setShinePosition] = useState({x: 50, y: 50});

    const handleClick = (index) => {
        setActiveIndex(index);
    };

    const handleSwipeLeft = () => {
        setActiveIndex((prevIndex) => (prevIndex + 1) % cardData.length);
    };

    const handleSwipeRight = () => {
        setActiveIndex((prevIndex) =>
            prevIndex === 0 ? cardData.length - 1 : prevIndex - 1
        );
    };

    // Configure swipe handlers to support both touch and mouse drag
    const swipeHandlers = useSwipeable({
        onSwipedLeft: handleSwipeLeft,
        onSwipedRight: handleSwipeRight,
        preventDefaultTouchmoveEvent: true,
        trackTouch: true,
        trackMouse: true,
        delta: 10,
    });

    const handleMouseMove = (event) => {
        const {clientX, clientY, currentTarget} = event;
        const cardRect = currentTarget.getBoundingClientRect();
        const cardWidth = cardRect.width;
        const cardHeight = cardRect.height;

        const centerX = cardRect.left + cardWidth / 2;
        const centerY = cardRect.top + cardHeight / 2;

        const deltaX = clientX - centerX;
        const deltaY = clientY - centerY;

        // Max rotation values
        const maxRotateX = 15;
        const maxRotateY = 15;

        const newRotateX = -(deltaY / cardHeight) * maxRotateX;
        const newRotateY = (deltaX / cardWidth) * maxRotateY;

        setRotateX(newRotateX);
        setRotateY(newRotateY);

        const shineX = ((clientX - cardRect.left) / cardWidth) * 100;
        const shineY = ((clientY - cardRect.top) / cardHeight) * 100;

        setShinePosition({x: shineX, y: shineY});
    };

    const handleMouseLeave = () => {
        setRotateX(0);
        setRotateY(0);
        setShinePosition({x: 50, y: 50});
    };

    const getCardStyle = (index, profilePhoto) => {
        const position = (index - activeIndex + cardData.length) % cardData.length; // Use data.length

        let transform;
        let zIndex;
        let opacity = 0.3;
        let translateY;
        let blurEffect = 'blur(4.9px)';
        let backgroundImage = '';
        let backgroundSize = '';
        let backgroundPosition = '';

        if (dataType === 'players') {
            backgroundImage = `radial-gradient(83.92% 84.44% at 50% 5.77%, rgba(17, 17, 17, 0.00) 40.5%, rgba(17, 17, 17, 0.30) 64.5%, rgba(17, 17, 17, 0.63) 80%, #111 97.99%), url(${profilePhoto})`;
            backgroundSize = 'cover';
            backgroundPosition = 'top';
        }

        if (position === 0) {
            transform = `rotateX(${rotateX}deg) rotateY(${rotateY}deg) translateX(0) scale(1)`;
            zIndex = 4;
            opacity = 1;
            translateY = "0";
            blurEffect = 'none';
        } else if (position === 1 || position === cardData.length - 1) {
            transform = position === 1 ? "translateX(60%) scale(0.9)" : "translateX(-60%) scale(0.9)";
            zIndex = 3;
            opacity = 1;
            translateY = "-15%";
            blurEffect = 'blur(2.2px)';
        } else if (position === 2 || position === cardData.length - 2) {
            transform = position === 2 ? "translateX(100%) scale(0.8)" : "translateX(-100%) scale(0.8)";
            zIndex = 2;
            opacity = 1;
            translateY = "-35%";
            blurEffect = 'blur(4.9px)';
        } else {
            transform = "translateX(0) scale(0.7)";
            zIndex = 1;
            opacity = 0;
            translateY = "-60%";
        }

        return {
            transform: `${transform} translateY(${translateY})`,
            zIndex,
            opacity,
            visibility: opacity > 0 ? "visible" : "hidden",
            backgroundImage,
            backgroundSize,
            backgroundPosition,
            filter: blurEffect,
            transition: 'transform 0.1s ease-in-out, opacity 0.4s ease-in-out, visibility 0.5s ease-in-out, box-shadow 0.05s ease-in-out',
            overflow: 'hidden',
            borderRadius: '8px',
            position: 'absolute',
            cursor: 'pointer',
        };
    };

    return (
        <div className="relative custom-block card-carousel">
            <div className="flex justify-center items-center">
                <button onClick={handleSwipeRight}
                        className="button--secondary button--carousel p-2 z-10 absolute -bottom-16 translate-x-[-30px] sm:static sm:translate-x-0 w-[48px] h-[48px] min-w-[48px]"
                >
                    <IconArrowLeft width={32} height={32}/>
                </button>
                <div className="relative w-full h-[520px] max-w-[800px] select-none" {...swipeHandlers}>
                    <div className="flex justify-center relative w-full h-full" style={{perspective: '1500px'}}>
                        {cardData.map((item, index) => (
                            <div
                                key={`${item.id}-${index}`}
                                id={item.id}
                                className={`card md:w-1/2 w-[400px] max-w-[90vw] md:max-w-[400px] ${dataType === 'teams' ? "card--team" : "card--player"} ${activeIndex === index ? "active" : ""} h-[520px] rounded-lg`}
                                onClick={() => handleClick(index)}
                                onMouseMove={index === activeIndex ? handleMouseMove : null}
                                onMouseLeave={index === activeIndex ? handleMouseLeave : null}
                                style={getCardStyle(index, dataType === 'teams' ? item.teamLogo : item.profilePhoto)}
                            >
                                {dataType === 'players' ? (
                                    <PlayerCard
                                        key={item.name}
                                        item={item}
                                        isActive={index === activeIndex}
                                        handleMouseMove={index === activeIndex ? handleMouseMove : null}
                                        handleMouseLeave={index === activeIndex ? handleMouseLeave : null}
                                        shinePosition={shinePosition}
                                    />
                                ) : (
                                    <TeamCard
                                        key={item.country}
                                        item={item}
                                        isActive={index === activeIndex}
                                        handleMouseMove={index === activeIndex ? handleMouseMove : null}
                                        handleMouseLeave={index === activeIndex ? handleMouseLeave : null}
                                        shinePosition={shinePosition}
                                    />
                                )}
                            </div>
                        ))}
                    </div>
                </div>
                <button onClick={handleSwipeLeft}
                        className="button--secondary button--carousel p-2 z-10 absolute -bottom-16 translate-x-[30px] sm:static sm:translate-x-0 w-[48px] h-[48px] min-w-[48px]"
                >
                    <IconArrowRight width={32} height={32}/>
                </button>
            </div>
        </div>
    );
};

export default CardCarousel;
