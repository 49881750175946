import React from 'react'

export const Wales = ({ width, height }) => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={ width ? width : "174" } height={ height ? height : "178" } viewBox="0 0 174 178" fill="none">
            <g clipPath="url(#clip0_4060_13931)">
                <path
                    d="M80.2921 112.26H93.6421L89.2402 121.738H84.8383L80.268 112.26H80.2921ZM45.9669 123.59L39.7129 130.854L54.4099 150.121L56.5988 147.932L45.9429 123.59H45.9669ZM127.967 123.59L117.311 147.932L119.5 150.121L134.197 130.854L127.943 123.59H127.967ZM76.7321 132.369L86.7145 137.445L97.0337 132.369L86.8829 127.462L76.7321 132.369ZM58.018 138.359C58.8118 140.692 62.5883 141.51 66.4369 140.211C70.2856 138.888 72.7391 135.929 71.9213 133.596C71.1034 131.263 67.351 130.421 63.5023 131.744C59.6537 133.067 57.2002 136.026 58.018 138.383V138.359ZM115.748 138.696C116.566 136.362 114.088 133.38 110.239 132.057C106.391 130.734 102.638 131.552 101.82 133.909C101.027 136.242 103.48 139.225 107.329 140.548C111.177 141.871 114.93 141.053 115.748 138.696ZM57.6332 153.657H68.2891L73.7013 150.097H99.7278L105.308 154.162H116.469L100.57 143.338H73.3645L57.6332 153.657Z"
                    fill="#FEBE10"/>
                <path
                    d="M84.6943 0L82.5054 6.59081L87.2441 18.9305L92.1511 6.42243L89.7938 0H96.8897L118.033 12.5081V35.167L111.274 39.7373V33.3149L101.628 23.8376L105.525 36.8508L87.4365 49.3589L69.3478 36.5141L73.0762 23.5008L63.2622 32.4489V39.3765L56.6714 34.9746V11.6662L78.1035 0H84.6943ZM22.1538 43.9468L0 53.0873V72.8597L10.4876 84.0208H19.6041L7.60108 72.0178L10.6559 62.7089L13.8792 68.4578L25.0403 79.6189L23.5249 70.8392L39.593 54.9395L50.9224 61.6987L72.2103 120.198L75.2651 118.514L56.6714 53.9292L34.0124 41.7578L19.6522 55.7814V62.8773L12.0511 54.6027L22.2019 43.9468H22.1538ZM41.4211 63.0457L33.1465 74.3751L63.2381 125.61L66.1005 123.758L44.6203 64.5851L41.397 63.0697L41.4211 63.0457ZM68.3135 45.1254L79.4746 108.003H83.7081V55.5889L68.3135 45.1014V45.1254ZM152.166 43.9468L162.317 54.6027L154.716 62.8773V55.7814L140.355 41.7578L117.696 53.9292L99.1027 118.514L102.134 120.198L123.445 61.6987L134.775 54.9395L150.843 70.8392L149.328 79.6189L160.489 68.4578L163.712 62.7089L166.743 72.0178L154.74 84.0208H163.856L174.344 72.8597V53.0873L152.19 43.9708L152.166 43.9468ZM132.899 63.0457L129.699 64.5611L108.219 123.734L111.082 125.586L141.173 74.3511L132.899 63.0216V63.0457ZM106.03 45.1254L90.6357 55.613V108.027H94.8692L106.03 45.1495V45.1254ZM80.1722 154.331L74.76 158.059L76.9489 172.419H80.1722V154.331ZM93.8589 154.331V172.419H97.0581L99.247 158.059L93.8349 154.331H93.8589ZM83.5397 154.162L85.2235 178.337H88.6151L90.4673 154.162H83.5397Z"
                    fill="white"/>
            </g>
            <defs>
                <clipPath id={`clip0_4060_13931_${width}`}>
                    <rect width="173.189" height="178" fill="white" transform="translate(0.405273)"/>
                </clipPath>
            </defs>
        </svg>
    )
}