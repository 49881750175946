import React from 'react'
import { StatsLogos } from "../SVGs/StatsLogos";
import {createRoot} from "react-dom/client";

function Footer({links}) {

  return (
      <>
          <div className="max-w-screen-xl grid grid-cols-6 lg:grid-cols-12 gap-4 items-center justify-between mx-auto px-4 pt-9 md:pt-11 text-white-100 lg:justify-between lg:items-start">

              <div className="col-span-5">
                  <div className="flex mb-14">
                      <a href="/">
                        <StatsLogos width={153} height={60}/>
                      </a>
                  </div>

                  <h2 className="mb-6">
                      Numbers Tell the Tale of Legacy
                  </h2>

                  <div className="mb-11">
                      <nav className="flex space-x-14">
                          {links && links.map((item) => (
                              <a key={item.key} href={item.href} className="h6 !text-grey-1 font-medium hover:underline">{item.title}</a>
                          ))}
                      </nav>
                  </div>

                  <div>
                      <p className="text-grey-1 mb-16">Book tickets, view upcoming matches and more<br/>
                          <a href="https://www.allblacks.com" className="underline hover:no-underline">
                              allblacks.com
                          </a>
                      </p>
                  </div>

                  <div className="text-grey-10 text-normal mb-8">
                      © New Zealand Rugby {new Date().getFullYear()}
                  </div>
              </div>
          </div>
      </>
  )
}

export default function init(mountNode) {
    const dataNode = document.getElementById('footerData');
    const footerData = JSON.parse(dataNode.textContent);

    // Note. In order for the header template to be valid JSON, need to include a fake element, hence the slice below
    if (mountNode) {
        createRoot(mountNode).render(
            <Footer links={footerData.footerItems.slice(0, -1)} />
        )
    }
}

