import React from 'react'

function MatchCenterCTA({allRivalsLink}) {

  const imageSrc = '/assets/images/blurred-team-cards-static.png';

  return (
      <>
          <div className="max-w-screen-xl grid grid-cols-6 lg:grid-cols-12 gap-4 mx-auto px-4 custom-block">
              <div className="col-span-6 lg:col-span-12 flex flex-col items-center relative">
                  <img
                      src={imageSrc}
                      className="w-full"
                      alt=""
                  />
                  <div className="text-center absolute left-0 top-[50%] -translate-y-[50%] right-0 z-1">
                      <h2>Our Rivalries</h2>
                      <p className="text-lg">Revisit some of our greatest rivalries through the years.</p>
                      <div className="flex justify-center">
                          <a href={allRivalsLink} className="button button--secondary flex items-center justify-center px-2">
                              <span className="">View rivalries</span>
                          </a>
                      </div>
                  </div>
              </div>
          </div>
      </>
  )
}

export default MatchCenterCTA
