import React from "react";
import Player from "./Player";
import Age from "./Age";
import MatchCount from "./MatchCount";
import Debut from "./Debut";
import Height from "./Height";
import WinRate from "./WinRate";

export const summaryColumns = [
        {
            accessorKey: 'playerName',
            header: 'Player',
            size: 200,
            Cell: ({renderedCellValue, row}) => (
                <Player row={row} playerName={renderedCellValue} />
            ),
            mantineTableBodyCellProps: {
                className: 'px-0 py-0',
            },
        },
        {
            accessorFn: (row) => [`${row.caps}`, `${row.testCaps}`],
            id: 'caps',
            header: 'Matches',
            mantineTableHeadCellProps: {
                className: 'pl-8 bg-transparent',
            },
            mantineTableBodyCellProps: {
                className: 'pl-8',
            },
            size: 110,
            enableGlobalFilter: false,
            Cell: ({renderedCellValue, row}) => (
                <MatchCount row={row} renderedCellValue={renderedCellValue} />
            ),
        },
        {
            accessorKey: 'points',
            header: 'Points',
            size: 60,
            enableGlobalFilter: false,
        },
        {
            accessorKey: 'status',
            header: 'Career Status',
            size: 85,
            enableGlobalFilter: false,
        },
        {
            accessorKey: 'age',
            header: 'Age',
            size: 130,
            enableGlobalFilter: false,
            mantineTableHeadCellProps: {
                className: 'pl-14 bg-transparent',
            },
            Cell: ({renderedCellValue, row}) => (
               <Age row={row} renderedCellValue={renderedCellValue} />
            ),
        },
        {
            accessorKey: 'height',
            header: 'Height',
            size: 80,
            enableGlobalFilter: false,
            mantineTableHeadCellProps: {
                align: 'left',
            },
            Cell: ({renderedCellValue, row}) => (
                <Height renderedCellValue={renderedCellValue} />
            ),
        },
    ];

export const performanceColumns = [
        {
            accessorKey: 'playerName',
            header: 'Player',
            size: 200,
            Cell: ({renderedCellValue, row}) => (
                <Player row={row} playerName={renderedCellValue} />
            ),
            mantineTableBodyCellProps: {
                className: 'px-0 py-0',
            },
        },
        {
            accessorKey: 'points',
            header: 'Points',
            size: 78,
            enableGlobalFilter: false,
            mantineTableHeadCellProps: {
                className: 'pl-8 bg-transparent',
            },
            mantineTableBodyCellProps: {
                className: 'pl-8',
            },
        },
        {
            accessorKey: 'tries',
            header: 'Tries',
            size: 78,
            enableGlobalFilter: false,
        },
        {
            accessorKey: 'conversions',
            header: 'Conversions',
            size: 78,
            enableGlobalFilter: false,
        },
        {
            accessorKey: 'dropGoal',
            header: 'Drop Goal',
            size: 78,
            enableGlobalFilter: false,
        },
        {
            accessorKey: 'penalties',
            header: 'Penalty Goal',
            size: 78,
            enableGlobalFilter: false,
        },
        {
            accessorKey: 'winRate',
            header: 'Win Rate',
            size: 78,
            enableGlobalFilter: false,
            mantineTableHeadCellProps: {
                className: 'pl-8 bg-transparent',
            },
            Cell: ({renderedCellValue, row}) => (
                <WinRate renderedCellValue={renderedCellValue} />
            ),
        },
    ];

export const careerColumns = [
    {
        accessorKey: 'playerName',
        header: 'Player',
        size: 200,
        Cell: ({renderedCellValue, row}) => (
            <Player row={row} playerName={renderedCellValue} />
        ),
        mantineTableBodyCellProps: {
            className: 'px-0 py-0',
        },
    },
    {
        accessorFn: (row) => [`${row.debut}`, `${row.debutAge}`],
        id: 'debut',
        header: 'Debut',
        size: 117,
        mantineTableHeadCellProps: {
            className: 'pl-8 bg-transparent',
        },
        mantineTableBodyCellProps: {
            className: 'pl-8',
        },
        Cell: ({renderedCellValue, row}) => (
            <Debut row={row} renderedCellValue={renderedCellValue} />
        ),
    },
    {
        accessorKey: 'career',
        header: 'Career in Black',
        size: 117,
    },
    {
        accessorKey: 'provincial',
        header: 'Provincial Team',
        size: 117,
    },
    {
        accessorKey: 'superRugby',
        header: 'Super Rugby Team',
        size: 117,
    },
];

export const historyColumns = [
    {
        accessorKey: 'playerName',
        header: 'Player',
        size: 200,
        Cell: ({renderedCellValue, row}) => (
            <Player row={row} playerName={renderedCellValue} />
        ),
        mantineTableBodyCellProps: {
            className: 'px-0 py-0',
        },
    },
    {
        accessorKey: 'born',
        header: 'Born',
        size: 117,
        mantineTableHeadCellProps: {
            classNames: { root: 'pl-8 bg-transparent'},
            // classNames: { contentWrapper: 'block truncate' },
        },
        mantineTableBodyCellProps: {
            className: 'pl-8',
        },
        enableGlobalFilter: false,
    },
    {
        accessorKey: 'birthPlace',
        header: 'Birthplace',
        size: 117,
        enableGlobalFilter: true,
        filterVariant: 'multi-select',
    },
    {
        accessorKey: 'highSchool',
        header: 'Highschool',
        size: 117,
        enableGlobalFilter: false,
    },
    {
        accessorKey: 'relatedPlayers',
        header: 'Related Players',
        size: 117,
        enableGlobalFilter: false,
    },
];
