import React from "react";
import { Box } from "@mantine/core";
import Opponent from "../../shared/Opponent";
import NZTeam from "../../shared/NZTeam";

function Match({ renderedCellValue }) {
    const [team, fullTimeScore, OppoScore, Opposition] = renderedCellValue;

    // Both white if it's a draw
    return (
        <Box className='w-full gradient-border--nested'>
            <div className="flex items-center justify-between w-full rounded-lg !py-1.5 !px-2 !bg-black-50">
                <NZTeam nzTeam={team} size='box'/>
                <div className='flex items-center px-1 md:px-2 lg:px-3'>
                    <span
                        className={`text-md md:text-xl lg:text-2xl ${parseInt(fullTimeScore) < parseInt(OppoScore) ? "text-grey-10" : "font-bold text-white"}`}>{fullTimeScore}</span>
                    <span className="text-md md:text-xl lg:text-2xl font-bold px-2 md:px-1 lg:px-3">-</span>
                    <span className={`text-md md:text-xl lg:text-2xl ${parseInt(OppoScore) < parseInt(fullTimeScore) ? "text-grey-10" : "font-bold text-white"}`}>{OppoScore}</span>
                </div>
                <Opponent opponent={Opposition} size='box'/>
            </div>
        </Box>
    )
}

export default Match;
