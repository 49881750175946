import React from "react";
import {Box} from "@mantine/core";
import {BorderGradient} from "../../SVGs/BorderGradient";

function Location({ renderedCellValue }) {

    const [city, home] = renderedCellValue;

    return (
        <Box className='flex items-center'>
            <div className='flex items-center'>
                <span>{city}</span>
                <span className='ml-1 text-xs text-grey-10 uppercase'>({home})</span>
            </div>
        </Box>
    )
}

export default Location;
