export const ALL_BLACKS_TEAM = 'All Blacks';
export const BLACK_FERNS_TEAM = 'Black Ferns';

export const PLAYER_TAB_OPTIONS = {
    SUMMARY: 'summary',
    PERFORMANCE: 'performance',
    CAREER: 'career',
    HISTORY: 'history',
}

export const PLAYER_TAB_DISPLAY_NAMES = {
    SUMMARY: 'Summary',
    PERFORMANCE: 'Performance',
    CAREER: 'Career',
    HISTORY: 'History',
}

export const MATCH_TAB_OPTIONS = {
    SUMMARY: 'summary',
    SCORING: 'scoring',
    DETAILS: 'details',
}

export const MATCH_TAB_DISPLAY_NAMES = {
    SUMMARY: 'Summary',
    SCORING: 'Scoring',
    DETAILS: 'Details',
}

export const REGION_OPTIONS = {
    'New Zealand': [
        "Northland",
        "Auckland",
        "Waikato",
        "Bay of Plenty",
        "Gisborne",
        "Hawke’s Bay",
        "Taranaki",
        "Manawatu-Whanganui",
        "Wellington",
        "Tasman",
        "Nelson",
        "Marlborough",
        "West Coast",
        "Canterbury",
        "Otago",
        "Southland"
    ],
    'International': [
        'All international'
    ]
}

export const FILTER_POSITIONS = [
    "Prop",
    "Hooker",
    "Lock",
    "Flanker",
    "Number 8",
    "Scrum Half",
    "Fly Half",
    "Centre",
    "Wing",
    "Fullback",
]


export const FILTER_RESULTS = [
    "Win",
    "Draw",
    "Loss",
]

// 1 = Win, 2 = Loss, 3 = Draw
export const FILTER_RESULTS_MAPPING = {
    "Win": 1,
    "Draw": 3,
    "Loss": 2,
}

export const FILTER_MATCH_TYPE = [
    "Test",
    "Game"
]

export const FILTER_LOCATION_TYPE = [
    "Home",
    "Away",
    "Neutral"
]

export const EARLIEST_YEAR = '1884';
export const LATEST_YEAR = new Date().getFullYear();

export const EARLIEST_MONTH = Date.parse('May 1884');
export const LATEST_MONTH = new Date().getDate();

export const PLAYER_SORT_OPTIONS = [
    'Alphabetical (A - Z)',
    'Earliest',
    'Latest',
    'Most points scored',
    'Most tests',
    'Most total matches'
]

export const MATCH_SORT_OPTIONS = [
    'Earliest',
    'Latest',
    'Point margin (highest to lowest)',
    'NZ points scored (highest to lowest)'
]

export const ALPHABETICAL = 'alpha';
export const DEBUT = 'debut';
export const DEBUT_REVERSE = 'debut-reverse';
export const POINTS = 'points';
export const TESTS = 'tests';
export const MATCHES = 'matches';
export const LATEST = 'latest';
export const EARLIEST = 'earliest';
export const POINTS_MARGIN = 'margin';
export const NZ_POINTS = 'nz-points';

export const SORT_MAPPINGS = {
    'Alphabetical (A - Z)': ALPHABETICAL,
    'Earliest': DEBUT,
    'Latest': DEBUT_REVERSE,
    'Most points scored': POINTS,
    'Most tests': TESTS,
    'Most total matches': MATCHES,
    'Point margin (highest to lowest)': POINTS_MARGIN,
    'NZ points scored (highest to lowest)': NZ_POINTS,
}

export const SORT_FUNCTIONS = {
    ALPHABETICAL : (a,b) => {
        return ((a.lastName < b.lastName) ? -1 : ((a.lastName > b.lastName) ? 1 : 0));
    },
    DEBUT : (a,b) => {
        return new Date(a.debut) - new Date(b.debut);
    },
    DEBUT_REVERSE : (a,b) => {
        return new Date(b.debut) - new Date(a.debut);
    },
    POINTS : function(a, b) {
        return a.points > b.points ? -1 : 0;
    },
    TESTS : function(a,b) {
        return a.testCaps > b.testCaps ? -1 : 0;
    },
    MATCHES : function(a,b) {
        return a.caps > b.caps ? -1 : 0
    },
};

export const MATCH_SORT_MAPPINGS = {
    'Earliest': EARLIEST,
    'Latest': LATEST,
    'Most points scored': POINTS,
    'Most tests': TESTS,
    'Most total matches': MATCHES,
    'Point margin (highest to lowest)': POINTS_MARGIN,
    'NZ points scored (highest to lowest)': NZ_POINTS,
}

export const MATCH_SORT_FUNCTIONS = {
    EARLIEST : (a,b) => {
        return new Date(a.date) - new Date(b.date);
    },
    LATEST : (a,b) => {
        return new Date(b.date) - new Date(a.date);
    },
    POINTS_MARGIN : function(a,b) {
        return (a.fullTimeNZScore - a.fullTimeOppositionScore) > (b.fullTimeNZScore - b.fullTimeOppositionScore) ? -1 : 0
    },
    NZ_POINTS : function(a,b) {
        return a.fullTimeNZScore > b.fullTimeNZScore ? -1 : 0
    },
};



















