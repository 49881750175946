import React from 'react';
import DOMPurify from 'dompurify';

function TopImageBlock({ imgSrc, heading, description, btnOneText, btnOneLink }) {

    const sanitizedDescription = DOMPurify.sanitize(description);

    const isExternalLink = (url) => {
        try {
          const link = new URL(url);
          return link.origin !== window.location.origin;
        } catch (error) {
          return false;
        }
      };

    const handleButtonClick = (url) => {
        if (url) {
          window.open(url, isExternalLink(url) ? "_blank" : "_self", isExternalLink(url) ? "noopener noreferrer" : "");
        }
      };

    return (
        <>
            <div className="max-w-screen-xl grid grid-cols-6 lg:grid-cols-12 gap-4 mx-auto relative pb-0 md:pb-20">
                <img src={imgSrc} alt="" className="col-span-6 md:col-span-12"/>
                <div className="col-span-6 md:col-span-12 md:text-center px-4 md:px-0 md:absolute md:-bottom-[24px] md:z-20 md:left-[50%] md:-translate-x-1/2">
                    <h2 className="mb-3">{heading}</h2>
                    <span className="text-grey-1" dangerouslySetInnerHTML={{__html: sanitizedDescription}}></span>
                    {btnOneText && btnOneLink && (
                        <button className="button--secondary"
                        onClick={() => handleButtonClick(btnOneLink)}
                        >{btnOneText}
                        </button>
                    )}
                </div>
            </div>
        </>
    )
}

export default TopImageBlock
