import React from "react";
import {Argentina} from "../SVGs/OpponentLogos/Argentina";
import {Australia} from "../SVGs/OpponentLogos/Australia";
import {USA} from "../SVGs/OpponentLogos/USA";
import {SouthAfrica} from "../SVGs/OpponentLogos/SouthAfrica";
import {England} from "../SVGs/OpponentLogos/England";
import {Japan} from "../SVGs/OpponentLogos/Japan";
import {Scotland} from "../SVGs/OpponentLogos/Scotland";
import {France} from "../SVGs/OpponentLogos/France";
import {Canada} from "../SVGs/OpponentLogos/Canada";
import {Fiji} from "../SVGs/OpponentLogos/Fiji";
import {HongKong} from "../SVGs/OpponentLogos/HongKong";
import {Ireland} from "../SVGs/OpponentLogos/Ireland";
import {Namibia} from "../SVGs/OpponentLogos/Namibia";
import {Portugal} from "../SVGs/OpponentLogos/Portugal";
import {Romania} from "../SVGs/OpponentLogos/Romania";
import {Tonga} from "../SVGs/OpponentLogos/Tonga";
import {Wales} from "../SVGs/OpponentLogos/Wales";
import {Italy} from "../SVGs/OpponentLogos/Italy";
import {Samoa} from "../SVGs/OpponentLogos/Samoa";
import {CardNoLogo} from "./CardNoLogo";
import {Barbarians} from "../SVGs/OpponentLogos/Barbarians";
import {NoLogo} from "./NoLogo";
import {Uruguay} from "../SVGs/OpponentLogos/Uruguay";
import {BritishIrishLions} from "../SVGs/OpponentLogos/BritishIrishLions";

function Opponent({ opponent, teamName, size, className = '' }) {
    const backgroundColor = countryBackgroundColors[opponent] || '#333';
    const textColorClass = opponent === 'Great Britain' || opponent === 'Pacific Islanders' ? 'text-black-100' : 'text-white';
    const marginBottomClass = opponent === 'British & Irish Lions' ? 'mb-2' : 'mb-8';

    const Logo = countryLogos[opponent] || null;

    const tiledBackgroundUrl = '/assets/images/card-pattern-tile.svg';

    // Gradient and background styles
    const combinedBackground = `
        linear-gradient(to bottom, rgba(0, 0, 0, 0) 35%, rgba(0, 0, 0, 0.8) 95%),
        url(${tiledBackgroundUrl}),
        ${backgroundColor}
    `;

    let letters = ''
    if (!Logo) {
        const matches = opponent.match(/\b(\w)/g);
        letters = matches.join(' ');
    }

    if (size === 'box') {
        return (
            <div className={`flex justify-center items-center w-10 md:w-16 lg:w-20 h-10 md:h-16 lg:h-20 rounded-md p-2 md:p-4 ${className}`} style={{ backgroundColor }}>
                { Logo ? (
                    <Logo width={55} height={55} />
                ) : (
                    <NoLogo country={opponent} letters={letters} textColorClass={textColorClass} textSize={opponent.length < 10 ? 'text-sm' : 'text-xs'} />
                ) }
            </div>
        )
    }
    if (size === 'cardLogo') {
        return (
            <div className={`flex flex-col items-center justify-between h-full ${className}`}>
                { Logo ? (
                    <>
                        <Logo width={200} height={200}/>
                        <div className="flex flex-col mt-auto">
                            <span className={`h4 text-center ${textColorClass}`}>{teamName}</span>
                            <span
                                className={`card__display-name text-center ${marginBottomClass} ${textColorClass}`}>{opponent}</span>
                        </div>
                    </>
                ) : (
                    <CardNoLogo teamName={teamName} country={opponent} textColorClass={textColorClass}/>
                )}
            </div>
        )
    }
    if (size === 'fullCard') {
        return (
            <div
                className={`flex flex-col items-center justify-center h-full ${className}`}
                style={{
                    background: combinedBackground,
                    backgroundSize: 'cover, repeat, auto',
                    backgroundBlendMode: 'overlay, normal',
                  }}
            >
                { Logo ? (
                    <Logo width={200} height={200}/>
                ) : (
                    <CardNoLogo teamName={teamName} country={opponent} textColorClass={textColorClass}/>
                )}
            </div>
        )
    }
    if (size === 'statsIcon') {
        return (
            <div
                className={`bg-gray-800 px-2 py-4 mb-3 max-h-[45px] w-[90px] overflow-visible logo-container--opponent rounded`}
                >
                <div className={`logo-container--inner px-2 py-4 h-[45px] relative flex justify-center items-center rounded`} style={{ backgroundColor }}>
                    {Logo ? <Logo width={40} height={40}/> : <span>{letters}</span>}
                </div>
            </div>
        )
    }
    if (size === 'playerImage') {
        return (
            <div className="object-cover md:w-[77px] md:h-[128px] rounded-l-xl hidden md:flex items-center justify-center"
                 style={{backgroundColor}}>
                {Logo ? (
                    <Logo width={50} height={50}/>
                ) : (
                    <NoLogo country={letters} textColorClass={textColorClass} textSize={'text-lg'} />
                )}
            </div>
        )
    }
}

export default Opponent;


export const countryBackgroundColors = {
    "All America": "#162C53",
    "Anglo Welsh": "#D3203D",
    "Argentina": "#76A9DC",
    "Australia": "#F9C220",
    "British & Irish Lions": "#A00201",
    "Canada": "#E82042",
    "Chile": "#C12626",
    "England": "#A71931",
    "Fiji": "#6ABFE6",
    "France": "#283B82",
    "Germany": "#1A1C19",
    "Georgia": "#E21E30",
    "Great Britain": "#FFFFFF",
    "Hong Kong": "#092040",
    "Ireland": "#027B4F",
    "Italy": "#2A88C8",
    "Japan": "#E4262B",
    "Kazakhstan": "#4DC5D7",
    "Madagascar": "#007E3C",
    "Namibia": "#29387C",
    "Netherlands": "#FF6101",
    "NZ Barbarians": "#FFFFFF",
    "Pacific Islanders": "#FFFFFF",
    "Portugal": "#E5232A",
    "Romania": "#F0D02F",
    "Scotland": "#212A53",
    "South Africa": "#013B45",
    "Samoa": "#031F5B",
    "Spain": "#B51830",
    "Tonga": "#AF1F24",
    "USA": "#162C53",
    "Uruguay": "#00ACE9",
    "Wales": "#D3203D",
    "World XV": "#12177A"
};


export const countryLogos = {
    "All America": null,
    "Anglo Welsh": null,
    "Argentina": Argentina,
    "Australia": Australia,
    "British & Irish Lions": BritishIrishLions,
    "Canada": Canada,
    "Chile": null,
    "England": England,
    "Fiji": Fiji,
    "France": France,
    "Germany": null,
    "Great Britain": null,
    "Hong Kong": HongKong,
    "Ireland": Ireland,
    "Italy": Italy,
    "Japan": Japan,
    "Kazakhstan": null,
    "Madagascar": null,
    "Namibia": Namibia,
    "Netherlands": null,
    "NZ Barbarians": Barbarians,
    "Pacific Islanders": null,
    "Portugal": Portugal,
    "Romania": Romania,
    "Scotland": Scotland,
    "South Africa": SouthAfrica,
    "Samoa": Samoa,
    "Spain": null,
    "Tonga": Tonga,
    "UK": null,
    "Uruguay": Uruguay,
    "USA": USA,
    "Wales": Wales,
    "World XV": null
};
