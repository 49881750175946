import React from 'react'

export const BlackFernsLogo = ({ width, height }) => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width ? width : "98"} height={height ? height : "74"}
             viewBox="0 0 98 74" fill="none">
                <path
                    d="M50.9264 73.0419H54.2813V69.73H57.12V67.6225H54.2813V66.2892H57.5501V64.1816H50.2812V64.7408H50.5393C50.8404 64.7838 50.9264 64.9558 50.9264 65.2139V73.0419Z"
                    fill="white"/>
                <path
                    d="M58.8834 73.0419H65.5071V70.9344H62.2383V69.6871H65.077V67.5795H62.2383V66.2462H65.5071V64.1387H58.2383V64.6978H58.4963C58.7974 64.7408 58.8834 64.9129 58.8834 65.1709V72.9989V73.0419Z"
                    fill="white"/>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M70.2382 68.0526V66.0741H70.7114C71.3995 66.0741 71.7866 66.3752 71.7866 67.1064C71.7866 67.8376 71.4856 68.0526 70.7974 68.0526H70.2382ZM75.1415 70.6333C75.1415 69.644 74.4963 69.1279 73.249 68.9128C74.5823 68.5687 75.1845 67.8376 75.1845 66.5902C75.1845 65.3429 74.1092 64.1816 72.3028 64.1816H66.2812V64.7408H66.5393C66.8404 64.7838 66.9264 64.9558 66.9264 65.2139V73.0419H70.2812V70.1171H70.8404C71.4856 70.1171 71.7866 70.5042 71.7866 71.2784V72.1386C71.7866 72.6117 72.0017 72.9988 72.5608 72.9988H75.8296V72.4397H75.5716C75.2705 72.3967 75.1845 72.2246 75.1845 71.9666V70.5902L75.1415 70.6333Z"
                      fill="white"/>
                <path
                    d="M75.9141 64.7838H76.1722C76.4732 64.8268 76.5593 64.9988 76.5593 65.2569V73.0848H79.8711V69.2139L81.9786 73.0848H85.2905V64.2246H81.9786V68.5687H81.9356L79.8281 64.2246H75.8711V64.7838H75.9141Z"
                    fill="white"/>
                <path
                    d="M93.936 66.7625C93.85 64.4829 92.0865 64.0098 90.065 64.0098C88.0435 64.0098 86.4521 64.87 86.4521 66.6764C86.4521 68.4829 87.4414 68.956 89.6349 69.9453C90.3661 70.2893 90.8392 70.4614 90.8392 70.9345C90.8392 71.4076 90.5812 71.4506 90.151 71.4506C89.5489 71.4506 89.4199 71.0635 89.4199 70.4614H86.3661C86.2801 73.042 88.1725 73.2571 90.2801 73.2571C92.3876 73.2571 94.151 72.3108 94.151 70.5904C94.151 68.87 92.3446 68.0098 90.6672 67.3216C89.979 67.0205 89.6349 66.8485 89.6349 66.3324C89.6349 65.8162 89.893 65.8592 90.194 65.8592C90.7962 65.8592 90.8822 66.2463 90.8822 66.8055H93.893L93.936 66.7625Z"
                    fill="white"/>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M35.1408 67.3216C35.1408 65.3001 34.4096 63.9668 31.0978 63.9668C27.786 63.9668 26.9688 65.0421 26.9688 68.612C26.9688 71.9668 27.6569 73.2571 31.1408 73.2571C34.6247 73.2571 35.1838 71.6657 35.1838 69.7302H31.872V70.6765C31.872 71.1066 31.6569 71.4077 31.1408 71.4077C30.6247 71.4077 30.4096 71.1066 30.4096 70.6765V66.4614C30.4096 66.0313 30.6247 65.7302 31.1408 65.7302C31.6569 65.7302 31.872 66.0313 31.872 66.4614V67.2786H35.1838L35.1408 67.3216Z"
                      fill="white"/>
                <path
                    d="M0.731188 72.9988V65.3859C0.731188 65.1278 0.60215 64.9988 0.430107 64.9127C0.344086 64.8697 0.258062 64.8267 0.17204 64.8267C0.12903 64.8267 0.0430107 64.8267 0 64.8267V64.2676H5.37634C7.91398 64.2676 8.64516 64.9988 8.64516 66.3751C8.64516 67.7514 7.78495 68.3536 6.49463 68.5687C7.87097 68.7407 8.8172 69.2998 8.8172 70.8052C8.8172 72.3106 8.17204 73.0848 5.97849 73.0848H0.688175L0.731188 72.9988ZM4.00001 69.4289V71.1923H4.68817C5.2043 71.1923 5.46237 70.7192 5.46237 70.2031C5.46237 69.6869 5.16129 69.4289 4.7742 69.4289H4.04301H4.00001ZM4.00001 66.031V67.6654H4.68817C5.11828 67.6654 5.37634 67.2783 5.37634 66.8482C5.37634 66.4181 5.11828 66.031 4.68817 66.031H4.00001Z"
                    fill="white"/>
                <path
                    d="M10.196 72.9988V65.4289C10.196 65.1278 10.067 64.9988 9.89495 64.8698C9.80893 64.8268 9.72291 64.7838 9.63688 64.7838C9.59387 64.7838 9.50785 64.7838 9.46484 64.7838V64.2246H13.5509V70.8913H16.4326V72.9988H10.153H10.196Z"
                    fill="white"/>
                <path
                    d="M21.9383 66.0311L21.2501 69.945H22.5834L21.9383 66.0311ZM17.2501 72.9988L19.2716 65.3859C19.3146 65.1278 19.2716 64.9558 19.0995 64.8698C19.0135 64.8268 18.9705 64.8268 18.8845 64.7838C18.8415 64.7838 18.7555 64.7838 18.7125 64.7838V64.2246H24.1748L26.4544 72.9988H23.0135L22.7555 71.6225H20.906L20.6479 72.9988H17.1641H17.2501Z"
                    fill="white"/>
                <path
                    d="M35.6992 64.1816H39.7852V66.6333L42.1938 64.1816H46.1508L42.753 67.5365L46.0218 72.9558H42.4519L40.5594 69.687L39.8283 70.4182V72.9558H36.4734V65.3859C36.4734 65.0849 36.3444 64.9558 36.1723 64.8268C36.0863 64.7838 36.0003 64.7408 35.9143 64.7408C35.8713 64.7408 35.7852 64.7408 35.7422 64.7408V64.1816H35.6992Z"
                    fill="white"/>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M95.4 62.9355H95.572L96.0021 63.7527H96.4322L95.9161 62.8925C96.2602 62.8925 96.4753 62.7634 96.4753 62.3763C96.4753 61.9893 96.1742 61.7742 95.6581 61.7742H94.9269V63.7527H95.357V62.9355H95.4ZM95.4 62.5914V62.1613H95.6581C95.7871 62.1613 96.0021 62.1613 96.0021 62.3763C96.0021 62.5914 95.9161 62.6344 95.7441 62.6344H95.357L95.4 62.5914ZM95.7011 64.6129C96.6903 64.6129 97.5935 63.9677 97.5935 62.8065C97.5935 61.6452 96.6903 61 95.7011 61C94.7118 61 93.8086 61.6452 93.8086 62.8065C93.8086 63.9677 94.7118 64.6129 95.7011 64.6129ZM96.9914 62.7634C96.9914 63.5806 96.3892 64.1398 95.7011 64.1398C95.0129 64.1398 94.4108 63.5806 94.4108 62.7634C94.4108 61.9462 94.9699 61.3871 95.7011 61.3871C96.4323 61.3871 96.9914 61.9462 96.9914 62.7634Z"
                      fill="white"/>
                <g clipPath="url(#clip0_5086_16830)">
                        <mask id="mask0_5086_16830" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="3" y="0"
                              width="95" height="68">
                                <path d="M97.6803 0.645508H3.88574V67.0814H97.6803V0.645508Z" fill="white"/>
                        </mask>
                        <g mask="url(#mask0_5086_16830)">
                                <mask id="mask1_5086_16830" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="3"
                                      y="0" width="95" height="68">
                                        <path d="M97.6812 0.645508H3.88672V67.0814H97.6812V0.645508Z" fill="white"/>
                                </mask>
                                <g mask="url(#mask1_5086_16830)">
                                        <path
                                            d="M48.0027 12.2988C46.866 12.4395 45.748 12.5918 44.6582 12.7582L45.0496 25.4238L59.0816 32.7668C59.4871 32.5699 59.8973 32.3684 60.3074 32.1645L48.5793 24.1863L46.6457 24.852L48.0027 12.3012V12.2988Z"
                                            fill="white"/>
                                        <path
                                            d="M41.2148 13.3578C40.0781 13.5852 39 13.8336 37.9898 14.1102L37.8398 28.5125L52.4016 35.9469C52.943 35.6914 53.5008 35.4289 54.0727 35.1594L41.4422 26.8508L39.4312 27.7508L41.2148 13.3555V13.3578Z"
                                            fill="white"/>
                                        <path
                                            d="M44.373 12.8027C43.3863 12.9574 42.423 13.1215 41.4973 13.302L41.4434 26.8512L55.8832 34.3043C56.3566 34.0793 56.8371 33.8496 57.3246 33.6152L45.0527 25.4215L43.0371 26.1316L44.3754 12.8027H44.373Z"
                                            fill="white"/>
                                        <path
                                            d="M54.0172 11.6396C53.1313 11.7264 52.2406 11.8178 51.3547 11.9139L51.9852 23.0982L50.0516 23.7217L51.057 11.9467C50.1289 12.0475 49.2031 12.1529 48.2891 12.2654L48.5773 24.1881L62.2156 31.21C62.607 31.0107 62.9984 30.8115 63.3898 30.6076L52.0461 23.0982L64.8922 29.8201C65.2742 29.6162 65.6563 29.4123 66.0383 29.2037L55.3227 21.9943L53.368 22.66L54.0102 11.642L54.0172 11.6396Z"
                                            fill="white"/>
                                        <path
                                            d="M57.0949 11.3428C56.1809 11.4295 55.2598 11.5186 54.334 11.61L55.3301 21.9928L67.4027 28.4545C67.7473 28.2623 68.0895 28.0678 68.4316 27.8709L58.2293 20.9826L56.5488 21.5779L57.0949 11.3428Z"
                                            fill="white"/>
                                        <path
                                            d="M21.5305 21.6811C19.8102 22.7428 18.4016 23.6896 17.4148 24.3787L16.9883 41.5584C16.9883 41.5584 13.6859 43.9279 12.4438 44.9732C11.2016 46.0209 8.92578 48.1607 8.92578 48.1607V52.4029C8.92578 52.4029 11.6773 48.6014 13.6039 46.7943C15.4813 45.0342 17.9047 43.0467 17.9047 43.0467L17.4594 42.4068L32.7852 49.3326C33.0312 49.0935 33.6289 48.5217 34.4867 47.7389L21.1977 39.0365L19.1258 40.6959L21.5281 21.6787L21.5305 21.6811Z"
                                            fill="white"/>
                                        <path
                                            d="M25.71 19.2549C24.3412 19.9979 23.0709 20.7385 21.9225 21.4393L21.1982 39.0361L36.7373 45.758C37.2951 45.2822 37.8928 44.783 38.5232 44.2744L25.4943 35.8182L23.4178 37.3299L25.71 19.2549Z"
                                            fill="white"/>
                                        <path
                                            d="M60.2023 11.042C59.2789 11.1334 58.3367 11.2225 57.3828 11.3139L58.2289 20.9818L69.7414 27.1061C70.1094 26.8881 70.475 26.6678 70.8383 26.4451L61.2125 19.9693L59.4055 20.6115L60.2023 11.042Z"
                                            fill="white"/>
                                        <path
                                            d="M37.6853 14.1943C37.6103 14.2154 37.5307 14.2365 37.4557 14.2576C36.3307 14.5857 35.2103 14.9654 34.1064 15.3779L33.7432 30.6521L48.6846 37.7256C49.2518 37.4467 49.8518 37.1584 50.4846 36.8561L37.8424 28.5123L35.6885 29.6068L37.6877 14.1943H37.6853Z"
                                            fill="white"/>
                                        <path
                                            d="M33.8418 15.4789C32.527 15.9781 31.2379 16.5266 29.991 17.1031L29.616 33.0852L27.5137 34.4305L29.7051 17.2344C28.416 17.8367 27.1762 18.4648 26.002 19.0977L25.498 35.8156L40.6129 42.65C41.1613 42.2375 41.7262 41.8297 42.2957 41.4312L29.6207 33.1273L44.4965 39.9805C45.0871 39.6148 45.6801 39.2703 46.266 38.9562C46.3012 38.9375 46.341 38.9164 46.3762 38.8977L33.748 30.65L31.6199 31.8688L33.8441 15.4766L33.8418 15.4789Z"
                                            fill="white"/>
                                        <path
                                            d="M78.3869 7.14199C77.6111 7.46309 76.7932 7.7748 75.9424 8.07715L75.9635 13.6084L83.1049 15.1506C83.5033 14.5506 83.8807 13.9482 84.2369 13.3459L78.8158 11.8178L77.4213 12.7225L78.3846 7.13965L78.3869 7.14199Z"
                                            fill="white"/>
                                        <path
                                            d="M75.674 8.17383C74.842 8.46445 73.9725 8.73867 73.0748 8.99883L73.0186 15.1395L81.2732 17.6824C81.6107 17.2559 81.9365 16.8246 82.2506 16.391L75.967 13.6113L74.5256 14.3684L75.6764 8.17617L75.674 8.17383Z"
                                            fill="white"/>
                                        <path
                                            d="M83.5445 4.65566C82.8812 5.02598 82.1641 5.40332 81.3953 5.78066L81.3531 9.89394L79.9633 10.9861L81.1445 5.9002C80.3594 6.28223 79.5227 6.66191 78.6367 7.03457L78.8219 11.8182L84.9133 12.1768C85.2531 11.565 85.5695 10.9604 85.8648 10.3674L81.4375 9.84941L86.4133 9.22832C86.6781 8.65644 86.9266 8.09863 87.1539 7.55957L83.7742 7.7752L82.7406 8.71738L83.5445 4.65332V4.65566Z"
                                            fill="white"/>
                                        <path
                                            d="M72.8383 9.06934C72.4398 9.18184 72.0367 9.29434 71.6266 9.39981C71.1508 9.52168 70.6375 9.63887 70.0937 9.74668L70.0703 16.4943L70.0586 16.499L79.3516 19.9068C79.675 19.5646 79.9891 19.2178 80.2937 18.8662L73.0211 15.1396L71.5773 15.8193L72.8383 9.06934Z"
                                            fill="white"/>
                                        <path
                                            d="M89.4727 0.645508C89.4727 0.645508 89.0719 1.00644 88.2938 1.59238L87.975 3.09238L87.3422 3.88926L87.6867 2.03769C87.2813 2.33066 86.8055 2.65879 86.2641 3.01035L85.9266 5.55098L85.2281 6.3291L85.8961 3.24941C85.2703 3.64785 84.5625 4.07676 83.782 4.51738L83.7656 7.7752L87.6328 6.3666C87.8367 5.84395 88.0242 5.34707 88.1906 4.87832L86.1938 5.26973L88.5187 3.92441C89.1867 1.92285 89.468 0.647851 89.468 0.647851"
                                            fill="white"/>
                                        <path
                                            d="M63.3881 10.7129C62.4576 10.816 61.4967 10.9145 60.5146 11.0129L61.2131 19.9707L72.2896 25.5324C72.6623 25.2934 73.0326 25.052 73.3936 24.8082L64.3186 18.8082L62.535 19.4902L63.3857 10.7129H63.3881Z"
                                            fill="white"/>
                                        <path d="M67.1039 17.7271L67.1086 17.7248L67.0898 17.7178L67.1039 17.7271Z"
                                              fill="white"/>
                                        <path
                                            d="M66.8307 10.2875C65.8275 10.4281 64.7658 10.5594 63.6572 10.6812L64.3205 18.807L74.64 23.9539C74.8955 23.7734 75.1486 23.593 75.3994 23.4102C75.5353 23.3117 75.6689 23.2109 75.8002 23.1102L67.1049 17.7266L65.558 18.3289L66.8307 10.2852V10.2875Z"
                                            fill="white"/>
                                        <path d="M70.0563 16.5008V16.4984L70.0469 16.4961L70.0563 16.5008Z"
                                              fill="white"/>
                                        <path
                                            d="M69.8051 9.80273C68.9801 9.96211 68.0871 10.1074 67.1379 10.2457L67.152 17.7105L67.1074 17.727L77.1504 22.0137C77.4902 21.7207 77.823 21.4184 78.1465 21.1137L70.0535 16.5012L68.5582 17.1504L69.8051 9.80273Z"
                                            fill="white"/>
                                </g>
                        </g>
                </g>
                <defs>
                        <clipPath id="clip0_5086_16830">
                                <rect width="80.3906" height="52.2656" fill="white"
                                      transform="translate(8.99219 0.554688)"/>
                        </clipPath>
                </defs>
        </svg>
    )
}