import React from 'react'

export const IconRedCard = ({ width, height }) => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg"  width={width ? width : "24"} height={height ? height : "24"} fill="none">
            <g filter="url(#a)">
                <rect width="23.001" height="23.001" x="1" fill="#EB4B4B" rx="1.438" transform="rotate(2.545 1 0)"/>
            </g>
            <defs>
                <filter id="a" width="29.625" height="29.626" x="-2.834" y="-2.813" colorInterpolationFilters="sRGB"
                        filterUnits="userSpaceOnUse">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feColorMatrix in="SourceAlpha" result="hardAlpha"
                                   values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                    <feOffset dx="-2.875" dy="-2.875"/>
                    <feGaussianBlur stdDeviation="4.313"/>
                    <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"/>
                    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                    <feBlend in2="shape" result="effect1_innerShadow_4456_5668"/>
                    <feColorMatrix in="SourceAlpha" result="hardAlpha"
                                   values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                    <feOffset dx="2.875" dy="2.875"/>
                    <feGaussianBlur stdDeviation="4.313"/>
                    <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"/>
                    <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"/>
                    <feBlend in2="effect1_innerShadow_4456_5668" result="effect2_innerShadow_4456_5668"/>
                    <feColorMatrix in="SourceAlpha" result="hardAlpha"
                                   values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                    <feOffset dx=".18" dy=".18"/>
                    <feGaussianBlur stdDeviation=".18"/>
                    <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"/>
                    <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.8 0"/>
                    <feBlend in2="effect2_innerShadow_4456_5668" result="effect3_innerShadow_4456_5668"/>
                </filter>
            </defs>
        </svg>
    )
}