import React from 'react'
import {createRoot} from "react-dom/client";
import {MantineProvider} from "@mantine/core";
import PlayerPageHero from "./playerpage_hero/PlayerPageHero";
import MatchTable from "./tables/MatchTable";
import PlayerMainContent from "./player_main_content/PlayerMainContent";
import PlayerStats from "./player_stats/PlayerStats";
import PlayerCTA from "./player_cta/PlayerCTA";
import { removeLoader } from "../utils/removeLoader";

function PlayerPage({player, allPlayersLink, nextPlayer, randomPlayer}) {
    return (
        <div className="content-container mt-32 relative z-20">
            <PlayerPageHero player={player} allPlayersLink={allPlayersLink}/>
            <PlayerMainContent player={player} />
            <PlayerStats player={player} />
            {player.games && (
                <MatchTable allMatches={player.games}/>
            )}
            <PlayerCTA nextPlayer={nextPlayer} randomPlayer={randomPlayer} />
        </div>

    )
}

export default function init(mountNode, theme) {
    const dataNode = document.getElementById('PlayerPageData');
    const pageData = JSON.parse(dataNode.textContent);

    removeLoader();
    if (mountNode) {
        createRoot(mountNode).render(
            <MantineProvider theme={theme} forceColorScheme={'dark'}>
                <PlayerPage player={pageData.player[0]} allPlayersLink={pageData.allPlayersLink} nextPlayer={pageData.nextPlayer} randomPlayer={pageData.randomPlayer} />
            </MantineProvider>
        )
    }
}
