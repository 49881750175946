import React, { useState, useEffect, useRef, JSXElementConstructor, Key, ReactElement, ReactNode, ReactPortal} from 'react';
import {StatsLogos} from "../SVGs/StatsLogos";
import {BurgerOpen} from "../SVGs/BurgerOpen";
import {BurgerClose} from "../SVGs/BurgerClose";
import MobileNav from "../mobile_nav/MobileNav";
import {createRoot} from "react-dom/client";

function Header({links}) {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [scrollDirection, setScrollDirection] = useState(null);
    const [isHeaderVisible, setIsHeaderVisible] = useState(true);

    useEffect(() => {
        let lastScrollY = window.pageYOffset;

        const updateScrollBehavior = () => {
          const scrollY = window.pageYOffset;
          const direction = scrollY > lastScrollY ? "down" : "up";

          // Determine if the header should be visible
          if (direction === "down" && !isMenuOpen) {
            setIsHeaderVisible(false);
          } else if (direction === "up") {
            setIsHeaderVisible(true);
          }

          // Update scroll direction
          if (direction !== scrollDirection && Math.abs(scrollY - lastScrollY) > 10) {
            setScrollDirection(direction);
          }

          lastScrollY = scrollY > 0 ? scrollY : 0;
        };

        window.addEventListener("scroll", updateScrollBehavior);
        return () => {
          window.removeEventListener("scroll", updateScrollBehavior);
        };
   }, [scrollDirection, isMenuOpen]);

  useEffect(() => {
    const headerElement = document.querySelector("header");
    if (headerElement) {
      if (isHeaderVisible) {
        headerElement.classList.remove("-translate-y-full", "fixed");
        headerElement.classList.add("translate-y-0", "fixed");

      } else {
        headerElement.classList.add("-translate-y-full", "fixed");
        headerElement.classList.remove("translate-y-0", "fixed");
      }
    }
  }, [isHeaderVisible]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    if (isMenuOpen) {
      setIsHeaderVisible(true); // Ensure header stays visible when menu is closed
    }
  };

    return (
        <>
            <div className="bg-black-100 md:bg-transparent w-full z-20 transition-transform duration-300">
                <div className="max-w-screen-xl mx-auto p-4">
                    <nav className="flex flex-wrap items-center justify-between">
                        <a href="/">
                            <StatsLogos width={153} height={60}/>
                        </a>
                        <div className="flex md:hidden">
                            <div className="cursor-pointer" onClick={toggleMenu}>
                                {isMenuOpen ? (
                                    <BurgerClose width={68} height={68}/>
                                ) : (
                                    <BurgerOpen width={68} height={68}/>
                                )}
                            </div>
                        </div>
                        <div className="hidden md:flex space-x-14">
                            {links.map((item) => (
                                <a key={item.key} href={item.href} className="h6 text-grey-1 hover:underline">{item.title}</a>
                            ))}
                        </div>
                    </nav>
                </div>
            </div>

            <MobileNav isMenuOpen={isMenuOpen} links={links} />
        </>
    );
}

export default function init(mountNode) {
    const dataNode = document.getElementById('headerData');
    const headerData = JSON.parse(dataNode.textContent);

    // Note. In order for the header template to be valid JSON, need to include a fake element, hence the slice below
    if (mountNode) {
        createRoot(mountNode).render(
            <Header links={headerData.headerItems.slice(0, -1)} />
        )
    }
}

