import React from 'react'
import {createRoot} from "react-dom/client";
import {MantineProvider} from "@mantine/core";
import MatchPageHeader from "./match_page_header/MatchPageHeader";
import MatchPageMainBlock from "./match_page_main_block/MatchPageMainBlock";
import MatchCTA from "./match_cta/MatchCTA";
import MatchLineUp from "./match_lineup/MatchLineUp";
import {removeLoader} from "../utils/removeLoader";

function MatchPage({match, allMatchesLink}) {
    const {summaryData, matchData, lineUp, rivalry} = match
    return (
        <div className="content-container mt-32 relative z-20">
            <MatchPageHeader match={summaryData} allMatchesLink={allMatchesLink} />
            <MatchPageMainBlock match={matchData} />
            <MatchLineUp lineUp={lineUp} nzTeam={matchData.team} opposition={matchData.opposition} />
            { rivalry && <MatchCTA data={rivalry} />}
        </div>

    )
}

export default function init(mountNode, theme) {
    const dataNode = document.getElementById('MatchPageData');
    const pageData = JSON.parse(dataNode.textContent);

    removeLoader();
    if (mountNode) {
        createRoot(mountNode).render(
            <MantineProvider theme={theme} forceColorScheme={'dark'}>
                <MatchPage match={pageData.match[0]} allMatchesLink={pageData.allMatchesLink} />
            </MantineProvider>
        )
    }
}
