import React from 'react';
import DOMPurify from 'dompurify';

function TextHeadingBlock({ heading, description }) {

    const sanitizedDescription = DOMPurify.sanitize(description);

      return (
        <div className="max-w-screen-xl grid grid-cols-6 md:grid-cols-12 gap-4 h-full mx-auto">
            <h2 className="col-span-6 md:col-span-4 px-4 md:col-start-2">{heading}</h2>
            <div
                className={`col-span-6 md:col-span-6 flex flex-col items-start justify-center space-y-4 px-4`}>
                <span dangerouslySetInnerHTML={{__html: sanitizedDescription}}></span>
            </div>
        </div>
      );
}

export default TextHeadingBlock;
