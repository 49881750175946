export function removeLoader() {
  window.addEventListener('load', function () {
    var loadingScreen = document.querySelectorAll(".LoadingScreenPage");
    setTimeout(function () {
      for (var i = 0; i < loadingScreen.length; i++) {
        (loadingScreen[i] as HTMLElement).style.display = 'none';
      }
    }, 300);

  })
}
