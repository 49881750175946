import React from 'react'

export const NoLogo = ({country, letters, textColorClass, textSize}) => {

    return (
        <div className='flex flex-col justify-center items-center h-full'>
            <div className='hidden md:block'>
                <div className={textColorClass === 'text-black-100' ? 'no-logo-line-black' : 'no-logo-line'}/>
                <div className="flex flex-col my-4 text-center">
                    <span
                        className={`card__display-name text-center text-wrap truncate ${textColorClass} ${textSize}`}>{country}</span>
                </div>
                <div className={textColorClass === 'text-black-100' ? 'no-logo-line-black' : 'no-logo-line'}/>
            </div>
            <div>
                <span
                    className={`md:hidden card__display-name text-center truncate ${textColorClass} text-xs`}>{letters}</span>
            </div>
        </div>
    )
}