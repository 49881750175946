import React from "react";
import Match from "./Match";
import Location from "./Location";
import ComparisonStat from "./ComparisonStat";
import YellowCard from "./YellowCard";
import RedCard from "./RedCard";

export const summaryColumns = [
        {
            accessorFn: (row) => [`${row.team}`, `${row.fullTimeNZScore}`, `${row.fullTimeOppositionScore}`, `${row.opposition}`],
            id: 'match',
            header: 'Match',
            mantineTableBodyCellProps: {
                className: 'px-0 py-0',
            },
            size: 220,
            Cell: ({renderedCellValue, row}) => (
                <Match renderedCellValue={renderedCellValue} />

            ),
        },
        {
            accessorKey: 'date',
            header: 'Date',
            size: 180,
            mantineTableHeadCellProps: {
                className: 'pl-8 bg-transparent',
            },
            mantineTableBodyCellProps: {
                className: 'pl-8',
            },
        },
        {
            accessorFn: (row) => [`${row.location}`, `${row.home}`],
            id: 'location',
            accessorKey: 'location',
            header: 'Location',
            size: 150,
            Cell: ({renderedCellValue, row}) => (
                <Location renderedCellValue={renderedCellValue} />
            )
        },
        {
            accessorKey: 'series',
            header: 'Series / Tour',
            size: 180,
        },
        {
            accessorKey: 'test',
            header: 'Test / Game',
            size: 85,
        }
    ];

export const scoringColumns = [
        {
            accessorFn: (row) => [`${row.team}`, `${row.fullTimeNZScore}`, `${row.fullTimeOppositionScore}`, `${row.opposition}`],
            id: 'match', //id is still required when using accessorFn instead of accessorKey
            header: 'Match',
            mantineTableBodyCellProps: {
                className: 'px-0 py-0',
            },
            size: 220,
            Cell: ({renderedCellValue, row}) => (
                <Match renderedCellValue={renderedCellValue} />

            ),
        },
        {
            accessorFn: (row) => [`${row.NZTries}`, `${row.OppTries}`],
            id: 'tries',
            header: 'Tries',
            size: 149,
            Cell: ({renderedCellValue, row}) => (
                <ComparisonStat renderedCellValue={renderedCellValue} />

            ),
            mantineTableHeadCellProps: {
                className: 'pl-8 bg-transparent',
            },
            mantineTableBodyCellProps: {
                className: 'pl-8',
            },
        },
        {
            accessorFn: (row) => [`${row.NZConversions}`, `${row.OppConversions}`],
            id: 'conversions',
            header: 'Conversions',
            size: 149,
            Cell: ({renderedCellValue, row}) => (
                <ComparisonStat renderedCellValue={renderedCellValue} />

            ),
        },
        {
            accessorFn: (row) => [`${row.NZDropGoal}`, `${row.OppDropGoal}`],
            id: 'dropGoal',
            header: 'Drop Goal',
            size: 149,
            Cell: ({renderedCellValue, row}) => (
                <ComparisonStat renderedCellValue={renderedCellValue} />
            ),
        },
        {
            accessorKey: 'topScorer',
            header: 'Top Scorer',
            size: 149,
        }
    ];

export const detailColumns = [
        {
            accessorFn: (row) => [`${row.team}`, `${row.fullTimeNZScore}`, `${row.fullTimeOppositionScore}`, `${row.opposition}`],
            id: 'match', //id is still required when using accessorFn instead of accessorKey
            header: 'Match',
            mantineTableBodyCellProps: {
                className: 'px-0 py-0',
            },
            size: 220,
            Cell: ({renderedCellValue, row}) => (
                <Match renderedCellValue={renderedCellValue} />

            ),
        },
        {
            accessorKey: 'redCards',
            header: 'Red cards',
            size: 149,
            Cell: ({renderedCellValue}) => (
                <RedCard renderedCellValue={renderedCellValue} />
            ),
            mantineTableHeadCellProps: {
                className: 'pl-8 bg-transparent',
            },
            mantineTableBodyCellProps: {
                className: 'pl-8',
            },
        },
        {
            accessorKey: 'yellowCards',
            header: 'Yellow cards',
            size: 149,
            Cell: ({renderedCellValue}) => (
                <YellowCard renderedCellValue={renderedCellValue} />
            ),
        },
        {
            accessorKey: 'weather',
            header: 'Weather',
            size: 149,
        },
        {
            accessorKey: 'headCoach',
            header: 'Head Coach',
            size: 149,
        },
    ];
