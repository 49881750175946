import React, {useEffect, useState} from 'react'
import { throttle } from 'lodash';
import BaseTableWithTabs from "./BaseTableWithTabs";

import {
    ALL_BLACKS_TEAM,
    ALPHABETICAL, BLACK_FERNS_TEAM, DEBUT, DEBUT_REVERSE, EARLIEST_YEAR,
    LATEST_YEAR, MATCHES,
    PLAYER_TAB_DISPLAY_NAMES,
    PLAYER_TAB_OPTIONS, POINTS, SORT_FUNCTIONS,
    SORT_MAPPINGS, TESTS
} from '../../constants';
import {careerColumns, historyColumns, performanceColumns, summaryColumns} from "./allPlayers/AllPlayerColumns";
import PlayersFilter from "./allPlayers/PlayersFilter";
import TableTabs from "./Tabs";
import {useScrollIntoView} from "@mantine/hooks";

function PlayersTable({ allPlayers }) {

    const tabColumns = {
        summary: summaryColumns,
        performance: performanceColumns,
        career: careerColumns,
        history: historyColumns,
    };

    const [currentColumns, setCurrentColumns] = useState(tabColumns.summary);
    const setColumns = (val) => {
        setCurrentColumns(tabColumns[val]);
    }

    const [selectedTeams, setSelectedTeams] = useState([])
    const [selectedPositions, setPositions] = useState([]);
    const [selectedRegions, setRegions] = useState([]);
    const [selectedStatus, setStatus] = useState(null);
    const [selectedDates, setSelectedDates] = useState([]);
    const [selectedSort, setSort] = useState(null);

    const statuses = [...new Set(allPlayers.map(item => item.status))];

    let url = new URL(window.location);
    let searchParams = url.searchParams;

    const { scrollIntoView, targetRef } = useScrollIntoView({
        offset: 40
    });

    useEffect(() => {
        // Only run on load
        if (searchParams.get('team')) {
            setSelectedTeams(searchParams.get('team').split(','))
        } else {
            setSelectedTeams([ALL_BLACKS_TEAM, BLACK_FERNS_TEAM])
        }
        if (searchParams.get('positions')) {
            setPositions(searchParams.get('positions').split(','))
        }
        if (searchParams.get('regions')) {
            setRegions(searchParams.get('regions').split(','))
        }
        if (searchParams.get('status')) {
            setStatus(searchParams.get('status'))
        }
        if (searchParams.get('dates')) {
            setSelectedDates(searchParams.get('dates').split(','))
        } else {
            setSelectedDates([EARLIEST_YEAR, LATEST_YEAR])
        }

        if (searchParams.get('sort')) {
            setSort(searchParams.get('sort').split(','))
        } else {
            setSort('Alphabetical (A - Z)')
        }

        if (url.hash) {
            // Scroll after loader removed
            setTimeout(() => {
                scrollIntoView();
            }, 600);
        }

    }, []);

    useEffect(() => {
        searchParams.set('team', selectedTeams);
        searchParams.set('sort', selectedSort);

        if (selectedPositions.length > 0) {
            searchParams.set('positions', selectedPositions);
        } else {
            searchParams.delete('positions')
        }
        if (selectedRegions.length > 0) {
            searchParams.set('regions', selectedRegions);
        } else {
            searchParams.delete('regions')
        }
        if (selectedStatus) {
            searchParams.set('status', selectedStatus);
        } else {
            searchParams.delete('status')
        }
        if (selectedDates) {
            searchParams.set('dates', selectedDates);
        } else {
            searchParams.delete('dates')
        }

        history.replaceState({}, "", url);
    }, [selectedTeams, selectedPositions, selectedRegions, selectedStatus, selectedDates, selectedSort]);

    let filteredPlayers = [...allPlayers];

    if (selectedTeams.length > 0) {
        filteredPlayers = filteredPlayers
            .filter(player => selectedTeams.includes(player.team))
    }

    if (selectedPositions.length > 0) {
        filteredPlayers = filteredPlayers
            .filter(player => selectedPositions.some(filter => player.filterPosition.split(', ').includes(filter)))
    }
    if (selectedRegions.length > 0) {
        filteredPlayers = filteredPlayers
            .filter(player => selectedRegions.includes(player.region))
    }
    if (selectedStatus) {
        filteredPlayers = filteredPlayers
            .filter(player => player.status === selectedStatus)
    }
    if (selectedDates) {
        filteredPlayers = filteredPlayers
            .filter(player => player.debutYear >= selectedDates[0] && player.debutYear <= selectedDates[1])
    }

    const sortType = SORT_MAPPINGS[selectedSort];

    if (sortType === ALPHABETICAL) {
        filteredPlayers.sort(SORT_FUNCTIONS.ALPHABETICAL);
    }
    if (sortType === DEBUT) {
        filteredPlayers.sort(SORT_FUNCTIONS.DEBUT);
    }
    if (sortType === DEBUT_REVERSE) {
        filteredPlayers.sort(SORT_FUNCTIONS.DEBUT_REVERSE);
    }
    if (sortType === POINTS) {
        filteredPlayers.sort(SORT_FUNCTIONS.POINTS);
    }
    if (sortType === TESTS) {
        filteredPlayers.sort(SORT_FUNCTIONS.TESTS);
    }
    if (sortType === MATCHES) {
        filteredPlayers.sort(SORT_FUNCTIONS.MATCHES);
    }

    const tabs = <TableTabs tabOptions={PLAYER_TAB_OPTIONS} tabDisplayNames={PLAYER_TAB_DISPLAY_NAMES} setCurrentColumns={setColumns} />;
    const filters = <PlayersFilter
        selectedPositions={selectedPositions}
        setSelectedPositions={setPositions}
        selectedRegions={selectedRegions}
        setSelectedRegions={setRegions}
        selectedStatus={selectedStatus}
        setSelectedStatus={setStatus}
        statusOptions={ statuses }
        selectedDates={selectedDates}
        setSelectedDates={setSelectedDates}
        selectedSort={selectedSort}
        setSelectedSort={setSort}
    />

    return (
        <div ref={targetRef}>
            <BaseTableWithTabs
                title={ 'All Players' }
                data={ filteredPlayers }
                columns={ currentColumns }
                pinnedColumn={ 'playerName' }
                tabs={ tabs }
                filters={ filters }
                search
                selectedTeams={ selectedTeams }
                setSelectedTeams={ setSelectedTeams }
            />
        </div>

    );
}

export default PlayersTable;