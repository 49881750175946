import React, { useState } from 'react';
import {Checkbox, Combobox, Group, Input, Radio, useCombobox} from '@mantine/core';
import { IconChevron } from "../../SVGs/IconChevron";


export function RadioSelect({label, selectOptions, value, setValue, clearable= false, classNames= ''}) {
    const combobox = useCombobox({
        onDropdownClose: () => combobox.resetSelectedOption(),
        onDropdownOpen: () => combobox.updateSelectedOptionIndex('active'),
    });

    const handleValueSelect = (val) =>
        setValue((current) =>
            current === val ? (clearable ? null : val) : val
        );

    const options = selectOptions.map((item) => (
        <Combobox.Option value={item} key={item} active={value === item}>
            <Group gap="sm" className='flex-nowrap'>
                <Radio
                    iconColor="dark.8"
                    color="violet.6"
                    style={{ pointerEvents: 'none' }}
                    checked={value === item}
                    onChange={() => {}}
                    aria-hidden
                    tabIndex={-1}
                />
                <span>{item}</span>
            </Group>
        </Combobox.Option>
    ));

    const Description = () => {
        if (value) {
            return (<span className="pr-1 text-purple-10 truncate max-w-32 sm:max-w-48">{value}</span>)
        } else {
            return (<span className="pr-1">All</span>)
        }
    }
    const RightSide = ({dropdownOpened}) => {

        return (
            <div className="flex pr-4">
                <Description />
                <IconChevron classname={dropdownOpened ? '' : 'rotate-180'} colour={value > 0 ? '#A48EFF' : 'white'} />
            </div>
        )
    };

    return (
        <Combobox store={combobox} onOptionSubmit={handleValueSelect} withinPortal={false}>
            <Combobox.DropdownTarget>
                <Input
                    component="button"
                    classNames={{input: 'bg-grey-gradient h5 px-4 py-2 h-fit ' + classNames, option: 'h5' }}
                    rightSection={<RightSide dropdownOpened={combobox.dropdownOpened} />}
                    rightSectionWidth={'fit-content'}
                    pointer
                    onClick={() => combobox.toggleDropdown()}
                >
                    { label }
                </Input>
            </Combobox.DropdownTarget>

            <Combobox.Dropdown className='static bg-grey-gradient'>
                <Combobox.Options classNames="h5 p-4">{options}</Combobox.Options>
            </Combobox.Dropdown>
        </Combobox>
    );
}
