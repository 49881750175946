import React from 'react';
import { IconExternalLink } from "../SVGs/IconExternalLink";
import DOMPurify from 'dompurify';

function LeftRightImageBlock({ imgSrc, heading, description, imagePosition, btnOneText, btnOneLink, btnTwoText, btnTwoLink}) {

  const sanitizedDescription = DOMPurify.sanitize(description);
  const isExternalLink = (url) => {
    try {
      const link = new URL(url);
      return link.origin !== window.location.origin;
    } catch (error) {
      return false;
    }
  };

  const handleButtonClick = (url) => {
    if (url) {
      window.open(url, isExternalLink(url) ? "_blank" : "_self", isExternalLink(url) ? "noopener noreferrer" : "");
    }
  };

  return (
    <div className="max-w-screen-xl grid grid-cols-6 md:grid-cols-12 gap-4 px-4 items-center h-full mx-auto">
      <img
        src={imgSrc}
        alt=""
        className={`col-span-6 md:col-span-7 md:mb-0 ${imagePosition === 'Right' ? 'md:order-last mb-12' : ''}`}
      />
      <div
          className={`col-span-6 md:col-span-5 flex flex-col items-start justify-center space-y-4 ${imagePosition === 'Right' ? 'md:pl-4' : ''}`}>
        <h2 className="text-left">{heading}</h2>
        <span className="text-grey-1 block" dangerouslySetInnerHTML={{__html: sanitizedDescription}}></span>
        <div className="flex">
          {btnOneText && btnOneLink && (
              <button
                  className="button--secondary flex items-center justify-center"
                  onClick={() => handleButtonClick(btnOneLink)}
              >
                {btnOneText}
                {isExternalLink(btnOneLink) && <IconExternalLink width={18} height={19}/>}
              </button>
          )}
          {btnTwoText && btnTwoLink && (
              <button
                  className="ml-2 button--secondary flex items-center justify-center"
                  onClick={() => handleButtonClick(btnTwoLink)}
              >
                {btnTwoText}
                {isExternalLink(btnTwoLink) && <IconExternalLink width={18} height={19}/>}
              </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default LeftRightImageBlock;
