import React from 'react'

export const IconMapPin = ({ width, height }) => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width ? width : "24"} height={height ? height : "25"} fill="none">
            <path fill="currentColor"
                  d="M12 6.317a3.75 3.75 0 1 0 0 7.5 3.75 3.75 0 0 0 0-7.5Zm0 6a2.25 2.25 0 1 1 0-4.5 2.25 2.25 0 0 1 0 4.5Zm0-10.5a8.26 8.26 0 0 0-8.25 8.25c0 2.944 1.36 6.064 3.938 9.024a23.832 23.832 0 0 0 3.885 3.59.75.75 0 0 0 .861 0 23.83 23.83 0 0 0 3.879-3.59c2.573-2.96 3.937-6.08 3.937-9.024A8.26 8.26 0 0 0 12 1.817Zm0 19.313c-1.55-1.219-6.75-5.696-6.75-11.063a6.75 6.75 0 1 1 13.5 0c0 5.366-5.2 9.844-6.75 11.063Z"/>
        </svg>
    )
}