import React from 'react'

export const BurgerClose = ({ width, height }) => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={ width ? width : "32" } height={ height ? height : "32" } viewBox="0 0 32 32" fill="none">
            <path fill="#fff"
                  d="M23.281 22.22a.75.75 0 1 1-1.061 1.06L16 17.06l-6.219 6.22a.75.75 0 0 1-1.061-1.06L14.94 16 8.72 9.78A.75.75 0 0 1 9.78 8.72l6.22 6.22 6.219-6.22a.75.75 0 1 1 1.061 1.06L17.061 16l6.22 6.22Z"/>
        </svg>
    )
}