import React from 'react'
import NZTeam from "../shared/NZTeam";
import Opponent from "../shared/Opponent";

function LatestMatches({matches}) {

  return (
      <>
          <div className="max-w-screen-xl grid grid-cols-6 lg:grid-cols-12 gap-4 mx-auto px-4 custom-block">
              <h4 className="col-span-6 lg:col-span-12 text-center mb-10">Latest matches</h4>
              { matches && matches.map(match => (
                  <div key={match.id} className="gradient-border !bg-black-50 col-span-6 lg:col-span-4 clickable">
                      <a href={match.pageLink}>
                          <div className="flex justify-center my-8">
                              <NZTeam nzTeam={match.team} size='box' className='mr-3' />
                              <Opponent opponent={match.opposition} size='box' className='ml-3' />
                          </div>
                          <div className="flex flex-col items-center mb-8">
                              <div className="text-6xl flex items-center">
                                  <span className={ match.NZWon ? "font-bold text-white" : "text-grey-10"}>{match.fullTimeNZScore}</span> <span
                                  className="bg-grey-1 h-px w-[10px] mx-4"></span> <span
                                  className={ match.NZWon ? "text-grey-10" : "font-bold text-white"}>{match.fullTimeOppositionScore}</span>
                              </div>
                              <span className="h4 block text-white mt-8 mb-2">{match.date}</span>
                              <span className="block text-grey-10">{match.location}</span>
                          </div>
                      </a>
                  </div>
              ))}
          </div>
      </>
  )
}

export default LatestMatches
