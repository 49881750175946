import React from "react";
import {Box, Image} from "@mantine/core";
import {BlackFernsLogo} from "../../SVGs/NZLogos/BlackFernsLogo";
import {AllBlacksLogo} from "../../SVGs/NZLogos/AllBlacksLogo";

function Player({ row, playerName }) {

    return (
        <Box className='w-full gradient-border--nested'>
            <a className='flex items-center rounded-lg w-full !bg-black-50 min-h-[89px] max-h-[89px]' href={row.original.pageLink}>
                <div className='hidden md:block relative rounded-lg'>
                    <div className="player-gradient rounded-l-lg w-full h-full absolute z-20"></div>
                    <Image
                        alt="profilePic"
                        src={row.original.profilePhoto}
                        w={98}
                        h={89}
                        className='object-top relative z-10 min-w-[98px] rounded-l-lg rounded-r-0'
                    />
                    <span className="absolute bottom-0 right-0 pb-2 pr-1 z-30">
                        {row.original.team === 'Black Ferns' ? <BlackFernsLogo width="24" height="15"/> :
                            <AllBlacksLogo width="24" height="15"/>}
                    </span>
                </div>
                <div className='flex flex-col text-left px-5 overflow-hidden'>
                    <span className="font-bold truncate md:hidden">{row.original.firstName}</span>
                    <span className="font-bold truncate md:hidden">{row.original.lastName}</span>
                    <span className="hidden md:block text-2xl font-bold truncate">{playerName}</span>
                    <span className="truncate">{row.original.position ? row.original.position : ''}</span>
                </div>
            </a>
        </Box>
    )
}

export default Player;
