import React from 'react'
import {IconRedCard} from "../SVGs/IconRedCard";
import {IconYellowCard} from "../SVGs/IconYellowCard";
import NZTeam from "../shared/NZTeam";
import Opponent from "../shared/Opponent";

function MatchPageMainBlock({match}) {

    return (
        <div className="max-w-screen-xl grid grid-cols-6 md:grid-cols-12 gap-4 mx-auto px-4 custom-block">
            <div
                className="gradient-border col-span-6 md:col-span-8 md:col-start-3 flex flex-col items-center relative">
                <div className="flex items-end mt-10 mb-10 md:-mt-8 md:mb-16">
                    {/* NZ On the left */}
                    <div className="flex flex-col items-center mr-8 md:mr-16">
                        <NZTeam nzTeam={match.team} size='box' className='w-[48px] h-[48px] lg:w-[120px] lg:h-[120px] mb-2' />
                        <span className="text-grey-10 uppercase text-sm md:text-body">({ match.NZHome })</span>
                    </div>
                    <div className="flex flex-col items-center mb-6 md:-mb-6 lg:mb-1.5">
                        <div className="text-4xl md:text-6xl flex items-center">
                            <span className={ parseInt(match.fullTimeNZScore) < parseInt(match.fullTimeOppositionScore) ? "text-grey-10" : "font-bold text-white" }>{ match.fullTimeNZScore }</span>
                            <span className="bg-grey-1 h-px w-[10px] mx-4"></span>
                            <span className={ parseInt(match.fullTimeNZScore) > parseInt(match.fullTimeOppositionScore) ? "text-grey-10" : "font-bold text-white" }>{ match.fullTimeOppositionScore }</span>
                        </div>
                    </div>
                    <div className="flex flex-col items-center ml-8 md:ml-16">
                        <Opponent opponent={match.opposition} size='box' className="w-[48px] h-[48px] lg:w-[120px] lg:h-[120px] mb-2 stroke" />
                        <span className="text-grey-10 uppercase text-sm md:text-body">({ match.OppHome })</span>
                    </div>
                </div>
                <div className="gradient-border--nested mb-12 md:mb-20">
                    <div className="flex flex-col items-center !px-6 !py-3 !bg-black-50">
                        <h6 className="text-grey-50">Last 5 matches</h6>
                        <div className="flex items-center">
                            <span className="text-grey-1">{match.Last5.NZWins}</span>
                            <span className="bg-grey-50 h-px w-[4px] mx-4"></span>
                            <span className="text-grey-1">{match.Last5.OppWins}</span>
                        </div>
                    </div>
                </div>
                <ul className="w-full match-stats-list text-center mb-16 md:mb-32">
                    <li>
                        <div className="flex justify-between px-16 mb-6">
                            <span>{ match.fullTimeNZScore }</span>
                            <span className="text-grey-1 font-normal">Points scored</span>
                            <span>{ match.fullTimeOppositionScore }</span>
                        </div>
                        <div className="flex justify-between px-16">
                            <span className="text-grey-1 font-normal">{ match.halfTimeNZScore }</span>
                            <span className="text-grey-1 font-normal">Half time</span>
                            <span className="text-grey-1 font-normal">{ match.halfTimeOppositionScore }</span>
                        </div>
                    </li>
                    <li>
                        <div className="flex justify-between px-16">
                            <span className={match.NZTries === 0 ? 'text-grey-10' : ''}>{ match.NZTries }</span>
                            <span className="text-grey-1 font-normal">Tries</span>
                            <span className={match.OppTries === 0 ? 'text-grey-10' : ''}>{ match.OppTries }</span>
                        </div>
                    </li>
                    <li>
                        <div className="flex justify-between px-16">
                            <span className={match.NZConversions === 0 ? 'text-grey-10' : ''}>{ match.NZConversions }</span>
                            <span className="text-grey-1 font-normal">Conversions</span>
                            <span className={match.OppConversions === 0 ? 'text-grey-10' : ''}>{ match.OppConversions }</span>
                        </div>
                    </li>
                    <li>
                        <div className="flex justify-between px-16">
                            <span className={match.NZDropGoal === 0 ? 'text-grey-10' : ''}>{ match.NZDropGoal }</span>
                            <span className="text-grey-1 font-normal">Drop goals</span>
                            <span className={match.OppDropGoal === 0 ? 'text-grey-10' : ''}>{ match.OppDropGoal }</span>
                        </div>
                    </li>
                </ul>
                <ul className="w-full match-stats-list text-center mb-16 md:mb-32">
                    <li>
                        <div className="flex justify-between px-16">
                            <span className={match.NZPenaltyGoal === 0 ? 'text-grey-10' : ''}>{ match.NZPenaltyGoal }</span>
                            <span className="text-grey-1 font-normal">Penalty goals</span>
                            <span className={match.OppPenaltyGoal === 0 ? 'text-grey-10' : ''}>{ match.OppPenaltyGoal }</span>
                        </div>
                    </li>
                    <li>
                        <div className="flex justify-between px-16">
                            <span className={match.NZRedCard === 0 ? 'text-grey-10' : ''}>{ match.NZRedCard }</span>
                            <div className="flex items-center">
                                <IconRedCard width={24} height={24}/>
                                <span className="text-grey-1 font-normal">Red cards</span>
                            </div>
                            <span className={match.OppRedCard === 0 ? 'text-grey-10' : ''}>{ match.OppRedCard }</span>
                        </div>
                    </li>
                    <li>
                        <div className="flex justify-between px-16">
                            <span className={match.NZYellowCard === 0 ? 'text-grey-10' : ''}>{ match.NZYellowCard }</span>
                            <div className="flex items-center">
                                <IconYellowCard width={24} height={24}/>
                                <span className="text-grey-1 font-normal">Yellow cards</span>
                            </div>
                            <span className={match.OppYellowCard === 0 ? 'text-grey-10' : ''}>{ match.OppYellowCard }</span>
                        </div>
                    </li>
                </ul>
                <ul className="w-full match-stats-list text-center mb-16">
                    <li>
                        <div className="flex justify-between px-16">
                            <span className="font-normal">{ match.NZCoach }</span>
                            <span className="text-grey-1 font-normal">Head coach</span>
                            <span className="font-normal">Unknown</span>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default MatchPageMainBlock
