import React from 'react'

export const IconExternalLink = ({ width, height }) => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={ width ? width : "18" } height={ height ? height : "19" } viewBox="0 0 18 19" fill="none">
            <path fill="currentColor"
                  d="M18 7.25a.75.75 0 1 1-1.5 0V3.061l-6.218 6.22A.75.75 0 0 1 9.22 8.219L15.44 2H11.25a.75.75 0 1 1 0-1.5h6a.75.75 0 0 1 .75.75v6ZM14.25 9.5a.75.75 0 0 0-.75.75V17h-12V5h6.75a.75.75 0 0 0 0-1.5H1.5A1.5 1.5 0 0 0 0 5v12a1.5 1.5 0 0 0 1.5 1.5h12A1.5 1.5 0 0 0 15 17v-6.75a.75.75 0 0 0-.75-.75Z"/>
        </svg>
    )
}