import React from 'react';
import TeamCard from '../card_carousel/TeamCard';
import {countryBackgroundColors} from "../shared/Opponent";

// Utility to adjust color opacity
const addOpacityToColor = (hex, opacity) => {
  const rgb = hex.match(/\w\w/g).map((c) => parseInt(c, 16));
  return `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${opacity})`;
};

function MatchCTA({ data }) {
  // Get the base color for the team's country
  const baseColor = countryBackgroundColors[data.country] || '#333';

  // Generate gradient stops based on the base color
  const gradientStops = {
    midShade: addOpacityToColor(baseColor, 1), // Base color (solid in the middle)
    fullyTransparent: addOpacityToColor(baseColor, 0), // Fully transparent
  };

   // Define the radial gradient with reversed stops
    const radialGradient = `radial-gradient(circle, 
  ${gradientStops.midShade} 0%, 
  ${gradientStops.fullyTransparent} 60%)`;

  const handleButtonClick = (url) => {
    if (url) {
      window.open(url, isExternalLink(url) ? "_blank" : "_self", isExternalLink(url) ? "noopener noreferrer" : "");
    }
  };

  return (
    <div className="max-w-screen-xl flex flex-col lg:grid lg:grid-cols-12 gap-4 px-4 items-center h-full mx-auto custom-block">

      <div className="relative col-span-4 col-start-2 mb-12 lg:mb-0">
        {/* Gradient container */}
        {data && (
          <div
            className="absolute inset-0 -z-10"
            style={{
              background: radialGradient,
              borderRadius: '12px',
              transform: 'scale(1.2)',
              width: '140%',
              height: '120%',
              left: '-22%',
              top: '-10%',
            }}
          ></div>
        )}
        {/* TeamCard */}
        {data && (
          <div className="relative z-20 card--team">
            <TeamCard
              key={`${data.country}-${data.teamName}`}
              item={data}
              isActive
              handleMouseMove={null}
              handleMouseLeave={null}
              shinePosition={null}
            />
          </div>
        )}
      </div>
      <div className="col-span-6 lg:col-span-4 lg:col-start-8 flex flex-col items-start justify-center space-y-4 z-100">
        <h2 className="text-center sm:text-left">View the rivalry</h2>
        <p className="text-grey-1 text-left pb-6">
          Learn how we stack up against our rivals {`${data.country}`} after all these years.
        </p>
          <div className="flex">
          {data.pageLink && (
            <a
              className="button button--secondary flex items-center justify-center"
              href={data.pageLink}
            >
              Learn more
            </a>
          )}
          </div>
      </div>
    </div>
  );
}

export default MatchCTA;
