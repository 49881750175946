import React from 'react'

export const IconRain = ({ width, height }) => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width ? width : "24"} height={height ? height : "25"} fill="none">
            <path fill="currentColor"
                  d="m14.874 18.733-3 4.5a.752.752 0 0 1-1.248-.832l3-4.5a.75.75 0 0 1 1.248.832Zm6.876-9.79a7.132 7.132 0 0 1-7.125 7.124h-2.224l-2.777 4.166a.75.75 0 1 1-1.248-.832l2.222-3.334H7.125a4.875 4.875 0 1 1 .836-9.678A7.132 7.132 0 0 1 21.75 8.942Zm-1.5 0A5.63 5.63 0 0 0 9 8.61a.751.751 0 1 1-1.5-.086c.013-.229.037-.455.072-.678a3.375 3.375 0 1 0-.447 6.72h7.5a5.632 5.632 0 0 0 5.625-5.624Z"/>
        </svg>
    )
}