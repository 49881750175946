import React, { useState, useEffect, useRef } from 'react';
import DOMPurify from 'dompurify';
import { IconArrowRight } from '../SVGs/IconArrowRight';

function PlayerMainContent({ player }) {
    const [isExpanded, setIsExpanded] = useState(false);
    const [showReadMore, setShowReadMore] = useState(false);
    const textContentRef = useRef(null);
    const buttonRef = useRef(null);
    const isSmallScreen = window.innerWidth < 1024;

    const cleanProfile = DOMPurify.sanitize(player.profile);

    const handleReadMoreClick = () => {
        setIsExpanded((prevState) => !prevState);
        if (buttonRef.current) {
            buttonRef.current.blur(); // Remove focus from the button
        }
    };

     useEffect(() => {
        if (!isExpanded && buttonRef.current) {
            // Scroll the read more/less button into view after collapsing the content
            buttonRef.current.scrollIntoView({ block: 'center' });
        }
    }, [isExpanded]);

    useEffect(() => {
        // Check the height of the text-content div
        if (textContentRef.current) {
            setShowReadMore(textContentRef.current.offsetHeight >= 450);
        }
    }, [player.profile]);

    return (
        <>
            <div className="max-w-screen-xl grid grid-cols-6 lg:grid-cols-12 gap-4 px-4 mx-auto custom-block">
                <div className="flex flex-col col-span-6 lg:col-span-4 lg:col-start-2 order-2 lg:order-1">
                    <div className="gradient-border flex flex-row-reverse justify-end lg:block mb-4 py-5 px-8">
                        <div className="ml-auto lg:ml-0">
                            <h4 className="text-grey-1 mb-1">Birthplace</h4>
                            <span className="h3 block mb-1">{player.birthPlace}</span>
                            {player.otherBirthPlaceCount > 0 && player.otherBirthPlaceLink && (
                                <div className="flex text-purple-50 items-center">
                                    <a href={player.otherBirthPlaceLink}
                                       className="underline hover:no-underline mr-2 text-sm md:text-base xl:text-lg !text-purple-50">
                                        {player.otherBirthPlaceCount} other players from the region
                                    </a>
                                    <IconArrowRight
                                        className="ml-2"
                                        width={isSmallScreen ? 15 : 20}
                                        height={isSmallScreen ? 13 : 20}
                                    />
                                </div>
                            )}
                        </div>
                        <div className="lg:mt-6">
                            <h4 className="text-grey-1 mb-1">Born</h4>
                            <span className="h3 block mb-1">{player.birthYear}</span>
                        </div>
                    </div>
                    <div className="gradient-border mb-4 py-5 px-8">
                        <h4 className="text-grey-1 mb-1">High school</h4>
                        <span className="h3 block mb-1">{player.highSchool}</span>
                    </div>
                    {player.relatedPlayers && (
                        <div className="gradient-border--nested mb-4">
                            <div className="py-5 px-8">
                                <h4 className="text-grey-1 mb-1 ml-8 mt-4">Relatives</h4>
                                {player.relatedPlayers.map((relative) => (
                                    <div key={relative.id} className="gradient-border--nested mx-8 mb-8 flex items-stretch !rounded-xl">
                                        <div className="h-full mr-4">
                                            <img
                                                src={relative.image}
                                                className="w-auto object-cover h-[128px] rounded-l-xl"
                                                alt=""
                                            />
                                        </div>
                                        <div className="w-full !rounded-xl flex text-left">
                                            <div
                                                className="relative-text-container flex flex-col flex-grow pr-5 justify-center">
                                                <h6 className="">{relative.relation}</h6>
                                                <a href={relative.link} className="table-row-name block">{relative.name}</a>
                                                <p className="m-0">{relative.position}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
                <div className="col-2 flex flex-col col-span-6 lg:col-span-5 lg:col-start-7 order-1 lg:order-2">
                    <h2 className="md:mt-12 mb-6 md:mb-10">Behind the jersey</h2>
                    <div
                        ref={textContentRef}
                        className={`text-content relative transition-all duration-300 ${isExpanded ? '' : 'max-h-[450px] overflow-hidden'}`}
                        style={{
                            maskImage: showReadMore && !isExpanded ? 'linear-gradient(to bottom, rgba(0, 0, 0, 1) 70%, rgba(0, 0, 0, 0))' : 'none',
                            WebkitMaskImage: showReadMore && !isExpanded ? 'linear-gradient(to bottom, rgba(0, 0, 0, 1) 70%, rgba(0, 0, 0, 0))' : 'none',
                        }}
                        dangerouslySetInnerHTML={{ __html: cleanProfile }}
                    />
                    {showReadMore && (
                        <button
                            ref={buttonRef}
                            className={`button--tertiary mt-8 mb-8 mx-auto lg:mx-0 lg:mb-0 w-[141px]`}
                            onClick={handleReadMoreClick}
                        >
                            {isExpanded ? 'Read less' : 'Read more'}
                        </button>
                    )}
                </div>
            </div>
        </>
    );
}

export default PlayerMainContent;
