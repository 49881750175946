import React, { useEffect } from 'react';
import Glide from '@glidejs/glide';
import Opponent from "../shared/Opponent";
import NZTeam from "../shared/NZTeam";

function RivalryStatsBlock({ data }) {
  const stats = data.concat(data); // Duplicate stats for a continuous scroll effect

  useEffect(() => {
    const glide = new Glide('.glide--stats', {
      type: 'carousel',
      startAt: 0,
      gap: 15,
      perView: 5,
      peek: {
        before: 20,
        after: 20
      },
      autoplay: 4000,
      hoverpause: true,
      animationTimingFunc: 'linear',
      animationDuration: 1000,
      breakpoints: {
        600: {
          perView: 1,
          focusAt: 'center',
          gap: 15,
          peek: {
            before: 0,
            after: 0,
          },
        },
        400: {
          perView: 1,
          focusAt: 'center',
          gap: 15,
          peek: {
            before: 0,
            after: 0,
          },
        }
      },
    });
    glide.mount();

    return () => {
      glide.destroy();
    };
  }, []);

  return (
    <div className="glide glide--stats bg-black text-white w-full overflow-visible px-4 sm:px-6 custom-block">
      <div className="glide__track overflow-visible" data-glide-el="track">
        <ul className="glide__slides flex items-center overflow-visible">
          {stats.map((stat, index) => (
              <li key={index} className="glide__slide md:max-w-none max-399:min-w-[90vw] min-w-[360px] flex justify-center mr-[10px] overflow-visible">
                <div className="bg-black-50 p-5 rounded-lg text-center max-399:min-w-[90vw] min-w-[360px] min-h-[170px] relative overflow-visible flex flex-col justify-center items-center">
                  <div className="flex absolute left-1/2 top-[-23px] -translate-x-1/2">
                    {
                      (stat.teamA === 'All Blacks' || stat.teamA === 'Black Ferns') ? (
                          <NZTeam nzTeam={stat.teamA} size={'statsIconSingle'} />
                      ) : (
                          <Opponent opponent={stat.teamA} size={'statsIcon'} />
                      )
                    }
                    { stat.teamB && (
                        <Opponent opponent={stat.teamB} size={'statsIcon'} />
                    )}
                  </div>
                  <div className="text-grey-1 opacity-60 text-2xl mb-1">{stat.stat}</div>
                  <div>
                    {stat.value.includes('%') ? (
                        <>
                          <span className="text-4xl text-white font-bold">{stat.value.replace('%', '')}</span>
                          <span className="text-grey-1 text-2xl">%</span>
                        </>
                    ) : (
                        <span className="text-4xl text-white font-bold block mb-2">{stat.value}</span>
                    )}
                  </div>
                  {stat.subtext && (
                    <div className="h6 text-grey-1 opacity-60">{stat.subtext}</div>
                  )}
                </div>
              </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default RivalryStatsBlock;
