import React from "react";
import {Checkbox} from "@mantine/core";

function TeamToggle({ selectedTeams, setSelectedTeams }) {
    const handleValueSelect = (val) => {
        setSelectedTeams((current) => {
            if (current.includes(val)) {
                if (current.length > 1) {
                    return current.filter((v) => v !== val)
                } else {
                    // Can't deselect when there's only one selected
                    return current
                }
            } else {
                return [...current, val]
            }
        });
    }

    return (
        <div className='flex w-fit bg-black-50 rounded-xl px-6 py-4'>
            <Checkbox
                labelPosition="left"
                label="All Blacks"
                value="All Blacks"
                color="violet.6"
                iconColor="white"
                size="md"
                className="mr-8"
                checked={selectedTeams.includes("All Blacks")}
                onChange={(event) => handleValueSelect(event.currentTarget.value)}
            />
            <Checkbox
                labelPosition="left"
                label="Black Ferns"
                value="Black Ferns"
                color="violet.6"
                iconColor="white"
                size="md"
                checked={selectedTeams.includes("Black Ferns")}
                onChange={(event) => handleValueSelect(event.currentTarget.value)}
            />
        </div>
    )
}

export default TeamToggle;
