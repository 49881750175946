import React, { useState } from 'react';
import { Checkbox, Combobox, Group, Input, useCombobox } from '@mantine/core';
import { IconChevron } from "../../SVGs/IconChevron";


export function MultiSelectCheckbox({label, selectOptions, value, setValue, classNames=''}) {
    const combobox = useCombobox({
        onDropdownClose: () => combobox.resetSelectedOption(),
        onDropdownOpen: () => combobox.updateSelectedOptionIndex('active'),
    });

    const handleValueSelect = (val) =>
        setValue((current) =>
            current.includes(val) ? current.filter((v) => v !== val) : [...current, val]
        );

    const handleValueRemove = (val) =>
        setValue((current) => current.filter((v) => v !== val));

    const values = value.map((item) => (
        <span key={item}>
            {item}
        </span>
    ));

    const options = selectOptions.map((item) => (
        <Combobox.Option value={item} key={item} active={value.includes(item)}>
            <Group gap="sm" className='flex-nowrap'>
                <Checkbox
                    checked={value.includes(item)}
                    onChange={() => {}}
                    aria-hidden
                    tabIndex={-1}
                    style={{ pointerEvents: 'none' }}
                    iconColor="dark.8"
                    color="violet.6"
                />
                <span>{item}</span>
            </Group>
        </Combobox.Option>
    ));

    const Description = ({length}) => {
        if (length === 1) {
            return (<span className="pr-1 text-purple-10 truncate max-w-32 sm:max-w-48">{values[0]}</span>)
        } else if (length > 1) {
            return (<span className="pr-1 text-purple-10 truncate max-w-32 sm:max-w-48">{values[0]} + {values.length - 1}</span>)
        } else {
            return (<span className="pr-1">All</span>)
        }
    }
    const RightSide = ({dropdownOpened}) => {

        return (
            <div className="flex pr-4 justify-end">
                <Description length={values.length} />
                <IconChevron classname={dropdownOpened ? '' : 'rotate-180'} colour={values.length > 0 ? '#A48EFF' : 'white'} />
            </div>
        )
    };

    return (
        <Combobox store={combobox} onOptionSubmit={handleValueSelect} withinPortal={false}>
            <Combobox.DropdownTarget>
                <Input
                    component="button"
                    classNames={{input: 'bg-grey-gradient h5 px-4 py-2 h-fit ' + classNames, option: 'h5' }}
                    rightSection={<RightSide dropdownOpened={combobox.dropdownOpened} />}
                    rightSectionWidth={'fit-content'}
                    pointer
                    onClick={() => combobox.toggleDropdown()}
                >
                    { label }
                </Input>
            </Combobox.DropdownTarget>

            <Combobox.Dropdown className='static bg-grey-gradient'>
                <Combobox.Options classNames="h5 p-4">{options}</Combobox.Options>
            </Combobox.Dropdown>
        </Combobox>
    );
}
