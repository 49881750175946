import React from 'react'

export const Scotland = ({ width, height }) => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={ width ? width : "180" } height={ height ? height : "136" } viewBox="0 0 180 136" fill="none">
            <g clipPath="url(#clip0_4060_15032)">
                <path
                    d="M93.9519 87.7859C112.081 78.9716 121.467 61.579 114.922 48.9397C108.374 36.3087 88.3719 33.2066 70.2425 42.0209C52.1188 50.8353 42.7278 68.225 49.2725 80.8644C55.8172 93.5009 75.8225 96.6003 93.9519 87.7859Z"
                    fill="white"/>
                <path
                    d="M28.7412 28.0146C28.7412 28.0146 50.1893 1.87246 89.6121 0.592773C89.6121 0.592773 81.934 18.1231 87.815 30.7512C87.815 30.7512 69.68 33.7015 52.9765 46.0484C52.9765 46.0456 45.2871 30.8187 28.744 28.0146"
                    fill="white"/>
                <path
                    d="M93.3384 29.7219C93.3384 29.7219 93.2596 22.9606 106.594 15.7466C106.594 15.7466 102.94 3.56283 98.9803 0.840326C101.959 0.308763 127.434 5.16033 133.875 25.7535C133.875 25.7535 141.263 14.256 137.641 6.01814C137.641 6.01814 160.532 17.3384 148.553 44.6338C148.553 44.6366 133.447 23.7538 93.3384 29.7219Z"
                    fill="white"/>
                <path
                    d="M150.525 47.2409C150.525 47.2409 165.498 35.0262 165.892 22.4177C165.892 22.4177 174.957 32.6637 164.717 61.0333C164.717 61.0333 176.535 51.5777 177.325 50.3965C177.325 50.3965 188.766 79.9558 143.055 102.416C143.055 102.416 166.697 71.8052 150.525 47.2409Z"
                    fill="white"/>
                <path
                    d="M106.704 131.183C106.704 131.183 117.512 137.798 133.54 131.956C133.54 131.956 145.741 127.138 156.51 132.564C156.51 132.564 155.832 107.203 179.997 103.716C179.997 103.716 164.422 93.9116 133.948 112.927C133.948 112.927 124.827 122.942 106.704 131.183Z"
                    fill="white"/>
                <path
                    d="M22.9272 34.9025C22.9272 34.9025 21.6306 45.9275 27.4806 51.5018C27.4806 51.5018 38.4184 45.8347 48.6278 49.4853C48.6278 49.4853 26.485 66.0593 21.0737 91.5406C21.0709 91.5406 -4.23596 69.6931 22.9272 34.9025Z"
                    fill="white"/>
                <path
                    d="M20.4609 94.7441C20.4609 94.7441 15.0019 116.24 35.1084 130.913C35.1084 130.913 18.5203 140.636 0 113.841C0 113.841 4.72781 115.81 12.6112 115.022C12.6112 115.022 -1.57219 106.346 0.39375 79.9475C0.39375 79.9475 8.24625 93.9538 20.4609 94.7441Z"
                    fill="white"/>
                <path
                    d="M92.8969 93.8919C94.5085 93.3631 101.008 90.0275 103.118 88.4806C105.266 92.84 112.12 108.86 105.261 126.283C105.261 126.283 65.9306 145.799 39.541 127.678C16.1353 111.605 26.5219 84.8244 33.84 73.985C33.84 73.985 36.6413 106.348 48.0685 111.864C48.0685 111.864 54.3741 109.895 54.3741 98.0769C54.3741 98.0769 70.1381 123.687 94.1766 121.322C94.1766 121.322 100.215 109.768 92.8969 93.8919Z"
                    fill="white"/>
                <path
                    d="M113.957 121.733C113.957 121.733 129.181 95.5371 121.765 71.6702C121.765 71.6702 132.798 78.3724 134.764 76.3981C134.764 76.3981 141.072 47.6318 122.552 35.4171C122.552 35.4171 168.14 44.0402 144.042 91.2256C144.042 91.2256 136.966 107.788 113.96 121.73"
                    fill="white"/>
                <path
                    d="M151.431 10.7599C151.431 6.58054 154.82 3.50366 158.828 3.50366C162.835 3.50366 166.177 6.58054 166.177 10.7599C166.177 14.9393 162.79 18.0555 158.828 18.0555C154.865 18.0555 151.431 14.9758 151.431 10.7599ZM158.828 16.8433C162.118 16.8433 164.714 14.2587 164.714 10.7599C164.714 7.26116 162.118 4.71304 158.828 4.71304C155.537 4.71304 152.896 7.30897 152.896 10.7599C152.896 14.2108 155.492 16.8433 158.828 16.8433ZM157.283 14.9758H156.015V6.58054H159.207C161.193 6.58054 162.172 7.3146 162.172 8.96835C162.172 10.473 161.229 11.1255 159.995 11.2802L162.385 14.9758H160.962L158.749 11.3393H157.286V14.9758H157.283ZM158.802 10.2593C159.877 10.2593 160.838 10.1833 160.838 8.89241C160.838 7.85179 159.899 7.66054 159.016 7.66054H157.281V10.2565H158.802"
                    fill="white"/>
            </g>
            <defs>
                <clipPath id={`clip0_4060_15032+${width}`}>
                    <rect width="180" height="135" fill="white" transform="translate(0 0.5)"/>
                </clipPath>
            </defs>
        </svg>
    )
}