import React from 'react'

export const BorderGradient = ({ width, height }) => {

    return (
        <a>
            <svg xmlns="http://www.w3.org/2000/svg" width="2" height="89" viewBox="0 0 2 89" fill="none">
                <path opacity="0.5" d="M1 0L1 103" stroke="url(#paint0_linear_3312_5522)"/>
                <defs>
                    <linearGradient id="paint0_linear_3312_5522" x1="0" y1="1.16329e-07" x2="4.50227e-06" y2="103"
                                    gradientUnits="userSpaceOnUse">
                        <stop stopColor="white" stopOpacity="0"/>
                        <stop offset="0.5" stopColor="white"/>
                        <stop offset="1" stopColor="white" stopOpacity="0"/>
                    </linearGradient>
                </defs>
            </svg>
        </a>
    )
}