import React from "react";
import {Box} from "@mantine/core";
import {MRT_GlobalFilterTextInput, MRT_ToggleGlobalFilterButton} from "mantine-react-table";

function TopToolbar({ table, tabs, filters, search = false }) {
    return (
        <Box className='flex justify-between align-center py-10 flex-wrap gap-y-5'>
            {tabs}
            <div className="flex ml-2">
                {filters}
                { search && (
                    <>
                        <MRT_ToggleGlobalFilterButton table={table} classNames={{ root: 'block lg:hidden button button--tertiary ml-3 min-w-12 px-2', icon: 'ml-0 !pl-0 !pr-1' }} />
                        <MRT_GlobalFilterTextInput classNames={{ input: 'rounded-lg h-[45px]' }} table={table} />
                    </>
                )}
            </div>
        </Box>
    )
}

export default TopToolbar;
