import React from 'react'
import {createRoot} from "react-dom/client";
import {MantineProvider} from "@mantine/core";
import CardCarousel from "./card_carousel/CardCarousel";
import RivalTable from "./tables/RivalTable";
import {removeLoader} from "../utils/removeLoader";

function AllRivalries({allRivalries, title, carouselRivalries}) {
    return (
        <div className="content-container mt-64 relative z-20">
            <div className="z-10 mt-80 text-center custom-block">
                <h1 className="col-span-12 text-white-100 text-4xl md:text-6xl lg:text-[88px] leading-none text-center">{title}</h1>
            </div>
            { carouselRivalries.length > 0 && (
                <CardCarousel dataType="teams" cardData={carouselRivalries}/>
            )}
            <RivalTable allRivals={allRivalries} withFilters />
        </div>

    )
}

export default function init(mountNode, theme) {
    const rivalriesData = document.getElementById('AllRivalriesPageData');
    const pageData = JSON.parse(rivalriesData.textContent.replace(/&quot;/ig, '"'));
    removeLoader();
    if (mountNode) {
        createRoot(mountNode).render(
            <MantineProvider theme={theme} forceColorScheme={'dark'}>
                <AllRivalries allRivalries={pageData.allRivalries} title={pageData.title} carouselRivalries={pageData.carouselRivalries}/>
            </MantineProvider>
        )
    }
}
