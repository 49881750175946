import React from "react";
import {Box} from "@mantine/core";

function Debut({ row, renderedCellValue }) {

    return (
        <Box className='flex items-center'>
            <div className='flex flex-col'>
                <span>{renderedCellValue[0]}</span>
                <span className='text-xs text-grey-10'>AGE {renderedCellValue[1]}</span>
            </div>
        </Box>
    )
}

export default Debut;
