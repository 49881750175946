import React, {useEffect, useRef} from 'react'
import Glide from '@glidejs/glide';

function HomePageHero() {
    const heroRef = useRef(null);
    const images = [
        '/assets/images/HeroBlockImage-1.png',
        '/assets/images/HeroBlockImage-2.png',
    ];

    useEffect(() => {
        const glide = new Glide('.glide--homepage-hero', {
            type: 'carousel',
            drag: false,
            startAt: 0,
            gap: 0,
            perView: 1,
            autoplay: 5000,
            hoverpause: true,
            animationDuration: 0,
            rewindDuration: 0,
        });

        glide.on('mount.after', () => {
            const slides = document.querySelector('.glide__slides');
            slides.classList.remove('hidden-initial');
        });

        glide.mount();

        // Intersection Observer to fade in background gradient element after scrolling past hero
        const observer = new IntersectionObserver(
            ([entry]) => {
                const backgroundGradient = document.getElementById('background-gradient--top');
                if (backgroundGradient) {
                    if (!entry.isIntersecting) {
                        backgroundGradient.classList.remove('opacity-0');
                        backgroundGradient.classList.add('opacity-100');
                    } else {
                        backgroundGradient.classList.remove('opacity-100');
                       backgroundGradient.classList.add('opacity-0');
                    }
                }
            },
            {
                rootMargin: '100px 0px 0px 0px',
                threshold: 0
            }
        );

        if (heroRef.current) {
            observer.observe(heroRef.current);
        }

        return () => {
            glide.destroy();
            if (heroRef.current) {
                observer.unobserve(heroRef.current);
            }
        };
    }, []);


  return (
      <>
          <div ref={heroRef} className="glide glide--homepage-hero bg-black text-white w-full overflow-visible relative">
              <div className="absolute inset-0 gradient-overlay z-10 pointer-events-none"></div>
              <div className="glide__track overflow-visible" data-glide-el="track">
                  <ul className="glide__slides overflow-visible hidden-initial">
                      {images.map((src, index) => (
                            <li key={index} className="glide__slide">
                                <img src={src} alt={`Slide ${index + 1}`} className="w-full" />
                            </li>
                        ))}
                  </ul>
              </div>
          </div>
          <div
              className="z-10 text-center md:absolute md:bottom-6 md:bottom-8 lg:-bottom-12 xl:-bottom-14 2xl:-bottom-32 md:left-0 md:right-0 m-auto">
              <h1 className="col-span-12 uppercase text-center">
                  Data of Champions<br/>
                  <span className="text-black-100 text-stroke-white">Stories of Legends</span>
              </h1>
          </div>
      </>
  )
}

export default HomePageHero
