import React from 'react'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import './index.scss'
import Footer from "./modules/footer/Footer";
import Header from "./modules/header/Header";
import HomePageHero from "./modules/homepage_hero/HomepageHero";
import LeftRightImageBlock from "./modules/left_right_image_block/LeftRightImageBlock";
import TopImageBlock from "./modules/top_image_block/TopImageBlock";
import TextHeadingBlock from "./modules/text_heading_block/TextHeadingBlock";
import AllPlayers from "./modules/AllPlayers";
import AllMatches from "./modules/AllMatches";
import AllRivalries from "./modules/AllRivalries";
import PlayerPage from "./modules/PlayerPage";
import MatchPage from "./modules/MatchPage";
import RivalryPage from "./modules/RivalryPage";
import {createTheme, rem} from '@mantine/core';
import StatsBlock from "./modules/stats_block/StatsBlock";

const header = document.getElementById('header')
if (header) {
    Header(header);
}

const footer = document.getElementById('footer')
if (footer) {
    Footer(footer);
}

const homepageHeroElement = document.getElementById('homepage-hero');
if (homepageHeroElement) {
    createRoot(document.getElementById('homepage-hero')).render(
        <StrictMode>
            <HomePageHero/>
        </StrictMode>,
    )
}

document.querySelectorAll('.left-right-image-block').forEach(block => {
    const {
        imgSrc,
        heading,
        description,
        imagePosition,
        btnOneText,
        btnOneLink,
        btnTwoText,
        btnTwoLink
    } = block.dataset;

    createRoot(block).render(
        <StrictMode>
            <LeftRightImageBlock
                imgSrc={imgSrc}
                heading={heading}
                description={description}
                imagePosition={imagePosition}
                btnOneText={btnOneText}
                btnOneLink={btnOneLink}
                btnTwoText={btnTwoText}
                btnTwoLink={btnTwoLink}
            />
        </StrictMode>
    );
});

document.querySelectorAll('.top-image-block').forEach(block => {
    const {
        imgSrc,
        heading,
        description,
        btnOneText,
        btnOneLink,
    } = block.dataset;

    createRoot(block).render(
        <StrictMode>
            <TopImageBlock
                imgSrc={imgSrc}
                heading={heading}
                description={description}
                btnOneText={btnOneText}
                btnOneLink={btnOneLink}
            />
        </StrictMode>
    );
});

document.querySelectorAll('.text-heading-block').forEach(block => {
    const {
        heading,
        description,
    } = block.dataset;

    createRoot(block).render(
        <StrictMode>
            <TextHeadingBlock
                heading={heading}
                description={description}
            />
        </StrictMode>
    );
});

const matchCTA = document.getElementById('match-cta');
if (matchCTA) {
    const { imgSrc, heading, description, btnText, btnLink } = matchCTA.dataset;
    createRoot(document.getElementById('match-cta')).render(
        <StrictMode>
            <MatchCTA
                imgSrc={imgSrc}
                heading={heading}
                description={description}
                btnText={btnText}
                btnLink={btnLink}
            />
        </StrictMode>,
    )
}

const playerCTA = document.getElementById('player-cta');
if (playerCTA) {
    createRoot(document.getElementById('player-cta')).render(
        <StrictMode>
            <PlayerCTA />
         </StrictMode>,
    )
}

const matchCenterCTA = document.getElementById('match-center-cta');
if (matchCenterCTA) {
    createRoot(document.getElementById('match-center-cta')).render(
        <StrictMode>
            <MatchCenterCTA />
         </StrictMode>,
    )
}

const statsBlock = document.getElementById('stats-block');
if (statsBlock) {
    const dataNode = document.getElementById('HomePageStats');
    const pageData = JSON.parse(dataNode.textContent);
    createRoot(document.getElementById('stats-block')).render(
        <StrictMode>
            <StatsBlock data={pageData.homepageStats} />
        </StrictMode>,
    )
}

const rivalryContentBlock = document.getElementById('rivalry-content-block');
if (rivalryContentBlock) {
    const { imgSrc, heading, content, imagePosition } = rivalryContentBlock.dataset;
    createRoot(document.getElementById('rivalry-content-block')).render(
        <StrictMode>
            <RivalryContentBlock
                imgSrc={imgSrc}
                heading={heading}
                content={content}
                imagePosition={imagePosition}
            />
        </StrictMode>,
    )
}


const rivalryTopScorers = document.getElementById('rivalry-top-scorers');
if (rivalryTopScorers) {
    createRoot(document.getElementById('rivalry-top-scorers')).render(
        <StrictMode>
            <RivalryTopScorers />
         </StrictMode>,
    )
}

// createRoot(document.getElementById('footer')).render(
//   <StrictMode>
//     <Footer footerItems={document.footer}/>
//   </StrictMode>,
// )


const theme = createTheme({
    colors: {
        // Have to specify all 10, so ive left the defaults and added ours in
        grey: [
            '#ffffff', // AKA light-1
            '#f1f3f5',
            '#e9ecef',
            '#dee2e6',
            '#949599', // Lightest grey     Grey-4      Greyscales/Grey | highlight
            '#797B83', // Next grey         Grey-5
            '#66676C', // Darkest           Grey-6
            '#495057',
            '#343a40',
            '#212529',
        ],
        // Hacking the 'dark' theme. Is this a good idea? We'll see
        dark: [
            '#ffffff',
            '#b8b8b8',
            '#828282',
            '#696969',
            '#424242',
            '#1C1C1C', // Lightest black    Dark-5
            '#191717', // Middle black      Dark-6      Greyscales/Black | tinted
            '#000000', // Darkest           Dark-7
            '#000000',
            '#000000',
        ],
        violet: [
            '#f3f0ff',
            '#e5dbff',
            '#d0bfff',
            '#A48EFF', // Purple/Purple | highlight     Violet-3
            '#9775fa',
            '#845ef7',
            '#7958FF', // Purple/Purple                 Violet-6
            '#7048e8',
            '#6741d9',
            '#2E1C74', // Purple/Purple | shadow        Violet-9
        ],
    },
    shadows: {
        md: '1px 1px 3px rgba(0, 0, 0, .25)',
        xl: '5px 5px 3px rgba(0, 0, 0, .25)',
    },
    primaryShade: { light: 1, dark: 7 }, // Make the default text colour white
    headings: {
        fontFamily: 'NZRugbyChisel, serif',
        sizes: {
            h1: { fontSize: rem(36) },
        },
    },
});

const playersSelector = document.getElementById('AllPlayersPage');
if (playersSelector) {
    AllPlayers(playersSelector, theme);
}

const playerSelector = document.getElementById('PlayerPage');
if (playerSelector) {
    PlayerPage(playerSelector, theme);
}

const matchesSelector = document.getElementById('AllMatchesPage');
if (matchesSelector) {
    AllMatches(matchesSelector, theme);
}

const matchSelector = document.getElementById('MatchPage');
if (matchSelector) {
    MatchPage(matchSelector, theme);
}

const rivalriesSelector = document.getElementById('AllRivalriesPage');
if (rivalriesSelector) {
    AllRivalries(rivalriesSelector, theme);
}

const rivalrySelector = document.getElementById('RivalryPage');
if (rivalrySelector) {
    RivalryPage(rivalrySelector, theme);
}
