import React from "react";
import {Box} from "@mantine/core";
import {BorderGradient} from "../../SVGs/BorderGradient";

function WinRate({ renderedCellValue }) {

    return (
        <Box className='flex items-center'>
            <BorderGradient/>
            <div className="ml-6">
                <span>{renderedCellValue}</span>
                <span className='ml-1 text-xs text-grey-10'>%</span>
            </div>
        </Box>
)
}

export default WinRate;
