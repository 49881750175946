import React from "react";
import {Box} from "@mantine/core";
import {BorderGradient} from "../../SVGs/BorderGradient";
import {IconRedCard} from "../../SVGs/IconRedCard";

function RedCard({ renderedCellValue }) {

    return (
        <Box className={'flex' + (renderedCellValue === 0 ? ' opacity-40' : '')}>
            <IconRedCard width={32} height={32}/>
            <span>{ renderedCellValue }</span>
        </Box>
    )
}

export default RedCard;
