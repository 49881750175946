import React from "react";
import {Box} from "@mantine/core";
import {IconYellowCard} from "../../SVGs/IconYellowCard";

function YellowCard({ renderedCellValue }) {

    return (
        <Box className={'flex' + (renderedCellValue === 0 ? ' opacity-40' : '')}>
            <IconYellowCard width={32} height={32}/>
            <span>{ renderedCellValue }</span>
        </Box>
    )
}

export default YellowCard;
