import React from 'react'

export const AllBlacksLogo = ({ width, height }) => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width ? width : "88"} height={height ? height : "74"}
             viewBox="0 0 88 74" fill="none" className="svg svg-allblacks-logo-new">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M85.0739 62.0215H85.2459L85.719 62.8387H86.1922L85.676 61.9785C86.0201 61.9785 86.2352 61.8064 86.2352 61.4624C86.2352 61.1183 85.9341 60.8602 85.3749 60.8602H84.6438V62.8817H85.1169V62.0645L85.0739 62.0215ZM85.0739 61.6774V61.2043H85.3749C85.504 61.2043 85.719 61.2043 85.719 61.4194C85.719 61.6344 85.633 61.6774 85.461 61.6774H85.0739ZM85.3749 63.6989C86.3642 63.6989 87.3104 63.0108 87.3104 61.8495C87.3104 60.6882 86.3642 60 85.3749 60C84.3857 60 83.4395 60.6882 83.4395 61.8495C83.4395 63.0108 84.3427 63.6989 85.3749 63.6989ZM86.6653 61.8495C86.6653 62.6667 86.0631 63.2258 85.3749 63.2258C84.6868 63.2258 84.0846 62.6667 84.0846 61.8495C84.0846 61.0323 84.6438 60.4731 85.3749 60.4731C86.1061 60.4731 86.6653 61.0323 86.6653 61.8495Z"
                  fill="white"/>
            <path
                d="M63.8695 67.0541C63.8695 64.9896 63.1383 63.6562 59.7404 63.6562C56.3426 63.6562 55.5254 64.7315 55.5254 68.3874C55.5254 71.7853 56.2136 73.1186 59.7404 73.1186C63.2673 73.1186 63.8265 71.5272 63.8265 69.5487H60.4716V70.538C60.4716 70.9681 60.2566 71.2692 59.7404 71.2692C59.2243 71.2692 59.0093 70.9681 59.0093 70.538V66.2799C59.0093 65.8498 59.2243 65.5487 59.7404 65.5487C60.2566 65.5487 60.4716 65.8498 60.4716 66.2799V67.0971H63.8265L63.8695 67.0541Z"
                fill="white"/>
            <path
                d="M29.032 72.8602V65.1183C29.032 64.8602 28.9029 64.7312 28.7309 64.6452C28.6449 64.6022 28.5588 64.5591 28.4728 64.5591C28.4298 64.5591 28.3438 64.5591 28.3008 64.5591V64H33.7201C36.2578 64 37.032 64.7312 37.032 66.1505C37.032 67.5699 36.1717 68.129 34.8384 68.3441C36.2148 68.5161 37.204 69.0753 37.204 70.5806C37.204 72.086 36.5588 72.8602 34.3223 72.8602H28.9889H29.032ZM32.3438 69.2043V71.0107H33.032C33.5481 71.0107 33.8062 70.5376 33.8062 69.9785C33.8062 69.4194 33.5051 69.2043 33.118 69.2043H32.3868H32.3438ZM32.3438 65.8065V67.4409H33.032C33.4621 67.4409 33.7201 67.0538 33.7201 66.6237C33.7201 66.1935 33.4621 65.8065 33.032 65.8065H32.3438Z"
                fill="white"/>
            <path
                d="M38.6667 72.8602V65.2043C38.6667 64.9032 38.5377 64.7312 38.3657 64.6452C38.2796 64.6022 38.1936 64.5591 38.1076 64.5591C38.0646 64.5591 37.9786 64.5591 37.9355 64.5591V64H42.0646V70.7527H44.9893V72.8602H38.6237H38.6667Z"
                fill="white"/>
            <path
                d="M10.5378 72.8602V65.2043C10.5378 64.9032 10.4088 64.7312 10.2367 64.6452C10.1507 64.6022 10.0647 64.5591 9.97868 64.5591C9.93567 64.5591 9.84965 64.5591 9.80664 64.5591V64H13.9357V70.7527H16.8604V72.8602H10.4948H10.5378Z"
                fill="white"/>
            <path
                d="M17.8913 72.8602V65.2043C17.8913 64.9032 17.7623 64.7312 17.5903 64.6452C17.5042 64.6022 17.4182 64.5591 17.3322 64.5591C17.2892 64.5591 17.2032 64.5591 17.1602 64.5591V64H21.2892V70.7527H24.2139V72.8602H17.8483H17.8913Z"
                fill="white"/>
            <path
                d="M50.493 65.7635L49.8048 69.7205H51.1381L50.493 65.7635ZM45.7618 72.8603L47.8263 65.1613C47.8693 64.9033 47.8263 64.6882 47.6543 64.6022C47.5683 64.5592 47.5252 64.5592 47.4392 64.5162C47.3962 64.5162 47.3102 64.5162 47.2672 64.5162V63.957H52.7726L55.0951 72.8603H51.6113L51.3532 71.4839H49.4607L49.2027 72.8603H45.6758H45.7618Z"
                fill="white"/>
            <path
                d="M4.8172 65.7635L4.12903 69.7205H5.46236L4.8172 65.7635ZM0.0860202 72.8603L2.15054 65.1613C2.19355 64.9033 2.15054 64.6882 1.97849 64.6022C1.89247 64.5592 1.84946 64.5592 1.76344 64.5162C1.72043 64.5162 1.63441 64.5162 1.5914 64.5162V63.957H7.09677L9.41935 72.8603H5.93548L5.67742 71.4839H3.78494L3.52688 72.8603H0H0.0860202Z"
                fill="white"/>
            <path
                d="M64.4297 63.9141H68.5587V66.3657L71.0103 63.9141H75.0103L71.5695 67.3119L74.8813 72.8173H71.2684L69.3329 69.5055L68.6017 70.2366V72.8173H65.2039V65.1614C65.2039 64.8603 65.0748 64.6883 64.9028 64.6022C64.8168 64.5592 64.7308 64.5162 64.6447 64.5162C64.6017 64.5162 64.5157 64.5162 64.4727 64.5162V63.9571L64.4297 63.9141Z"
                fill="white"/>
            <path
                d="M83.0649 66.6668C82.9789 64.3872 81.2154 63.9141 79.1939 63.9141C77.1724 63.9141 75.581 64.7743 75.581 66.5807C75.581 68.3872 76.5703 68.8603 78.7638 69.8495C79.495 70.1936 79.9681 70.3657 79.9681 70.8388C79.9681 71.3119 79.7101 71.3549 79.28 71.3549C78.6778 71.3549 78.5488 70.9678 78.5488 70.3657H75.495C75.409 72.9463 77.3015 73.1614 79.409 73.1614C81.5165 73.1614 83.2799 72.2151 83.2799 70.4947C83.2799 68.7743 81.4735 67.9141 79.7961 67.2259C79.1079 66.9248 78.7638 66.7528 78.7638 66.2366C78.7638 65.7205 79.0219 65.7635 79.323 65.7635C79.9251 65.7635 80.0111 66.1506 80.0111 66.7098H83.0219L83.0649 66.6668Z"
                fill="white"/>
            <g clipPath="url(#clip0_5078_17274)">
                <mask id="mask0_5078_17274" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-2" y="0"
                      width="95" height="68">
                    <path d="M92.6803 0.645508H-1.11426V67.0814H92.6803V0.645508Z" fill="white"/>
                </mask>
                <g mask="url(#mask0_5078_17274)">
                    <mask id="mask1_5078_17274" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-2" y="0"
                          width="95" height="68">
                        <path d="M92.6812 0.645508H-1.11328V67.0814H92.6812V0.645508Z" fill="white"/>
                    </mask>
                    <g mask="url(#mask1_5078_17274)">
                        <path
                            d="M43.0027 12.2988C41.866 12.4395 40.748 12.5918 39.6582 12.7582L40.0496 25.4238L54.0816 32.7668C54.4871 32.5699 54.8973 32.3684 55.3074 32.1645L43.5793 24.1863L41.6457 24.852L43.0027 12.3012V12.2988Z"
                            fill="white"/>
                        <path
                            d="M36.2148 13.3578C35.0781 13.5852 34 13.8336 32.9898 14.1102L32.8398 28.5125L47.4016 35.9469C47.943 35.6914 48.5008 35.4289 49.0727 35.1594L36.4422 26.8508L34.4312 27.7508L36.2148 13.3555V13.3578Z"
                            fill="white"/>
                        <path
                            d="M39.373 12.8027C38.3863 12.9574 37.423 13.1215 36.4973 13.302L36.4434 26.8512L50.8832 34.3043C51.3566 34.0793 51.8371 33.8496 52.3246 33.6152L40.0527 25.4215L38.0371 26.1316L39.3754 12.8027H39.373Z"
                            fill="white"/>
                        <path
                            d="M49.0172 11.6396C48.1313 11.7264 47.2406 11.8178 46.3547 11.9139L46.9852 23.0982L45.0516 23.7217L46.057 11.9467C45.1289 12.0475 44.2031 12.1529 43.2891 12.2654L43.5773 24.1881L57.2156 31.21C57.607 31.0107 57.9984 30.8115 58.3898 30.6076L47.0461 23.0982L59.8922 29.8201C60.2742 29.6162 60.6563 29.4123 61.0383 29.2037L50.3227 21.9943L48.368 22.66L49.0102 11.642L49.0172 11.6396Z"
                            fill="white"/>
                        <path
                            d="M52.0949 11.3428C51.1809 11.4295 50.2598 11.5186 49.334 11.61L50.3301 21.9928L62.4027 28.4545C62.7473 28.2623 63.0895 28.0678 63.4316 27.8709L53.2293 20.9826L51.5488 21.5779L52.0949 11.3428Z"
                            fill="white"/>
                        <path
                            d="M16.5305 21.6811C14.8102 22.7428 13.4016 23.6896 12.4148 24.3787L11.9883 41.5584C11.9883 41.5584 8.68594 43.9279 7.44375 44.9732C6.20156 46.0209 3.92578 48.1607 3.92578 48.1607V52.4029C3.92578 52.4029 6.67734 48.6014 8.60391 46.7943C10.4813 45.0342 12.9047 43.0467 12.9047 43.0467L12.4594 42.4068L27.7852 49.3326C28.0312 49.0935 28.6289 48.5217 29.4867 47.7389L16.1977 39.0365L14.1258 40.6959L16.5281 21.6787L16.5305 21.6811Z"
                            fill="white"/>
                        <path
                            d="M20.71 19.2549C19.3412 19.9979 18.0709 20.7385 16.9225 21.4393L16.1982 39.0361L31.7373 45.758C32.2951 45.2822 32.8928 44.783 33.5232 44.2744L20.4943 35.8182L18.4178 37.3299L20.71 19.2549Z"
                            fill="white"/>
                        <path
                            d="M55.2023 11.042C54.2789 11.1334 53.3367 11.2225 52.3828 11.3139L53.2289 20.9818L64.7414 27.1061C65.1094 26.8881 65.475 26.6678 65.8383 26.4451L56.2125 19.9693L54.4055 20.6115L55.2023 11.042Z"
                            fill="white"/>
                        <path
                            d="M32.6853 14.1943C32.6103 14.2154 32.5307 14.2365 32.4557 14.2576C31.3307 14.5857 30.2103 14.9654 29.1064 15.3779L28.7432 30.6521L43.6846 37.7256C44.2518 37.4467 44.8518 37.1584 45.4846 36.8561L32.8424 28.5123L30.6885 29.6068L32.6877 14.1943H32.6853Z"
                            fill="white"/>
                        <path
                            d="M28.8418 15.4789C27.527 15.9781 26.2379 16.5266 24.991 17.1031L24.616 33.0852L22.5137 34.4305L24.7051 17.2344C23.416 17.8367 22.1762 18.4648 21.002 19.0977L20.498 35.8156L35.6129 42.65C36.1613 42.2375 36.7262 41.8297 37.2957 41.4312L24.6207 33.1273L39.4965 39.9805C40.0871 39.6148 40.6801 39.2703 41.266 38.9562C41.3012 38.9375 41.341 38.9164 41.3762 38.8977L28.748 30.65L26.6199 31.8688L28.8441 15.4766L28.8418 15.4789Z"
                            fill="white"/>
                        <path
                            d="M73.3869 7.14199C72.6111 7.46309 71.7932 7.7748 70.9424 8.07715L70.9635 13.6084L78.1049 15.1506C78.5033 14.5506 78.8807 13.9482 79.2369 13.3459L73.8158 11.8178L72.4213 12.7225L73.3846 7.13965L73.3869 7.14199Z"
                            fill="white"/>
                        <path
                            d="M70.674 8.17383C69.842 8.46445 68.9725 8.73867 68.0748 8.99883L68.0186 15.1395L76.2732 17.6824C76.6107 17.2559 76.9365 16.8246 77.2506 16.391L70.967 13.6113L69.5256 14.3684L70.6764 8.17617L70.674 8.17383Z"
                            fill="white"/>
                        <path
                            d="M78.5445 4.65566C77.8812 5.02598 77.1641 5.40332 76.3953 5.78066L76.3531 9.89394L74.9633 10.9861L76.1445 5.9002C75.3594 6.28223 74.5227 6.66191 73.6367 7.03457L73.8219 11.8182L79.9133 12.1768C80.2531 11.565 80.5695 10.9604 80.8648 10.3674L76.4375 9.84941L81.4133 9.22832C81.6781 8.65644 81.9266 8.09863 82.1539 7.55957L78.7742 7.7752L77.7406 8.71738L78.5445 4.65332V4.65566Z"
                            fill="white"/>
                        <path
                            d="M67.8383 9.06934C67.4398 9.18184 67.0367 9.29434 66.6266 9.39981C66.1508 9.52168 65.6375 9.63887 65.0937 9.74668L65.0703 16.4943L65.0586 16.499L74.3516 19.9068C74.675 19.5646 74.9891 19.2178 75.2937 18.8662L68.0211 15.1396L66.5773 15.8193L67.8383 9.06934Z"
                            fill="white"/>
                        <path
                            d="M84.4727 0.645508C84.4727 0.645508 84.0719 1.00644 83.2938 1.59238L82.975 3.09238L82.3422 3.88926L82.6867 2.03769C82.2813 2.33066 81.8055 2.65879 81.2641 3.01035L80.9266 5.55098L80.2281 6.3291L80.8961 3.24941C80.2703 3.64785 79.5625 4.07676 78.782 4.51738L78.7656 7.7752L82.6328 6.3666C82.8367 5.84395 83.0242 5.34707 83.1906 4.87832L81.1938 5.26973L83.5187 3.92441C84.1867 1.92285 84.468 0.647851 84.468 0.647851"
                            fill="white"/>
                        <path
                            d="M58.3881 10.7129C57.4576 10.816 56.4967 10.9145 55.5146 11.0129L56.2131 19.9707L67.2896 25.5324C67.6623 25.2934 68.0326 25.052 68.3936 24.8082L59.3186 18.8082L57.535 19.4902L58.3857 10.7129H58.3881Z"
                            fill="white"/>
                        <path d="M62.1039 17.7271L62.1086 17.7248L62.0898 17.7178L62.1039 17.7271Z" fill="white"/>
                        <path
                            d="M61.8307 10.2875C60.8275 10.4281 59.7658 10.5594 58.6572 10.6812L59.3205 18.807L69.64 23.9539C69.8955 23.7734 70.1486 23.593 70.3994 23.4102C70.5353 23.3117 70.6689 23.2109 70.8002 23.1102L62.1049 17.7266L60.558 18.3289L61.8307 10.2852V10.2875Z"
                            fill="white"/>
                        <path d="M65.0563 16.5008V16.4984L65.0469 16.4961L65.0563 16.5008Z" fill="white"/>
                        <path
                            d="M64.8051 9.80273C63.9801 9.96211 63.0871 10.1074 62.1379 10.2457L62.152 17.7105L62.1074 17.727L72.1504 22.0137C72.4902 21.7207 72.823 21.4184 73.1465 21.1137L65.0535 16.5012L63.5582 17.1504L64.8051 9.80273Z"
                            fill="white"/>
                    </g>
                </g>
            </g>
            <defs>
                <clipPath id="clip0_5078_17274">
                    <rect width="80.3906" height="52.2656" fill="white" transform="translate(3.99219 0.554688)"/>
                </clipPath>
            </defs>
        </svg>
    )
}