import React from "react";
import {Box} from "@mantine/core";

function Points({ renderedCellValue }) {

    return (
        <Box className='flex items-center'>
            <div className='flex'>
                <span className={parseInt(renderedCellValue[0]) < parseInt(renderedCellValue[1]) ? 'text-grey-10' : ''}>
                    {renderedCellValue[0]}
                </span>
                <span className='text-grey-10'>/</span>
                <span  className={parseInt(renderedCellValue[0]) < parseInt(renderedCellValue[1]) ? 'text-grey-10' : ''}>
                    {renderedCellValue[1]}
                </span>
            </div>
        </Box>
    )
}

export default Points;
