import React, { useEffect, useRef, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Legend, Title } from 'chart.js';
import Opponent from "../shared/Opponent";
import NZTeam from "../shared/NZTeam";

ChartJS.register(ArcElement, Legend, Title);

function PlayerStats({ player }) {
    const chartRef = useRef(null);
    const [isInView, setIsInView] = useState(false);

    const data = {
        labels: ['Draw Rate', 'Loss Rate', 'Win Rate'],
        datasets: [
            {
                data: [
                    parseFloat(player.drawRate),
                    parseFloat(player.lossRate),
                    parseFloat(player.winRate),
                ],
                backgroundColor: ['#30302D', '#5B5954', '#DAD7CA'],
                borderColor: '#000000',
                borderWidth: 2,
            },
        ],
        winRate: parseFloat(player.winRate)
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
            centerTextPlugin: {
                winRate: parseFloat(player.winRate),
                drawRate: parseFloat(player.drawRate),
                lossRate: parseFloat(player.lossRate),
            },
        },
        cutout: '90%',
        rotation: 30,
        animation: {
            duration: isInView ? 1000 : 0, // Trigger animation when in view
        },
    };


    const centerTextPlugin = {
        id: 'centerTextPlugin',
        afterDraw(chart) {
            const { ctx, chartArea: { width, height } } = chart;
            const winRate = chart.config.options.plugins.centerTextPlugin.winRate;

            ctx.save();
            const windowWidth = window.innerWidth;
            const fontSize = windowWidth < 600 ? '16px' : '24px';
            ctx.font = `${fontSize} "DM Sans", sans-serif`;
            ctx.fillStyle = '#FFFFFF';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';

            const centerX = width / 2;
            const centerY = height / 2;

            const centerAdjustmentTop = windowWidth < 600 ? 15 : 30;
            ctx.fillText(`Win %`, centerX, centerY - centerAdjustmentTop);

            const percentageFontSize = windowWidth < 600 ? '32px' : '56px';
            ctx.font = `bold ${percentageFontSize} "DM Sans", sans-serif`;
            ctx.fillStyle = '#FFFFFF';

            const centerAdjustmentBottom = windowWidth < 600 ? 10 : 20;
            ctx.fillText(`${winRate}%`, centerX, centerY + centerAdjustmentBottom);

            const allMatchesFontSize = windowWidth < 600 ? '10px' : '12px';
            ctx.font = `${allMatchesFontSize} "DM Sans", sans-serif`;

            const allMatchesAdjustmentBottom = windowWidth < 600 ? 30 : 60;
            ctx.fillText(`ALL MATCHES`, centerX, centerY + allMatchesAdjustmentBottom);

            ctx.restore();
        }
    };

    ChartJS.register(centerTextPlugin);

    const debounce = (func, delay) => {
        let timeoutId;
        return function (...args) {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            timeoutId = setTimeout(() => {
                func.apply(null, args);
            }, delay);
        };
    };

    useEffect(() => {
        const handleResize = debounce(() => {
            if (chartRef.current) {
                chartRef.current.update();
            }
        }, 200);

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="max-w-screen-xl grid grid-cols-6 lg:grid-cols-12 gap-4 px-4 h-full mx-auto custom-block">
            <h2 className="col-span-6 lg:col-span-12 text-center mb-6 md:mb-10">Performance</h2>
            {/* left col */}
            <a href={player.bestGame.pageLink} className="left-col col-span-6 lg:col-span-5 text-white flex flex-col h-full">
                <div
                    className="gradient-border flex flex-col w-full h-full py-8 items-center clickable">
                    <div className="flex flex-col items-center">
                        <span className="h4 text-center mb-8 block text-grey-1">{player.bestGame.title}</span>
                        <div className="flex justify-center mb-8">
                            <NZTeam nzTeam={player.team} size='box' className='mr-3' />
                            <Opponent opponent={player.bestGame.opposition} size='box' className='ml-3' />
                        </div>
                        <div className="flex flex-col items-center mb-8">
                            <div className="text-6xl flex items-center w-full justify-between">
                                <span className={ player.bestGame.NZWon ? "font-bold text-white" : "text-grey-10"}>{player.bestGame.fullTimeNZScore}</span> <span
                                className="bg-grey-1 h-px w-[10px] mx-4"></span> <span
                                className={ player.bestGame.NZWon ? "text-grey-10" : "font-bold text-white"}>{player.bestGame.fullTimeOppositionScore}</span>
                            </div>
                            <span className="h4 block text-white mt-8 mb-2">{player.bestGame.date}</span>
                            <span className="block text-grey-10">{player.bestGame.location}</span>
                        </div>
                    </div>
                    <div className="divider h-px w-full mb-8"></div>
                    <div className="flex flex-col w-1/2 items-center">
                    <span className="h6 block text-grey-10 mb-8">
                        {player.playerName.split(" ")[0]}'s performance
                    </span>
                        <ul className="flex flex-col w-full">
                            <li className="flex justify-between mb-4">
                                <span className="h4 text-grey-1">Points</span>
                                <span className={`stats-bold ${parseInt(player.bestGame.points) === 0 ? 'text-grey-10' : ''}`}>{player.bestGame.points}</span>
                            </li>
                            <li className="flex justify-between mb-4">
                                <span className="h4 text-grey-1">Tries</span>
                                <span className={`stats-bold ${parseInt(player.bestGame.tries) === 0 ? 'text-grey-10' : ''}`}>{player.bestGame.tries}</span>
                            </li>
                            <li className="flex justify-between mb-4">
                                <span className="h4 text-grey-1">Conversions</span>
                                <span className={`stats-bold ${parseInt(player.bestGame.conversions) === 0 ? 'text-grey-10' : ''}`}>{player.bestGame.conversions}</span>
                            </li>
                            <li className="flex justify-between mb-4">
                                <span className="h4 text-grey-1">Drop goals</span>
                                <span className={`stats-bold ${parseInt(player.bestGame.dropGoal) === 0 ? 'text-grey-10' : ''}`}>{player.bestGame.dropGoal}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </a>
            {/*right col*/}
            <div className="right-col flex flex-col col-span-6 lg:col-span-7 w-full h-full">
                <div className="gradient-border flex w-full py-12 items-center justify-center mb-6">
                    <div className="hidden sm:flex ml-4 sm:ml-0 items-center">
                        <div className="flex flex-col items-right">
                            <div className="stats-bold mb-1 text-right">
                                {player.winRate} <span className="h4 text-grey-10">%</span>
                            </div>
                            <div
                                className="h6 text-grey-1 font-medium text-right">
                                {((player.winRate / 100) * player.caps).toFixed(0)} matches
                            </div>
                            <div className="h4 mt-1 text-right">Wins</div>
                        </div>
                        <div className="flex-grow flex justify-center mx-4">
                            <div className="bg-white h-px w-[37px]"></div>
                        </div>
                    </div>
                    <div className="relative w-auto">
                        <h4 className="text-center mb-4">
                            Win rate
                        </h4>
                        <div className="relative w-full h-48 sm:h-72 lg:h-80">
                            <Doughnut ref={chartRef} data={data} options={options}/>
                        </div>
                        <div className="flex justify-center sm:hidden text-white mt-8">
                            <ul className="flex">
                                <li className="mr-2">
                                    <div className="stats-bold mb-1">
                                        {player.winRate} <span className="h4 text-grey-10">%</span>
                                    </div>
                                    <div
                                        className="h6 text-grey-1 font-medium">
                                        {((player.winRate / 100) * player.caps).toFixed(0)} matches
                                    </div>
                                    <div className="h4 mt-1">Wins</div>
                                </li>
                                <li className="mr-2">
                                    <div className="stats-bold mb-1">
                                        {player.drawRate} <span className="h4 text-grey-10">%</span>
                                    </div>
                                    <div
                                        className="h6 text-grey-1 font-medium">
                                        {((player.drawRate / 100) * player.caps).toFixed(0)} matches
                                    </div>
                                    <div className="h4 mt-1">Draws</div>
                                </li>
                                <li className="mr-2">
                                    <div className="stats-bold mb-1">
                                        {player.lossRate} <span className="h4 text-grey-10">%</span>
                                    </div>
                                    <div
                                        className="h6 text-grey-1 uppercase font-medium">
                                         {((player.lossRate / 100) * player.caps).toFixed(0)} matches
                                    </div>
                                    <div className="h4 mt-1">Losses</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="hidden sm:flex flex-col justify-center">
                        <div className="flex items-center mb-10">
                            <div className="flex-grow flex justify-center mx-4">
                                <div className="bg-white h-px w-[37px]"></div>
                            </div>
                            <div className="flex flex-col items-left mr-8">
                                <div className="stats-bold mb-1 text-left">
                                    {player.drawRate} <span className="h4 text-grey-10">%</span>
                                </div>
                                <div
                                    className="h6 text-grey-1 font-medium text-left">
                                    {((player.drawRate / 100) * player.caps).toFixed(0)} matches
                                </div>
                                <div className="h4 mt-1 text-left">Draws</div>
                            </div>
                        </div>
                        <div className="flex items-center">
                            <div className="flex-grow flex justify-center mx-4">
                                <div className="bg-white h-px w-[37px]"></div>
                            </div>
                            <div className="flex flex-col items-left mr-8">
                                <div className="stats-bold mb-1 text-left">
                                    {player.lossRate} <span className="h4 text-grey-10">%</span>
                                </div>
                                <div
                                    className="h6 text-grey-1 font-medium text-left">
                                    {((player.lossRate / 100) * player.caps).toFixed(0)} matches
                                </div>
                                <div className="h4 mt-1 text-left">Losses</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="gradient-border flex flex-grow justify-center items-center w-full py-12">
                    <ul className="flex flex-col md:flex-row justify-between text-grey-1 md:px-4">
                        <li className="mr-6 text-center md:text-left mb-4 md:mb-0">
                            <span className="h4 block">Points scored</span>
                            {/* TODO: reinstate once we have the records */}
                            {/*<span className="h4 block">Points&nbsp;*/}
                            {/*    <span className="inline-flex items-center">*/}
                            {/*        scored <IconMedal className="pl-1" />*/}
                            {/*    </span>*/}
                            {/*</span>*/}
                            <span className={`h3 text-[56px] ${parseInt(player.points) === 0 ? 'text-grey-10' : ''}`}>{player.points}</span>
                        </li>
                        <li className="mr-6 mt-1 text-center md:text-left mb-4 md:mb-0">
                            <span className="block">Tries</span>
                            <span className={`h3 ${parseInt(player.tries) === 0 ? 'text-grey-10' : ''}`}>{player.tries}</span>
                        </li>
                        <li className="mr-6 mt-1 text-center md:text-left mb-4 md:mb-0">
                            <span className="block">Conversions</span>
                            <span className={`h3 ${parseInt(player.conversions) === 0 ? 'text-grey-10' : ''}`}>{player.conversions}</span>
                        </li>
                        <li className="mr-6 mt-1 text-center md:text-left mb-4 md:mb-0">
                            <span className="block">Drop goals</span>
                            <span className={`h3 ${parseInt(player.dropGoal) === 0 ? 'text-grey-10' : ''}`}>{player.dropGoal}</span>
                        </li>
                        <li className="mr-6 mt-1 text-center md:text-left">
                            <span className="block">Penalty goals</span>
                            <span className={`h3 ${parseInt(player.penalties) === 0 ? 'text-grey-10' : ''}`}>{player.penalties}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        );
    }

    export default PlayerStats;
