import React from 'react'

export const Portugal = ({ width, height }) => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={ width ? width : "136" } height={ height ? height : "113" } viewBox="0 0 136 113" fill="none">
            <g clipPath="url(#clip0_4060_8795)">
                <path
                    d="M89.357 68.4785C93.5752 62.8022 98.1926 57.3689 101.89 51.3801C107.219 42.7528 110.708 33.3097 112.618 23.2763C113.138 20.5163 113.104 19.0061 109.684 18.6415C104.424 18.1034 99.2168 16.437 93.9571 16.3502C84.9305 16.194 76.1817 18.2076 67.6412 21.2454C57.0351 25.0296 47.21 30.1677 38.2182 36.9376C28.7577 44.0547 20.8595 52.7167 13.9507 62.2293C9.1944 68.7736 5.06302 75.7691 0.202576 83.2855C0.619185 80.6122 0.758055 78.616 1.2441 76.7239C4.68113 62.9237 10.2533 50.0608 19.0368 38.8124C24.9561 31.2266 31.8823 24.5956 39.7805 18.9366C47.5051 13.3992 55.6811 8.67763 64.6555 5.86552C72.7447 3.3485 81.1811 1.40432 89.5827 0.675257C99.616 -0.19268 109.823 -0.0190924 119.752 2.41113C123.45 3.31378 127.234 4.19908 130.619 5.8308C131.764 6.38628 132.615 9.4067 132.233 10.969C130.862 16.5238 129.369 22.1306 127.095 27.3556C120.933 41.4509 111.975 53.6715 100.623 64.0346C95.5714 68.6521 90.1208 72.8876 83.9411 75.4393C84.9826 68.2181 86.0241 61.0836 87.1525 53.4111C87.2045 47.214 87.1525 41.5551 87.1351 35.8961C85.6423 35.2365 84.1841 34.1429 82.6392 34.004C77.5531 33.5527 72.4323 33.4312 67.3114 32.9625C76.6157 29.5775 86.1283 27.8937 96.5262 29.1957C96.0922 42.4577 94.582 55.3553 88.7842 67.8015C88.6106 68.357 88.6106 68.6 88.5932 68.8604C88.8363 68.7215 89.0793 68.5653 89.3223 68.4264L89.357 68.4785Z"
                    fill="white"/>
                <path
                    d="M129.768 41.2773C131.608 37.8924 133.344 34.7331 135.063 31.5564L136 31.7821C135.965 32.5632 136.017 33.3617 135.861 34.1255C132.407 50.2518 125.585 64.6769 114.822 77.314C103.99 90.0206 90.6589 98.9777 75.3659 105.383C60.6457 111.545 45.2311 113.212 29.5909 112.066C21.9878 111.511 14.5235 108.959 7.00721 107.31C4.21245 106.702 4.03886 104.654 4.50755 102.432C7.19815 89.361 13.0307 77.6439 20.6512 66.8467C24.9041 60.8059 29.6256 55.0428 34.6597 49.6442C40.0409 43.8811 46.2206 38.9165 52.869 35.6184C52.869 43.4819 52.8169 51.3801 52.9037 59.2957C52.9384 62.1078 53.3897 64.9026 53.5286 67.7147C53.8758 74.6755 58.6494 77.8175 64.3084 80.0741C66.322 80.8726 68.5266 81.1677 70.6617 82.2786C67.3114 82.2786 63.8918 82.7473 60.6457 82.1919C51.4282 80.5775 47.1753 74.2589 46.0123 65.4407C45.3006 60.0247 44.6062 54.6088 43.8077 48.4118C33.5661 62.0384 27.8724 76.8627 23.7931 93.232C28.0981 94.1347 32.1774 95.3845 36.3261 95.8011C56.6011 97.8147 74.4112 90.6976 91.3706 80.5428C102.029 74.1548 111.663 66.5169 119.37 56.7266C123.154 51.9182 126.262 46.5717 129.786 41.26L129.768 41.2773Z"
                    fill="white"/>
                <path
                    d="M79.5667 44.5928C79.8791 46.2246 80.0354 47.6827 80.0874 49.1235C80.1569 51.2412 78.8029 52.0745 76.9629 52.2307C74.9145 52.3869 73.4911 51.4322 73.2481 49.3491C73.0572 47.7348 73.2134 46.0683 73.2134 43.9853C75.5221 44.1415 77.4489 44.2804 79.584 44.6102L79.5667 44.5928Z"
                    fill="white"/>
                <path
                    d="M72.3628 53.8277C72.4496 55.2511 72.4322 56.3968 72.5017 57.5424C72.6058 59.2957 72.1719 60.6844 70.2277 61.0315C68.2488 61.3787 66.5823 60.8753 66.0963 58.7402C65.6971 56.9869 65.6797 55.1643 65.4193 52.6993C68.1099 53.0292 70.193 53.2895 72.3454 53.8277H72.3628Z"
                    fill="white"/>
                <path
                    d="M64.9159 49.5574C64.6555 52.0224 63.0238 52.7341 61.3574 52.0918C60.2291 51.6578 59.1702 50.1997 58.7883 48.9672C58.3543 47.5785 58.6841 45.9468 58.6841 44.4887C64.9854 43.2909 65.0548 43.343 64.9159 49.5574Z"
                    fill="white"/>
                <path
                    d="M80.0354 67.6799C79.1501 70.3185 77.1886 70.4052 75.3833 69.6762C72.1198 68.3396 73.3176 65.3886 73.2828 62.6285H80.0528C80.0528 64.1908 80.0528 65.8052 80.0354 67.6799Z"
                    fill="white"/>
                <path
                    d="M62.7981 69.9019C58.4932 69.6415 57.9724 68.5826 59.066 62.5765H64.6382C65.0374 65.2671 66.4087 68.0271 62.7808 69.9019H62.7981Z"
                    fill="white"/>
                <path
                    d="M87.1351 36.2259C87.2045 41.5898 87.2393 47.2487 87.2219 53.1507C87.1525 47.7695 87.1177 42.1452 87.1351 36.2259Z"
                    fill="white"/>
                <path
                    d="M89.2529 68.3743C89.114 68.6346 88.8883 68.7909 88.6453 68.9298C88.6453 68.6694 88.6627 68.4263 88.8016 68.0965C89.0099 68.0965 89.0793 68.1833 89.2529 68.3743Z"
                    fill="black"/>
            </g>
            <defs>
                <clipPath id={`clip0_4060_8795_${width}`}>
                    <rect width="135.78" height="112.172" fill="white" transform="translate(0.219971 0.223938)"/>
                </clipPath>
            </defs>
        </svg>
    )
}