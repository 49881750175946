import React from "react";
import {Box} from "@mantine/core";
import {BorderGradient} from "../../SVGs/BorderGradient";

function ComparisonStat({ renderedCellValue }) {

    return (
        <Box className='flex items-center'>
            <div className='flex'>
                <span className={(parseInt(renderedCellValue[0]) < parseInt(renderedCellValue[1]) || parseInt(renderedCellValue[0]) === 0) ? 'text-grey-10' : ''}>
                    {renderedCellValue[0]}
                </span>
                <span className='text-grey-10'>-</span>
                <span className={(parseInt(renderedCellValue[1]) < parseInt(renderedCellValue[0]) || parseInt(renderedCellValue[1]) === 0) ? 'text-grey-10' : ''}>
                    {renderedCellValue[1]}
                </span>
            </div>
        </Box>
    )
}

export default ComparisonStat;
