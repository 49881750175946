import React from "react";
import Opponent from "../shared/Opponent";
import NZTeam from "../shared/NZTeam";

function RivalryTopScorers({data, opposition}) {
  // Placeholder player data
  const placeholder = {
    pageLink: null,
    profilePhoto: null,
    playerName: null,
    position: "N/A",
    points: 0,
    tries: 0,
    conversions: 0,
    dropGoals: 0,
    redCard: 0,
    yellowCard: 0,
  };

  // Get top 5 players for both teams
  const topNZPlayers = data.nzTeam;
  const topOppPlayers = data.oppTeam;

  const renderList = (playersA, playersB) => (
    <ul className="w-full match-stats-list match-stats-list--lineup text-center md:mb-8">
      <li className="!pt-0 md:px-12 md:mb-6">
        <h6 className="text-grey-1">In test matches between {data.against} and the {data.team}</h6>
      </li>
      {playersA.map((playerA, index) => {
        const playerB = playersB[index] || placeholder;

        return (
          <li
            key={index}
            className="flex justify-between items-center px-0 pt-6 md:!pt-0 md:px-12 md:mb-6"
          >
            {/* Team A Player */}
            <a
              className={'flex flex-grow items-stretch h-full !rounded-xl flex-1 cursor-pointer gradient-border--nested clickable strict'}
              href={playerA.pageLink ? playerA.pageLink : null}
            >
              <div className="md:min-w-[77px] md:h-[128px]">
                { playerA.profilePhoto ? (
                    <img
                        src={playerA.profilePhoto}
                        className="object-cover rounded-l-xl hidden md:block h-full"
                        alt={playerA.playerName}
                    />
                ) : (
                    <NZTeam nzTeam={data.team} size={'playerImage'} />
                )}
              </div>
              <div className="w-full !md:rounded-l-none !rounded-xl flex text-left relative !bg-black-50">
                <div
                    className="h-[138px] md:h-auto flex flex-col flex-grow justify-center p-2 sm:p-4 md:py-0 md:pl-2 md:pr-6 relative bg-black-50 rounded-xl md:!rounded-l-none md:!rounded-r-xl">
                  <span className="small-card-name block">
                    {playerA.playerName.length > 34
                        ? `${playerA.playerName.slice(0, 34)}...`
                        : playerA.playerName}
                  </span>
                  <p className="text-grey-0 m-0 font-normal text-base">{playerA.position}</p>
                  <p className="text-grey-0 m-0 font-normal text-base"><span
                      className="text-white-100">{playerA.matches} </span>played</p>
                  <ul className="lineup-points-list text-base font-normal">
                    <li>
                      <span className="text-white-100 mr-1">{playerA.points}</span>
                      <span className="text-grey-1 text-xs">PT</span>
                    </li>
                    <li>
                      <span className="text-white-100 mr-1">{playerA.tries}</span>
                      <span className="text-grey-1 text-xs">T</span>
                    </li>
                    <li>
                      <span className="text-white-100 mr-1">{playerA.conversions}</span>
                      <span className="text-grey-1 text-xs">C</span>
                    </li>
                    <li>
                      <span className="text-grey-10 mr-1">{playerA.dropGoals}</span>
                      <span className="text-grey-10 text-xs">DG</span>
                    </li>
                  </ul>
                </div>
              </div>
            </a>

            {/* Separator */}
            <span className="h4 text-grey-1 font-normal mx-4">{index + 1}</span>

            {/* Team B Player */}
            {playerB.playerName ? (
              <div
                className={'flex flex-grow items-stretch h-full !rounded-xl flex-1 gradient-border--nested'}
              >
                <div className="h-full">
                  <Opponent opponent={opposition} size={'playerImage'} />
                </div>
                <div className="w-full !rounded-xl md:!rounded-l-none md:!rounded-r-xl flex text-left relative !bg-black-50">
                  <div
                      className="h-[138px] md:h-auto flex flex-col flex-grow justify-center p-2 sm:p-4 md:py-0 md:pl-2 md:pr-6 relative bg-black-50 rounded-xl md:!rounded-l-none md:!rounded-r-xl">
                    <span className="small-card-name block">
                      {playerB.playerName.length > 34
                          ? `${playerB.playerName.slice(0, 34)}...`
                          : playerB.playerName}
                    </span>
                    <p className="text-grey-0 m-0 font-normal text-base">{playerB.position}</p>
                    <p className="text-grey-0 m-0 font-normal text-base"><span className="text-white-100">{playerB.matches} </span>played</p>
                    <ul className="lineup-points-list text-base font-normal">
                      <li>
                        <span className="text-white-100 mr-1">{playerB.points}</span>
                        <span className="text-grey-1 text-xs">PT</span>
                      </li>
                      <li>
                        <span className="text-white-100 mr-1">{playerB.tries}</span>
                        <span className="text-grey-1 text-xs">T</span>
                      </li>
                      <li>
                        <span className="text-white-100 mr-1">{playerB.conversions}</span>
                        <span className="text-grey-1 text-xs">C</span>
                      </li>
                      <li>
                        <span className="text-grey-10 mr-1">{playerB.dropGoals}</span>
                        <span className="text-grey-10 text-xs">DG</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            ) : (
                <div
                    className={"gradient-border--nested flex flex-grow items-stretch h-full !rounded-xl flex-1"}
                >
                  <div className="h-full">
                    <Opponent opponent={opposition} size={'playerImage'}/>
                  </div>
                  <div
                      className="w-full !rounded-l-none !rounded-r-xl flex text-left relative !bg-black-50">
                    <div
                        className="h-[138px] md:h-auto flex flex-col flex-grow justify-center p-2 sm:p-4 md:py-0 md:pl-2 md:pr-6 relative bg-black-50 rounded-xl">
                      <span className='text-grey-10'>Not available</span>
                    </div>
                  </div>
                </div>
            )}
          </li>
        );
      })}
    </ul>
  );

  return (
    <div className="max-w-screen-xl grid grid-cols-6 lg:grid-cols-12 gap-4 mx-auto px-4 custom-block">
      <h2 className="col-span-6 lg:col-span-12 text-center mb-6 md:mb-14">Top Scorers</h2>
      <div className="lineup-list-container gradient-border--nested col-span-6 lg:col-span-8 lg:col-start-3 flex flex-col items-center relative">
        <div className="w-full md:!pt-8">{renderList(topNZPlayers, topOppPlayers)}</div>
      </div>
    </div>
  );
}

export default RivalryTopScorers;