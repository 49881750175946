import React from 'react'

export const USA = ({ width, height }) => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={ width ? width : "181" } height={ height ? height : "145" } viewBox="0 0 181 145" fill="none">
            <g clipPath="url(#clip0_4060_13936)">
                <mask id={`mask0_4060_13936_${width}`} style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="0" y="0"
                      width="182" height="179">
                    <path d="M181.188 0.77037H0V178.727H181.188V0.77037Z" fill="white"/>
                </mask>
                <g mask="url(#mask0_4060_13936)">
                    <path
                        d="M62.8684 137.285C65.0672 137.526 107.904 131.452 138.62 96.3384C169.658 62.7528 174.847 25.4668 174.847 25.4668C174.847 25.4668 144.64 7.13889 94.2552 6.64281C43.8567 6.14674 16.5861 24.0188 16.5861 24.0188C16.5861 24.0188 6.1685 56.934 13.0063 81.8986C24.5232 123.984 60.6696 137.043 62.8684 137.285Z"
                        stroke="white" strokeWidth="11.7181"/>
                    <path
                        d="M62.8684 137.285C65.0672 137.526 107.904 131.452 138.62 96.3384C169.658 62.7528 174.847 25.4668 174.847 25.4668C174.847 25.4668 144.64 7.13889 94.2552 6.64281C43.8567 6.14674 16.5861 24.0188 16.5861 24.0188C16.5861 24.0188 6.1685 56.934 13.0063 81.8986C24.5232 123.984 60.6696 137.043 62.8684 137.285Z"
                        stroke="#162C53" strokeWidth="9.37178"/>
                </g>
                <path
                    d="M62.8675 137.285C65.0663 137.526 107.903 131.452 138.619 96.3384C169.657 62.7528 174.846 25.4668 174.846 25.4668C174.846 25.4668 144.639 7.13889 94.2542 6.64281C43.8558 6.14674 16.5851 24.0188 16.5851 24.0188C16.5851 24.0188 6.16752 56.934 13.0053 81.8986C24.5223 123.984 60.6686 137.043 62.8675 137.285Z"
                    fill="white"/>
                <path
                    d="M58.7113 82.8237C100.556 103.927 146.463 80.0216 146.463 80.0216C146.463 80.0216 141.73 86.8057 136.93 91.324C132.868 95.1451 129.435 97.5987 124.421 100.119C113.065 105.817 90.7952 111.167 68.7668 104.638C16.3171 89.0716 24.1872 36.9302 31.3468 20.9083C31.3468 20.9083 23.061 64.8444 58.6979 82.8237H58.7113Z"
                    fill="#C42033"/>
                <path
                    d="M37.7815 94.0859C71.0051 128.248 120.452 107.896 120.452 107.896C120.452 107.896 115.732 113.674 94.9507 123.462C77.2798 131.788 67.1169 131.814 65.1192 131.399C63.1215 130.997 39.7658 120.901 25.8892 101.943C3.8742 71.8967 21.1429 29.6097 21.1429 29.6097C21.1429 29.6097 12.8303 68.4242 37.7949 94.0859H37.7815Z"
                    fill="#C42033"/>
                <path
                    d="M152.402 21.1496C145.698 18.5486 142.775 16.7386 130.749 14.0839C103.357 8.05059 79.6129 9.60585 79.6129 9.60585C79.6129 9.60585 98.4235 9.60585 110.852 13.4806C129.381 19.2324 136.018 27.8936 136.018 27.8936L135.87 33.3236C135.87 33.3236 138.619 35.0263 140.737 39.3033C144.089 46.074 138.968 55.0302 138.968 55.0302C138.968 55.0302 118.736 33.2431 87.0004 49.5063L93.2349 38.3513L71.5685 34.7582L91.1969 27.4645L70.9786 21.8736L93.8114 15.9341C93.8114 15.9341 81.0341 12.5823 62.8939 15.4113C32.3384 20.1977 36.2132 40.3222 36.0523 40.3356C36.8165 45.9533 38.6399 51.8124 41.7772 57.3228C38.774 50.351 38.8008 43.245 42.394 39.5714C47.9446 37.6005 62.2504 44.3579 72.1718 52.9922C84.8552 64.0399 85.8608 74.3904 85.9144 79.4853C77.9772 80.893 63.1486 76.3211 54.6081 71.6687C57.8393 74.0284 61.5398 76.12 65.7631 77.8496C106.991 94.6624 150.136 72.1246 150.136 72.1246C150.136 72.1246 150.35 71.9235 150.712 71.5481C146.02 72.2319 134.168 72.7547 129.77 70.985C129.77 70.985 150.042 65.9036 159.695 55.7541C152.616 57.9798 142.708 56.6793 142.708 56.6793C142.708 56.6793 166.587 49.6538 167.753 36.3268C168.45 28.4299 157.041 22.9596 152.375 21.163M91.4383 75.6239C91.2103 77.4876 89.6953 78.721 88.5959 78.7881C87.5233 70.6766 87.3088 63.3159 75.3092 52.228C66.0178 43.6473 55.3321 38.7804 47.4888 37.815C55.8014 35.0397 72.4534 42.8964 79.5057 48.9566C92.8863 60.4601 92.35 72.4866 91.4383 75.6239Z"
                    fill="#162C53"/>
                <path d="M108.546 26.0031L130.896 33.7392L117.22 35.214L119.406 31.7549L108.546 26.0031Z"
                      fill="#162C53"/>
            </g>
            <defs>
                <clipPath id={`clip0_4060_13936_${width}`}>
                    <rect width="181" height="143.459" fill="white" transform="translate(0 0.77037)"/>
                </clipPath>
            </defs>
        </svg>
    )
}