// @TODO: we still need to create tag element for player cards - Simon

import React from 'react';
import imgUrlAb from '/assets/images/all_blacks_logo_player_card.png';
import imgUrlBf from '/assets/images/black_ferns_logo_player_card.png';

const PlayerCard = ({ item, isActive, handleMouseMove, handleMouseLeave, shinePosition }) => {
  const shineStyle = isActive && shinePosition ? {
    background: `
      radial-gradient(circle at ${shinePosition.x}% ${shinePosition.y}%, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0)),
      radial-gradient(circle at 0% 0%, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.6) 30%, rgba(0, 0, 0, 0) 70%) 0% 0%,
      radial-gradient(circle at 100% 0%, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.6) 30%, rgba(0, 0, 0, 0) 70%) 100% 0%
    `,
    mixBlendMode: 'overlay',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    pointerEvents: 'none',
  } : {};

  const handleCardClick = (event) => {
    if (!isActive) {
      event.preventDefault();
    }
  };

  return (
    <a
      id={item.id}
      className={`flex flex-col h-full w-full text-grey-1 p-8`}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      href={item.pageLink}
      onClick={handleCardClick}
    >
      <div className="flex justify-between">
        <span className="font-display text-xl text-white-100 text-shadow-custom">#{item.playerNumber}</span>
        <div className="team-logo drop-shadow-team-logo">
          {item.team === "Black Ferns" ?
            <img
                className=""
                src={imgUrlBf} alt="Black Ferns" width={52} height={32}/>
            :
            <img
                className=""
                src={imgUrlAb} alt="All Blacks" width={52} height={32}/>
          }
        </div>
      </div>
      <div className="flex flex-col mt-auto">
        <div className="flex flex-col mb-12">
          <span className="small-stat-title uppercase text-grey-1 block mr-2 text-shadow-custom">{item.career}</span>
          <span className="card__display-name text-white no-underline block mr-1 text-shadow-custom">{item.playerName}</span>
          <span className="h4 text-shadow-custom text-grey-1">{item.position}</span>
        </div>
        <ul className="flex justify-between">
          <li>
            <span className="small-stat-title text-grey-10">Matches</span><br/>
            <span className="text-white-100">{item.caps}</span>
          </li>
          <li>
            <span className="small-stat-title text-grey-10">Points</span><br/>
            <span className="text-white-100">{item.points}</span>
          </li>
          <li>
            <span className="small-stat-title text-grey-10">Tries</span><br/>
            <span className="text-white-100">{item.tries}</span>
          </li>
          <li>
            <span className="small-stat-title text-grey-10">Born</span><br/>
            <span className="text-white-100">{item.birthYear}</span>
          </li>
        </ul>
      </div>
      {/* Shine effect */}
      {isActive && <div style={shineStyle}></div>}
    </a>
  );
};

export default PlayerCard;
