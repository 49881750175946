import React, { useState } from 'react'

import { ALL_BLACKS_TEAM, BLACK_FERNS_TEAM } from '../../constants';
import { summaryColumns } from "./allRivals/AllRivalColumns";
import BaseTable from "./BaseTable";

function RivalTable({ allRivals, withFilters }) {
    const [selectedTeams, setSelectedTeams] = useState([ALL_BLACKS_TEAM, BLACK_FERNS_TEAM])

    let filteredRivals = [...allRivals];

    if (selectedTeams.length > 0) {
        filteredRivals = filteredRivals
            .filter(rival => selectedTeams.includes(rival.team))
    }

    return (
        <BaseTable
            title={ 'All Rivalries' }
            data={ filteredRivals }
            columns={ summaryColumns }
            pinnedColumn={ 'team' }
            selectedTeams={ withFilters ? selectedTeams : null }
            setSelectedTeams={ withFilters ? setSelectedTeams : null }
        />
    );
}

export default RivalTable;