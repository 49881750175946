import React from 'react';
import Opponent, {countryBackgroundColors} from '../shared/Opponent';

const TeamCard = ({ item, isActive, shinePosition, handleMouseMove, handleMouseLeave }) => {
  const backgroundColor = countryBackgroundColors[item.country] || '#333';
  const textColorClass = item.country === 'Great Britain' || item.country === 'Pacific Islanders' ? 'text-black-100' : 'text-white';
  const subTextColorClass = item.country === 'Great Britain' || item.country === 'Pacific Islanders' ? 'text-black-100' : 'text-grey-1';

  const shineStyle = isActive && shinePosition ? {
    background: `radial-gradient(circle at ${shinePosition.x}% ${shinePosition.y}%, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0))`,
    mixBlendMode: 'overlay',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    pointerEvents: 'none',
  } : {};

  const tiledBackgroundUrl = '/assets/images/card-pattern-tile.svg';

  // Gradient and background styles
  const combinedBackground = `
    linear-gradient(to bottom, rgba(0, 0, 0, 0) 35%, rgba(0, 0, 0, 0.8) 95%),
    url(${tiledBackgroundUrl}),
    ${backgroundColor}
  `;

  const handleCardClick = (event) => {
    if (!isActive) {
      event.preventDefault();
    }
  };

  return (
    <a
      id={item.id}
      className={`flex flex-col h-full w-full text-grey-1 p-8`}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      style={{
        background: combinedBackground,
        backgroundSize: 'cover, repeat, auto',
        backgroundBlendMode: 'overlay, normal',
      }}
      href={item.pageLink}
      onClick={handleCardClick}
    >
      <div className="flex flex-col h-full">
        <span className={`h6 font-medium mb-8 ${textColorClass}`}>{item.gender}</span>
        <Opponent opponent={item.country} teamName={item.teamName} size='cardLogo' />
        <div className="flex flex-col mt-auto">
          <span className={`h6 font-medium text-center mb-8 ${subTextColorClass}`}>
            Results against {item.nzTeam}
          </span>
          <ul className="flex justify-between space-x-4">
            <li className="flex-1 flex flex-col items-start justify-between">
                <span className={`h6 ${subTextColorClass}`}>Tests</span>
                <span className={`h5 ${subTextColorClass}`}>{item.tests}</span>
            </li>
            <li className="flex-1 flex flex-col items-start justify-between">
              <span className={`h6 ${subTextColorClass}`}>Wins</span>
              <span className={`h5 ${subTextColorClass}`}>{item.wins}</span>
            </li>
            <li className="flex-1 flex flex-col items-start justify-between">
              <span className={`h6 ${subTextColorClass}`}>Losses</span>
              <span className={`h5 ${subTextColorClass}`}>{item.losses}</span>
            </li>
            <li className="flex-1 flex flex-col items-start justify-between">
              <span className={`h6 ${subTextColorClass}`}>Success</span>
              <span className={`h5 ${subTextColorClass}`}>{item.successRate}%</span>
            </li>
          </ul>
        </div>
      </div>
      {/* Shine effect */}
      {isActive && <div style={shineStyle}></div>}
    </a>
  );
};

export default TeamCard;
