import React from 'react'
import { IconRedCard } from "../SVGs/IconRedCard";
import { IconYellowCard } from "../SVGs/IconYellowCard";
import NZTeam from "../shared/NZTeam";
import Opponent from "../shared/Opponent";
function RivalryPageMainBlock({data}) {

  return (
      <>
          <div className="max-w-screen-xl grid grid-cols-6 lg:grid-cols-12 gap-4 mx-auto px-4 custom-block">
              <div
                  className="gradient-border col-span-6 lg:col-span-8 lg:col-start-3 flex flex-col items-center relative">
                  <div className="flex items-center lg:items-baseline mt-10 mb-10 lg:-mt-8 lg:mb-16">
                      <div className="flex flex-col items-center mr-8 sm:mr-16">
                          <NZTeam nzTeam={data.team} size='box' className="w-[48px] h-[48px] sm:w-[96px] sm:h-[96px] lg:w-[120px] lg:h-[120px] mb-2"/>
                      </div>
                      <div className="flex flex-col items-center">
                          <div className="flex items-center">
                              <div className="flex items-baseline">
                                  <span className={`text-4xl lg:text-6xl ${(parseInt(data.winRate) > parseInt(data.oppWinRate) && parseInt(data.winRate) !== 0) ? 'font-bold text-white' : 'text-grey-10'}`}>{data.winRate}</span>
                                  <span className="h4 text-grey-10">%</span>
                              </div>
                              <div className="h6 text-white-100 mx-6">Win rate</div>
                              <div className="flex items-baseline">
                                  <span className={`text-4xl lg:text-6xl ${(parseInt(data.oppWinRate) > parseInt(data.winRate) && parseInt(data.oppWinRate) !== 0) ? 'font-bold text-white' : 'text-grey-10'}`}>{data.oppWinRate}</span>
                                  <span className="h4 text-grey-10">%</span>
                              </div>
                          </div>
                      </div>
                      <div className="flex flex-col items-center ml-8 sm:ml-16 self-center">
                          <Opponent opponent={data.country} size='box' className="w-[48px] h-[48px] sm:w-[96px] sm:h-[96px] lg:w-[120px] lg:h-[120px] mb-2 stroke" />
                      </div>
                  </div>
                  <div className="mb-12 lg:mb-20 text-center">
                      <h4 className="text-grey-1 mb-3">Tests</h4>
                      <span className="stats-bold">{data.matches}</span>
                  </div>
                  <ul className="w-full match-stats-list text-center mb-16 md:mb-32">
                      <li>
                          <div className="flex justify-between px-16">
                              <span className={data.wins === 0 ? 'text-grey-10' : ''}>{data.wins}</span>
                              <span className="text-grey-1 font-normal">Wins</span>
                              <span className={data.oppWins === 0 ? 'text-grey-10' : ''}>{data.oppWins}</span>
                          </div>
                      </li>
                      <li>
                          <div className="flex justify-between px-16">
                              <span className={data.draw === 0 ? 'text-grey-10' : ''}>{data.draw}</span>
                              <span className="text-grey-1 font-normal">Draws</span>
                              <span className={data.draw === 0 ? 'text-grey-10' : ''}>{data.draw}</span>
                          </div>
                      </li>
                  </ul>
                  <ul className="w-full match-stats-list text-center mb-16 md:mb-32">
                      <li>
                          <div className="flex justify-between px-16">
                              <span className={data.nzPoints === 0 ? 'text-grey-10' : ''}>{parseInt(data.nzPoints).toLocaleString()}</span>
                              <span className="text-grey-1 font-normal">Points scored</span>
                              <span className={data.oppPoints === 0 ? 'text-grey-10' : ''}>{parseInt(data.oppPoints).toLocaleString()}</span>
                          </div>
                      </li>
                      <li>
                          <div className="flex justify-between px-16">
                              <span className={data.nzTries === 0 ? 'text-grey-10' : ''}>{parseInt(data.nzTries).toLocaleString()}</span>
                              <span className="text-grey-1 font-normal">Tries</span>
                              <span className={data.oppTries === 0 ? 'text-grey-10' : ''}>{parseInt(data.oppTries).toLocaleString()}</span>
                          </div>
                      </li>
                      <li>
                          <div className="flex justify-between px-16">
                              <span className={data.nzConversions === 0 ? 'text-grey-10' : ''}>{data.nzConversions}</span>
                              <span className="text-grey-1 font-normal">Conversions</span>
                              <span className={data.oppConversions === 0 ? 'text-grey-10' : ''}>{data.oppConversions}</span>
                          </div>
                      </li>
                      <li>
                          <div className="flex justify-between px-16">
                              <span className={data.nzDropGoals === 0 ? 'text-grey-10' : ''}>{data.nzDropGoals}</span>
                              <span className="text-grey-1 font-normal">Drop goals</span>
                              <span className={data.oppDropGoals === 0 ? 'text-grey-10' : ''}>{data.oppDropGoals}</span>
                          </div>
                      </li>
                  </ul>
                  <ul className="w-full match-stats-list text-center mb-16 md:mb-32">
                      <li>
                          <div className="flex justify-between px-16">
                              <span className={data.nzPenaltyGoals === 0 ? 'text-grey-10' : ''}>{data.nzPenaltyGoals}</span>
                              <span className="text-grey-1 font-normal">Penalty goals</span>
                              <span className={data.oppPenaltyGoals === 0 ? 'text-grey-10' : ''}>{data.oppPenaltyGoals}</span>
                          </div>
                      </li>
                      <li>
                          <div className="flex justify-between px-16">
                              <span className={data.nzRedCards === 0 ? 'text-grey-10' : ''}>{data.nzRedCards}</span>
                              <div className="flex items-center">
                                  <IconRedCard width={24} height={24}/>
                                  <span className="text-grey-1 font-normal">Red cards</span>
                              </div>
                              <span className={data.oppRedCards === 0 ? 'text-grey-10' : ''}>{data.oppRedCards}</span>
                          </div>
                      </li>
                      <li>
                          <div className="flex justify-between px-16">
                              <span className={data.nzYellowCards === 0 ? 'text-grey-10' : ''}>{data.nzYellowCards}</span>
                              <div className="flex items-center">
                                  <IconYellowCard width={24} height={24}/>
                                  <span className="text-grey-1 font-normal">Yellow cards</span>
                              </div>
                              <span className={data.oppYellowCards === 0 ? 'text-grey-10' : ''}>{data.oppYellowCards}</span>
                          </div>
                      </li>
                  </ul>
              </div>
          </div>
      </>
  )
}

export default RivalryPageMainBlock
