import React from "react";
import {Box} from "@mantine/core";

function Points({ renderedCellValue }) {

    return (
        <Box className='flex items-center'>
            <div className='flex items-center'>
                <span>{renderedCellValue[0]}</span>
                <span className='h6 pl-1'>W</span>
                <span className='pl-3'>{renderedCellValue[1]}</span>
                <span className='h6 text-grey-10 pl-1'>D</span>
                <span className='pl-3'>{renderedCellValue[2]}</span>
                <span className='h6 text-grey-10 pl-1'>L</span>
            </div>
        </Box>
    )
}

export default Points;
