import React from 'react'

export const IconFilter = () => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
            <path
                d="M21.6158 5.14346C21.5004 4.87675 21.3091 4.64986 21.0657 4.49101C20.8223 4.33216 20.5377 4.24836 20.247 4.25002H3.74705C3.45673 4.2506 3.17282 4.3354 2.92976 4.49416C2.68669 4.65291 2.49492 4.87879 2.3777 5.14438C2.26049 5.40998 2.22286 5.70389 2.2694 5.99045C2.31593 6.27701 2.44462 6.54391 2.63986 6.75877L2.64736 6.76721L8.99705 13.5472V20.75C8.99698 21.0215 9.0706 21.2879 9.21004 21.5208C9.34948 21.7538 9.54953 21.9445 9.78885 22.0727C10.0282 22.2008 10.2978 22.2617 10.5689 22.2486C10.8401 22.2356 11.1027 22.1493 11.3286 21.9988L14.3286 19.9981C14.5343 19.8612 14.7029 19.6755 14.8195 19.4576C14.9361 19.2398 14.9971 18.9965 14.997 18.7494V13.5472L21.3477 6.76721L21.3552 6.75877C21.5525 6.54489 21.6824 6.27764 21.7288 5.99037C21.7751 5.7031 21.7358 5.40854 21.6158 5.14346ZM13.7014 12.7419C13.5717 12.8795 13.4987 13.0609 13.497 13.25V18.7494L10.497 20.75V13.25C10.4971 13.0596 10.4247 12.8762 10.2945 12.7372L3.74705 5.75002H20.247L13.7014 12.7419Z"
                fill="white"/>
        </svg>
    )
}