import React from 'react'

export const IconChevron = ({classname, colour, hover}) => {

    return (
        <span className={classname}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none" className="!ml-0">
                <path
                    d="M20.0307 14.9694L12.5307 7.46937C12.4611 7.39964 12.3784 7.34432 12.2873 7.30658C12.1963 7.26884 12.0987 7.24941 12.0001 7.24941C11.9015 7.24941 11.8039 7.26884 11.7129 7.30658C11.6218 7.34432 11.5391 7.39964 11.4695 7.46937L3.96948 14.9694C3.86447 15.0743 3.79294 15.208 3.76396 15.3535C3.73497 15.4991 3.74982 15.65 3.80664 15.7871C3.86345 15.9242 3.95967 16.0414 4.08312 16.1238C4.20656 16.2062 4.35168 16.2501 4.5001 16.25H19.5001C19.6485 16.2501 19.7936 16.2062 19.9171 16.1238C20.0405 16.0414 20.1368 15.9242 20.1936 15.7871C20.2504 15.65 20.2652 15.4991 20.2362 15.3535C20.2073 15.208 20.1357 15.0743 20.0307 14.9694Z"
                    fill={colour}/>
            </svg>
        </span>
    )
}