import React from "react";
import {IconRedCard} from "../SVGs/IconRedCard";
import {IconYellowCard} from "../SVGs/IconYellowCard";
import Opponent from "../shared/Opponent";
import NZTeam from "../shared/NZTeam";

function MatchLineUp({lineUp, nzTeam, opposition}) {
    // filter players by reserve status
    const filterByReserve = (reserve) => ({
        nzTeam: lineUp.nzTeam.filter((player) => player.reserve === reserve),
        oppTeam: lineUp.oppTeam.filter((player) => player.reserve === reserve),
    });

    // Separate players based on reserve status
    const mainPlayers = filterByReserve(0);
    const reservePlayers = filterByReserve(1);

    // Combine and sort unique player numbers
    const getPlayerNumbers = (nzTeam, oppTeam) =>
        Array.from(
            new Set([...nzTeam.map((player) => player.number), ...oppTeam.map((player) => player.number)])
        ).sort((a, b) => a - b);

    const mainNumbers = getPlayerNumbers(mainPlayers.nzTeam, mainPlayers.oppTeam);
    const reserveNumbers = getPlayerNumbers(reservePlayers.nzTeam, reservePlayers.oppTeam);

    // Placeholder player data
    const placeholder = {
        pageLink: null,
        profilePhoto: null,
        playerName: null,
        position: "N/A",
        points: 0,
        tries: 0,
        conversions: 0,
        dropGoals: 0,
        captain: 0,
        redCard: 0,
        yellowCard: 0,
        number: null,
    };

    const renderList = (numbers, playersA, playersB) => (
        <ul className="w-full match-stats-list match-stats-list--lineup text-center md:mb-8">
            {numbers.map((number) => {
                const playerA = playersA.find((player) => player.number === number) || placeholder;
                const playerB = playersB.find((player) => player.number === number) || placeholder;

                return (
                    <li key={number} className="flex justify-between items-center px-0 pt-6 md:!pt-0 md:px-12 md:mb-6">
                        {/* Team A Player */}
                        <a
                            className={`${
                                playerA.captain === 1 ? "border-2 !rounded-xl" : "gradient-border--nested"
                            } flex flex-grow items-stretch h-full md:!rounded-xl flex-1 cursor-pointer clickable strict`}
                            href={playerA.pageLink ? playerA.pageLink : null}
                        >
                            <div className="hidden rounded-l-xl md:block md:min-w-[77px] md:max-w-[77px] md:h-[128px]">
                                {playerA.profilePhoto ? (
                                    <img
                                        src={playerA.profilePhoto}
                                        className={`${
                                            playerA.captain === 1 ? "rounded-l-l" : "rounded-l-xl"
                                        } object-cover h-full`}
                                        alt={playerA.playerName}
                                    />
                                ) : (
                                    <NZTeam nzTeam={nzTeam} size={'playerImage'}/>
                                )}
                            </div>
                            <div className="w-full rounded-xl md:!rounded-l-none md:!rounded-r-xl flex text-left relative !bg-black-50 !hover:bg-none">
                                <div
                                    className="h-[138px] md:h-auto flex flex-col flex-grow justify-center p-2 md:py-0 md:pl-2 md:pr-6 relative bg-black-50 rounded-xl">
                                    {playerA.captain === 1 && <h6 className="text-grey-0">Captain</h6>}
                                    <span className="small-card-name block truncate text-wrap">
                                        {playerA.playerName.length > 34
                                            ? `${playerA.playerName.slice(0, 34)}...`
                                            : playerA.playerName}
                                    </span>
                                    <p className="text-grey-0 m-0 font-normal text-base">{playerA.position}</p>
                                    <ul className="lineup-points-list text-base font-normal">
                                        <li>
                                            <span className="text-white-100 mr-1">{playerA.points}</span>
                                            <span className="text-grey-1 text-xs">PT</span>
                                        </li>
                                        <li>
                                            <span className="text-white-100 mr-1">{playerA.tries}</span>
                                            <span className="text-grey-1 text-xs">T</span>
                                        </li>
                                        <li>
                                            <span className="text-white-100 mr-1">{playerA.conversions}</span>
                                            <span className="text-grey-1 text-xs">C</span>
                                        </li>
                                        <li>
                                            <span className="text-grey-10 mr-1">{playerA.dropGoals}</span>
                                            <span className="text-grey-10 text-xs">DG</span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="absolute right-[5px] top-[10px]">
                                    {playerA.redCard > 0 && <IconRedCard width={24} height={24}/>}
                                    {playerA.yellowCard > 0 && <IconYellowCard width={24} height={24}/>}
                                </div>
                            </div>
                        </a>

                        {/* Separator */}
                        <span className="h4 text-grey-1 font-normal mx-2">{number}</span>

                        {/* Team B Player */}
                        {playerB.playerName ? (
                            <div
                                className={`${
                                    playerB.captain === 1 ? "border-2 rounded-xl" : "gradient-border--nested"
                                } flex flex-grow items-stretch h-full md:!rounded-xl flex-1`}
                            >
                                <div className="h-full">
                                    <Opponent opponent={opposition} size={'playerImage'}/>
                                </div>
                                <div
                                    className="w-full rounded-xl md:!rounded-l-none md:!rounded-r-xl flex text-left relative !bg-black-50">
                                    <div
                                        className="h-[138px] md:h-auto flex flex-col flex-grow justify-center p-2 md:py-0 md:pl-2 md:pr-6 relative bg-black-50 rounded-xl">
                                        {playerB.captain === 1 && <h6 className="text-grey-0">Captain</h6>}
                                        <span className="small-card-name block truncate text-wrap">
                                            {playerB.playerName.length > 34
                                                ? `${playerB.playerName.slice(0, 34)}...`
                                                : playerB.playerName}
                                        </span>
                                        <p className="text-grey-0 m-0 font-normal text-base">{playerB.position}</p>
                                        <ul className="lineup-points-list text-base font-normal">
                                            <li>
                                                <span className="text-white-100 mr-1">{playerB.points}</span>
                                                <span className="text-grey-1 text-xs">PT</span>
                                            </li>
                                            <li>
                                                <span className="text-white-100 mr-1">{playerB.tries}</span>
                                                <span className="text-grey-1 text-xs">T</span>
                                            </li>
                                            <li>
                                                <span className="text-white-100 mr-1">{playerB.conversions}</span>
                                                <span className="text-grey-1 text-xs">C</span>
                                            </li>
                                            <li>
                                                <span className="text-grey-10 mr-1">{playerB.dropGoals}</span>
                                                <span className="text-grey-10 text-xs">DG</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="absolute right-[5px] top-[10px]">
                                        {playerB.redCard > 0 && <IconRedCard width={24} height={24}/>}
                                        {playerB.yellowCard > 0 && <IconYellowCard width={24} height={24}/>}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div
                                className={"gradient-border--nested flex flex-grow items-stretch h-full !rounded-xl flex-1"}
                            >
                                <div className="h-full">
                                    <Opponent opponent={opposition} size={'playerImage'}/>
                                </div>
                                <div
                                    className="w-full !rounded-l-none !rounded-r-xl flex text-left relative !bg-black-50">
                                    <div
                                        className="h-[138px] md:h-auto flex flex-col flex-grow justify-center p-4 md:py-0 md:pl-2 md:pr-6 relative bg-black-50 rounded-xl">
                                        <span className='text-grey-10'>Not available</span>
                                    </div>
                                </div>
                            </div>
                        )}
                    </li>
                );
            })}
        </ul>
    );

    return (
        <div className="max-w-screen-xl grid grid-cols-6 lg:grid-cols-12 gap-4 mx-auto px-4 custom-block">
            <h2 className="col-span-6 lg:col-span-12 text-center mb-6 md:mb-14">Lineups</h2>
            <div
                className="lineup-list-container gradient-border--nested col-span-6 lg:col-span-8 lg:col-start-3 flex flex-col items-center relative mb-14 ">
                <div className="w-full md:!pt-8">
                    {renderList(mainNumbers, mainPlayers.nzTeam, mainPlayers.oppTeam)}
                </div>
            </div>
            { reservePlayers.nzTeam.length > 0 && (
                <div
                    className="lineup-list-container gradient-border--nested col-span-6 lg:col-span-8 lg:col-start-3 flex flex-col items-center relative">
                    <div className="w-full md:!pt-8">
                        {renderList(reserveNumbers, reservePlayers.nzTeam, reservePlayers.oppTeam)}
                    </div>
                </div>
            )}
        </div>
    );
}

export default MatchLineUp;
