import React, { useState } from 'react'
import BaseTableWithTabs from "./BaseTableWithTabs";

import {
    ALL_BLACKS_TEAM,
    BLACK_FERNS_TEAM, EARLIEST,
    EARLIEST_YEAR,
    FILTER_RESULTS_MAPPING, LATEST,
    LATEST_YEAR, MATCH_SORT_FUNCTIONS, MATCH_SORT_MAPPINGS,
    MATCH_TAB_DISPLAY_NAMES,
    MATCH_TAB_OPTIONS, NZ_POINTS, POINTS_MARGIN
} from '../../constants';
import {detailColumns, scoringColumns, summaryColumns} from "./allMatches/AllMatchColumns";
import TableTabs from "./Tabs";
import MatchesFilter from "./allMatches/MatchesFilter";

function MatchTable({ allMatches, withFilters }) {

    const tabColumns = {
        summary: summaryColumns,
        scoring: scoringColumns,
        details: detailColumns,
    };

    const [currentColumns, setCurrentColumns] = useState(tabColumns.summary)
    const setColumns = (val) => {
        setCurrentColumns(tabColumns[val]);
    }

    const [selectedTeams, setSelectedTeams] = useState([ALL_BLACKS_TEAM, BLACK_FERNS_TEAM])
    const [selectedOpponents, setSelectedOpponents] = useState([]);
    const [selectedResult, setSelectedResult] = useState([]);
    const [selectedType, setSelectedType] = useState(null);
    const [selectedLocationType, setSelectedLocationType] = useState(null);
    const [selectedSeries, setSelectedSeries] = useState([]);
    const [selectedDates, setSelectedDates] = useState([EARLIEST_YEAR, LATEST_YEAR]);
    const [selectedSort, setSort] = useState('Latest');

    const opponents = [...new Set(allMatches.filter(item => item.test === 'Test').map(item => item.opposition))].sort();

    const uniqueSeries = new Set();

    allMatches.forEach(game => {
        game.filterSeries.split(', ').forEach(series => {
            uniqueSeries.add(series);  // `trim()` to remove any leading/trailing spaces
        });
    });

    const series = Array.from(uniqueSeries).sort();

    let filteredMatches = [...allMatches];

    if (selectedTeams.length > 0) {
        filteredMatches = filteredMatches
            .filter(game => selectedTeams.includes(game.team))
    }

    if (selectedOpponents.length > 0) {
        filteredMatches = filteredMatches
            .filter(game => selectedOpponents.includes(game.opposition))
    }

    if (selectedResult.length > 0) {
        const selectedResultValues = selectedResult.map(result => FILTER_RESULTS_MAPPING[result]);
        filteredMatches = filteredMatches
            .filter(game => selectedResultValues.includes(game.result))
    }

    if (selectedType) {
        filteredMatches = filteredMatches
            .filter(game => game.test === selectedType)
    }

    if (selectedLocationType) {
        filteredMatches = filteredMatches
            .filter(game => game.home === selectedLocationType)
    }

    if (selectedSeries.length > 0) {
        filteredMatches = filteredMatches
            .filter(game => selectedSeries.some(filter => game.filterSeries.split(', ').includes(filter)))
    }

    if (selectedDates) {
        filteredMatches = filteredMatches
            .filter(game => game.date.split(' ')[2] >= selectedDates[0] && game.date.split(' ')[2] <= selectedDates[1])
    }

    const sortType = MATCH_SORT_MAPPINGS[selectedSort];

    if (sortType === EARLIEST) {
        filteredMatches.sort(MATCH_SORT_FUNCTIONS.EARLIEST);
    }
    if (sortType === LATEST) {
        filteredMatches.sort(MATCH_SORT_FUNCTIONS.LATEST);
    }
    if (sortType === POINTS_MARGIN) {
        filteredMatches.sort(MATCH_SORT_FUNCTIONS.POINTS_MARGIN);
    }
    if (sortType === NZ_POINTS) {
        filteredMatches.sort(MATCH_SORT_FUNCTIONS.NZ_POINTS);
    }


    const tabs = <TableTabs tabOptions={MATCH_TAB_OPTIONS} tabDisplayNames={MATCH_TAB_DISPLAY_NAMES} setCurrentColumns={setColumns} />;

    let filters = null;
    if (withFilters) {
        filters = <MatchesFilter
            opponents={opponents}
            selectedOpponents={selectedOpponents}
            setSelectedOpponents={setSelectedOpponents}
            selectedResult={selectedResult}
            setSelectedResult={setSelectedResult}
            selectedType={selectedType}
            setSelectedType={setSelectedType}
            selectedLocationType={selectedLocationType}
            setSelectedLocationType={setSelectedLocationType}
            series={series}
            selectedSeries={selectedSeries}
            setSelectedSeries={setSelectedSeries}
            selectedDates={selectedDates}
            setSelectedDates={setSelectedDates}
            selectedSort={selectedSort}
            setSelectedSort={setSort}
        />
    }

    return (
        <BaseTableWithTabs
            title={ 'All Matches' }
            data={ filteredMatches }
            columns={ currentColumns }
            pinnedColumn={ 'match' }
            tabs={ tabs }
            filters={ withFilters ? filters : null }
            selectedTeams={ withFilters ? selectedTeams : null }
            setSelectedTeams={ withFilters ? setSelectedTeams : null }
        />
    );
}

export default MatchTable;