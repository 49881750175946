import React from 'react'

export const Japan = ({ width, height }) => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={ width ? width : "182" } height={ height ? height : "181" } viewBox="0 0 182 181" fill="none">
            <g clipPath="url(#clip0_4060_13355)">
                <mask id={`mask0_4060_13355_${width}`} style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="0" y="0"
                      width="182" height="181">
                    <path d="M181.64 0H0V181H181.64V0Z" fill="white"/>
                </mask>
                <g mask="url(#mask0_4060_13355)">
                    <mask id={`mask1_4060_13355_${width}`} style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="0" y="0"
                          width="182" height="181">
                        <path d="M181.64 0H0V181H181.64V0Z" fill="white"/>
                    </mask>
                    <g mask="url(#mask1_4060_13355)">
                        <path
                            d="M75.1342 116.469C80.1129 130.108 92.1621 140.423 92.1621 140.423L92.4503 135.807C92.4503 135.807 86.1749 133.065 76.8685 117.477C74.8994 114.185 75.1342 116.469 75.1342 116.469Z"
                            fill="#00AA83"/>
                        <path
                            d="M106.085 119.068C98.7261 132.056 92.2319 133.785 92.2319 133.785C92.2319 133.785 93.0804 139.036 92.4454 138.406C92.2319 138.187 103.7 129.964 108.027 119.356L106.085 119.068Z"
                            fill="#00AA83"/>
                        <path
                            d="M93.3095 95.4707C91.3618 152.617 83.1387 167.115 83.1387 167.115C83.1387 167.115 90.0117 167.329 90.0704 167.115C97.2103 137.937 95.9083 95.4707 95.9083 95.4707H93.3095Z"
                            fill="#00AA83"/>
                        <path
                            d="M126.783 123.603C126.783 123.603 133.854 129.729 146.944 131.384C147.237 131.421 147.968 125.098 147.968 125.098C147.968 125.098 154.58 124.82 154.553 124.27C153.945 110.962 150.973 104.345 150.973 104.345C150.973 104.345 158.582 99.601 164.484 87.8452C164.553 87.7011 159.089 85.1557 159.089 85.1557C159.089 85.1557 161.005 78.9444 160.535 78.7096C150.732 73.8803 139.398 75.4598 139.398 75.4598C139.398 75.4598 136.287 64.195 127.76 57.4713C127.093 56.9484 123.24 61.5269 123.24 61.5269C123.24 61.5269 118.806 57.5033 118.208 58.101C110.497 65.7478 108.688 77.2955 108.688 77.2955C108.688 77.2955 97.2473 77.0126 88.053 82.9252C87.6154 83.208 90.2142 89.1633 90.2142 89.1633C90.2142 89.1633 85.0807 92.317 85.1661 92.4451C92.3647 103.459 100.46 107.306 100.46 107.306C100.46 107.306 98.2559 114.217 99.1631 127.509C99.2004 128.059 105.807 127.584 105.807 127.584C105.807 127.584 107.247 133.785 107.536 133.71C120.353 130.589 126.799 123.593 126.799 123.593V123.603H126.783Z"
                            fill="#F3777E"/>
                        <path
                            d="M126.282 119.286C126.282 119.286 133.524 126.373 144.708 127.787C144.964 127.814 145.477 121.757 145.477 121.757C145.477 121.757 151.24 121.842 151.213 121.378C150.696 110.001 146.741 103.043 146.741 103.043C146.741 103.043 155.087 98.9873 160.135 88.9338C160.194 88.8164 155.525 86.7406 155.525 86.7406C155.525 86.7406 157.286 80.9561 156.864 80.796C147.942 77.4235 137.494 79.0991 137.494 79.0991C137.494 79.0991 134.943 67.9517 127.648 62.2046C127.077 61.7563 123.465 66.4255 123.465 66.4255C123.465 66.4255 119.185 62.4821 118.833 62.8449C112.894 69.0616 111.453 80.6626 111.453 80.6626C111.453 80.6626 100.482 79.7768 91.9702 84.3927C91.5753 84.6061 94.3609 90.1611 94.3609 90.1611C94.3609 90.1611 89.4248 93.0801 89.5049 93.1868C96.2232 102.322 104.42 105.999 104.42 105.999C104.42 105.999 101.133 113.315 101.901 124.681C101.933 125.151 108.555 124.414 108.555 124.414C108.555 124.414 109.356 129.831 109.606 129.767C120.562 127.093 126.512 119.494 126.512 119.494L126.288 119.281L126.282 119.286Z"
                            fill="#FBD6E6"/>
                        <path
                            d="M125.13 100.412C126.827 100.412 128.193 99.0407 128.193 97.3491C128.193 95.6575 126.827 94.2861 125.13 94.2861C123.433 94.2861 122.067 95.6575 122.067 97.3491C122.067 99.0407 123.438 100.412 125.13 100.412Z"
                            fill="#F3777E"/>
                        <path
                            d="M134.66 89.0299C135.503 89.0299 136.186 88.3468 136.186 87.4984C136.186 86.6499 135.503 85.9722 134.66 85.9722C133.817 85.9722 133.128 86.6552 133.128 87.4984C133.128 88.3415 133.811 89.0299 134.66 89.0299Z"
                            fill="#F3777E"/>
                        <path
                            d="M128.113 85.62C128.95 85.62 129.633 84.937 129.633 84.0938C129.633 83.2507 128.95 82.5677 128.113 82.5677C127.275 82.5677 126.581 83.2507 126.581 84.0938C126.581 84.937 127.264 85.62 128.113 85.62Z"
                            fill="#F3777E"/>
                        <path
                            d="M120.861 85.62C121.704 85.62 122.387 84.937 122.387 84.0938C122.387 83.2507 121.704 82.5677 120.861 82.5677C120.017 82.5677 119.329 83.2507 119.329 84.0938C119.329 84.937 120.012 85.62 120.861 85.62Z"
                            fill="#F3777E"/>
                        <path
                            d="M114.633 89.4568C115.482 89.4568 116.165 88.7791 116.165 87.9306C116.165 87.0821 115.482 86.4044 114.633 86.4044C113.785 86.4044 113.107 87.0875 113.107 87.9306C113.107 88.7737 113.79 89.4568 114.633 89.4568Z"
                            fill="#F3777E"/>
                        <path
                            d="M111.554 96.2231C112.397 96.2231 113.08 95.5401 113.08 94.6969C113.08 93.8538 112.397 93.1708 111.554 93.1708C110.711 93.1708 110.022 93.8592 110.022 94.6969C110.022 95.5347 110.706 96.2231 111.554 96.2231Z"
                            fill="#F3777E"/>
                        <path
                            d="M111.874 103.043C112.717 103.043 113.4 102.354 113.4 101.517C113.4 100.679 112.717 99.9905 111.874 99.9905C111.031 99.9905 110.348 100.674 110.348 101.517C110.348 102.36 111.026 103.043 111.874 103.043Z"
                            fill="#F3777E"/>
                        <path
                            d="M115.824 108.721C116.667 108.721 117.35 108.038 117.35 107.194C117.35 106.351 116.667 105.668 115.824 105.668C114.98 105.668 114.297 106.351 114.297 107.194C114.297 108.038 114.98 108.721 115.824 108.721Z"
                            fill="#F3777E"/>
                        <path
                            d="M122.045 111.81C122.888 111.81 123.577 111.127 123.577 110.284C123.577 109.441 122.888 108.753 122.045 108.753C121.202 108.753 120.519 109.436 120.519 110.284C120.519 111.133 121.202 111.81 122.045 111.81Z"
                            fill="#F3777E"/>
                        <path
                            d="M129.084 111.373C129.927 111.373 130.61 110.69 130.61 109.847C130.61 109.003 129.927 108.32 129.084 108.32C128.241 108.32 127.553 108.998 127.553 109.847C127.553 110.695 128.241 111.373 129.084 111.373Z"
                            fill="#F3777E"/>
                        <path
                            d="M134.874 107.536C135.717 107.536 136.4 106.853 136.4 106.01C136.4 105.167 135.717 104.484 134.874 104.484C134.031 104.484 133.348 105.167 133.348 106.01C133.348 106.853 134.031 107.536 134.874 107.536Z"
                            fill="#F3777E"/>
                        <path
                            d="M138.177 101.746C139.02 101.746 139.703 101.063 139.703 100.215C139.703 99.3662 139.02 98.6885 138.177 98.6885C137.333 98.6885 136.645 99.3715 136.645 100.215C136.645 101.058 137.328 101.746 138.177 101.746Z"
                            fill="#F3777E"/>
                        <path
                            d="M138.065 94.7076C138.913 94.7076 139.596 94.0246 139.596 93.1815C139.596 92.3383 138.913 91.6553 138.065 91.6553C137.216 91.6553 136.539 92.3383 136.539 93.1815C136.539 94.0246 137.222 94.7076 138.065 94.7076Z"
                            fill="#F3777E"/>
                        <path d="M114.58 87.984L134.815 106.058" stroke="#F3777E" strokeWidth="0.0800436"/>
                        <path d="M120.695 84.0351L129.031 109.793" stroke="#F3777E" strokeWidth="0.0800436"/>
                        <path d="M128.059 84.0351L121.885 110.332" stroke="#F3777E" strokeWidth="0.0800436"/>
                        <path d="M134.601 87.3383L115.658 107.141" stroke="#F3777E" strokeWidth="0.0800436"/>
                        <path d="M138.124 93.1281L111.821 101.458" stroke="#F3777E" strokeWidth="0.0800436"/>
                        <path d="M138.23 99.9478L111.389 94.6436" stroke="#F3777E" strokeWidth="0.0800436"/>
                        <path
                            d="M94.5047 72.5836C94.5047 72.5836 101.773 78.4854 114.91 79.7128C115.204 79.7448 115.727 73.3947 115.727 73.3947C115.727 73.3947 122.328 72.9037 122.285 72.3594C121.245 59.0775 118.059 52.562 118.059 52.562C118.059 52.562 125.514 47.5726 131.031 35.6301C131.095 35.4913 125.551 33.1167 125.551 33.1167C125.551 33.1167 127.269 26.8466 126.789 26.6278C116.832 22.1187 105.556 24.0611 105.556 24.0611C105.556 24.0611 102.082 12.903 93.3467 6.45685C92.6637 5.95525 88.9603 10.6565 88.9603 10.6565C88.9603 10.6565 84.4032 6.77703 83.8215 7.3907C76.3615 15.283 74.9207 26.8787 74.9207 26.8787C74.9207 26.8787 63.4798 26.9694 54.4829 33.1701C54.0506 33.4689 56.8415 39.3388 56.8415 39.3388C56.8415 39.3388 51.8094 42.6526 51.9001 42.7806C59.4509 53.5599 67.6687 57.1458 67.6687 57.1458C67.6687 57.1458 65.6943 64.1256 67.023 77.3755C67.0764 77.9251 73.6613 77.2368 73.6613 77.2368C73.6613 77.2368 75.2996 83.3894 75.5877 83.3094C88.2933 79.7715 94.5154 72.5729 94.5154 72.5729L94.5047 72.5836Z"
                            fill="#F3777E"/>
                        <path
                            d="M93.8645 68.2879C93.8645 68.2879 101.325 75.1343 112.557 76.1855C112.813 76.2069 113.128 70.1342 113.128 70.1342C113.128 70.1342 118.886 70.0382 118.854 69.5686C117.963 58.2131 113.795 51.388 113.795 51.388C113.795 51.388 122.008 47.0657 126.725 36.8521C126.784 36.7347 122.05 34.8136 122.05 34.8136C122.05 34.8136 123.619 28.9705 123.198 28.8264C114.169 25.7474 103.779 27.7538 103.779 27.7538C103.779 27.7538 100.871 16.6971 93.3949 11.1848C92.8132 10.7579 89.3447 15.5445 89.3447 15.5445C89.3447 15.5445 84.9476 11.7397 84.6061 12.1186C78.8696 18.5221 77.8024 30.1658 77.8024 30.1658C77.8024 30.1658 66.8097 29.6268 58.4532 34.5202C58.0636 34.7443 61.0306 40.2086 61.0306 40.2086C61.0306 40.2086 56.1853 43.2823 56.2706 43.3836C63.2878 52.3005 71.5963 55.7157 71.5963 55.7157C71.5963 55.7157 68.544 63.1331 69.6806 74.4673C69.7287 74.9368 76.3189 73.987 76.3189 73.987C76.3189 73.987 77.2901 79.3713 77.5409 79.3019C88.4055 76.2762 94.0993 68.4853 94.0993 68.4853L93.8751 68.2825L93.8645 68.2879Z"
                            fill="#FBD6E6"/>
                        <path
                            d="M92.104 49.4563C93.7902 49.4029 95.119 47.9888 95.0709 46.2972C95.0122 44.611 93.6035 43.2823 91.9119 43.3356C90.215 43.389 88.8916 44.8031 88.9449 46.4947C88.9983 48.1863 90.4124 49.5097 92.104 49.4563Z"
                            fill="#F3777E"/>
                        <path
                            d="M101.261 37.7753C102.104 37.7486 102.766 37.0442 102.739 36.2011C102.707 35.3579 102.003 34.6962 101.159 34.7229C100.316 34.7496 99.6546 35.454 99.6867 36.2971C99.7133 37.1402 100.423 37.8019 101.261 37.7753Z"
                            fill="#F3777E"/>
                        <path
                            d="M94.6061 34.5788C95.4492 34.5522 96.1109 33.8478 96.0789 33.0047C96.0522 32.1615 95.3425 31.4998 94.5047 31.5265C93.6616 31.5532 92.9999 32.2576 93.0319 33.1007C93.0586 33.9438 93.7683 34.6055 94.6061 34.5788Z"
                            fill="#F3777E"/>
                        <path
                            d="M87.3543 34.8136C88.1974 34.787 88.8644 34.0826 88.8324 33.2395C88.8057 32.3963 88.096 31.7346 87.2582 31.7613C86.4098 31.788 85.7534 32.4924 85.7801 33.3355C85.8068 34.1786 86.5165 34.8403 87.3543 34.8136Z"
                            fill="#F3777E"/>
                        <path
                            d="M81.2604 38.8532C82.1035 38.8265 82.7652 38.1221 82.7332 37.2736C82.7118 36.4305 82.0074 35.7688 81.1643 35.8008C80.3159 35.8275 79.6542 36.5319 79.6862 37.375C79.7129 38.2182 80.4226 38.8799 81.2604 38.8532Z"
                            fill="#F3777E"/>
                        <path
                            d="M78.3946 45.7103C79.2378 45.6836 79.8995 44.9792 79.8674 44.1361C79.8408 43.2929 79.1364 42.6312 78.2986 42.6579C77.4501 42.6846 76.7884 43.389 76.8205 44.2321C76.8471 45.0752 77.5569 45.7369 78.3946 45.7103Z"
                            fill="#F3777E"/>
                        <path
                            d="M78.9392 52.514C79.7824 52.4873 80.444 51.7829 80.4174 50.9398C80.3907 50.0966 79.681 49.4349 78.8432 49.4616C78.0001 49.4883 77.3384 50.1927 77.365 51.0358C77.3917 51.8789 78.0961 52.5406 78.9392 52.5086"
                            fill="#F3777E"/>
                        <path
                            d="M83.075 58.069C83.9181 58.0423 84.5798 57.3379 84.5478 56.4948C84.5211 55.6517 83.8167 54.99 82.9736 55.0167C82.1305 55.0433 81.4741 55.7477 81.5008 56.5908C81.5275 57.434 82.2318 58.0903 83.075 58.0636"
                            fill="#F3777E"/>
                        <path
                            d="M89.3932 60.9506C90.2363 60.9239 90.898 60.2195 90.866 59.371C90.8393 58.5279 90.1296 57.8662 89.2918 57.8982C88.4487 57.9249 87.787 58.6293 87.819 59.4724C87.8457 60.3155 88.5554 60.9772 89.3932 60.9506Z"
                            fill="#F3777E"/>
                        <path
                            d="M96.4044 60.2889C97.2475 60.2622 97.9092 59.5578 97.8772 58.7147C97.8559 57.8715 97.1515 57.2099 96.3083 57.2365C95.4599 57.2632 94.7982 57.9676 94.8302 58.8107C94.8569 59.6538 95.5666 60.3155 96.4044 60.2835"
                            fill="#F3777E"/>
                        <path
                            d="M102.071 56.2653C102.915 56.2387 103.576 55.5343 103.55 54.6911C103.523 53.848 102.813 53.1863 101.97 53.213C101.132 53.2397 100.465 53.9441 100.497 54.7872C100.524 55.6303 101.234 56.292 102.071 56.2653Z"
                            fill="#F3777E"/>
                        <path
                            d="M105.183 50.3688C106.026 50.3421 106.688 49.6377 106.656 48.7946C106.629 47.9515 105.924 47.2898 105.087 47.3165C104.238 47.3431 103.577 48.0529 103.609 48.8907C103.635 49.7338 104.345 50.3955 105.183 50.3688Z"
                            fill="#F3777E"/>
                        <path
                            d="M104.852 43.341C105.695 43.3143 106.357 42.6099 106.325 41.7668C106.298 40.9236 105.599 40.2619 104.751 40.2886C103.908 40.3153 103.246 41.025 103.278 41.8682C103.305 42.7059 104.009 43.373 104.852 43.341Z"
                            fill="#F3777E"/>
                        <path d="M81.1592 37.3804L101.971 54.7925" stroke="#F3777E" strokeWidth="0.0800436"/>
                        <path d="M87.1411 33.2341L96.2981 58.7093" stroke="#F3777E" strokeWidth="0.0800436"/>
                        <path d="M94.4994 32.9993L89.1792 59.4831" stroke="#F3777E" strokeWidth="0.0800436"/>
                        <path d="M101.149 36.089L82.856 56.4948" stroke="#F3777E" strokeWidth="0.0800436"/>
                        <path d="M104.852 41.7614L78.8374 50.9398" stroke="#F3777E" strokeWidth="0.0800436"/>
                        <path d="M105.177 48.5705L78.1812 44.1361" stroke="#F3777E" strokeWidth="0.0800436"/>
                        <path
                            d="M58.6879 123.198C58.6879 123.198 65.8705 129.201 78.987 130.615C79.2858 130.647 79.8941 124.308 79.8941 124.308C79.8941 124.308 86.5004 123.908 86.4684 123.363C85.6146 110.071 82.5196 103.507 82.5196 103.507C82.5196 103.507 90.0437 98.6244 95.7268 86.762C95.7961 86.6179 90.2891 84.1739 90.2891 84.1739C90.2891 84.1739 92.0928 77.9251 91.6179 77.701C81.7245 73.0532 70.4223 74.8408 70.4223 74.8408C70.4223 74.8408 67.1031 63.6347 58.4531 57.0711C57.7754 56.5588 54.008 61.2067 54.008 61.2067C54.008 61.2067 49.5042 57.2632 48.9119 57.8715C41.3451 65.6625 39.7389 77.2368 39.7389 77.2368C39.7389 77.2368 28.298 77.1674 19.2104 83.24C18.7728 83.5335 21.4836 89.4408 21.4836 89.4408C21.4836 89.4408 16.4088 92.6852 16.4942 92.8133C23.8903 103.694 32.0601 107.392 32.0601 107.392C32.0601 107.392 29.9843 114.345 31.1315 127.616C31.1796 128.166 37.7698 127.568 37.7698 127.568C37.7698 127.568 39.328 133.742 39.6162 133.668C52.3698 130.306 58.6879 123.198 58.6879 123.198Z"
                            fill="#F3777E"/>
                        <path
                            d="M58.1063 118.897C58.1063 118.897 65.4757 125.845 76.6871 127.051C76.9432 127.077 77.3435 121.015 77.3435 121.015C77.3435 121.015 83.1066 120.999 83.0746 120.53C82.3435 109.164 78.272 102.28 78.272 102.28C78.272 102.28 86.5431 98.0748 91.3991 87.9253C91.4631 87.8079 86.7513 85.8175 86.7513 85.8175C86.7513 85.8175 88.4055 80.0009 87.9893 79.8515C79.003 76.6444 68.5867 78.5068 68.5867 78.5068C68.5867 78.5068 65.8278 67.4127 58.4372 61.799C57.8608 61.3561 54.3282 66.0947 54.3282 66.0947C54.3282 66.0947 49.9792 62.2259 49.6377 62.6048C43.8105 68.9229 42.5778 80.5506 42.5778 80.5506C42.5778 80.5506 31.5905 79.8622 23.1646 84.6381C22.775 84.8569 25.662 90.3586 25.662 90.3586C25.662 90.3586 20.774 93.3629 20.854 93.4696C27.7431 102.483 36.0036 106.01 36.0036 106.01C36.0036 106.01 32.8499 113.384 33.8317 124.729C33.8691 125.199 40.4754 124.345 40.4754 124.345C40.4754 124.345 41.3718 129.74 41.6173 129.676C52.5246 126.805 58.3304 119.1 58.3304 119.1L58.1063 118.891V118.897Z"
                            fill="#FBD6E6"/>
                        <path
                            d="M56.6069 100.044C58.3038 100.012 59.6432 98.6138 59.6112 96.9222C59.5845 95.2359 58.1864 93.8912 56.4948 93.9179C54.8033 93.9445 53.4585 95.3426 53.4959 97.0289C53.5226 98.7258 54.9207 100.065 56.6069 100.039"
                            fill="#F3777E"/>
                        <path
                            d="M65.9242 88.4856C66.7673 88.4696 67.4397 87.7758 67.4237 86.9274C67.4077 86.0843 66.714 85.4172 65.8709 85.4332C65.0277 85.4492 64.3554 86.1429 64.3714 86.9861C64.3874 87.8292 65.0811 88.5016 65.9296 88.4856"
                            fill="#F3777E"/>
                        <path
                            d="M59.3129 85.1984C60.1614 85.1824 60.8284 84.4887 60.8124 83.6402C60.7964 82.7971 60.1027 82.1301 59.2595 82.1408C58.4164 82.1568 57.744 82.8505 57.76 83.6936C57.7761 84.5367 58.4698 85.2091 59.3129 85.1931"
                            fill="#F3777E"/>
                        <path
                            d="M52.0663 85.3318C52.9094 85.3158 53.5818 84.6221 53.5604 83.779C53.5444 82.9359 52.8507 82.2635 52.0076 82.2795C51.1644 82.2955 50.4921 82.9892 50.5081 83.8324C50.5241 84.6755 51.2178 85.3425 52.0663 85.3265"
                            fill="#F3777E"/>
                        <path
                            d="M45.9135 89.286C46.7566 89.27 47.429 88.5763 47.4183 87.7332C47.4023 86.89 46.7086 86.2177 45.8601 86.2337C45.017 86.2497 44.3446 86.9487 44.3606 87.7865C44.3766 88.6296 45.0704 89.302 45.9135 89.286Z"
                            fill="#F3777E"/>
                        <path
                            d="M42.9511 96.1057C43.7995 96.0897 44.4666 95.396 44.4506 94.5475C44.4346 93.7044 43.7408 93.032 42.8977 93.0534C42.0546 93.0694 41.3822 93.7631 41.3982 94.6062C41.4142 95.4494 42.1079 96.1217 42.9511 96.1057Z"
                            fill="#F3777E"/>
                        <path
                            d="M43.4047 102.915C44.2478 102.899 44.9202 102.205 44.9042 101.357C44.8882 100.513 44.1945 99.8464 43.3513 99.8624C42.5082 99.8784 41.8358 100.572 41.8518 101.415C41.8625 102.258 42.5616 102.931 43.4047 102.915Z"
                            fill="#F3777E"/>
                        <path
                            d="M47.4548 108.528C48.2979 108.512 48.9703 107.813 48.9543 106.97C48.9383 106.127 48.2446 105.455 47.4014 105.471C46.553 105.487 45.8859 106.181 45.8966 107.029C45.9126 107.872 46.6063 108.544 47.4548 108.528Z"
                            fill="#F3777E"/>
                        <path
                            d="M53.7306 111.495C54.5737 111.479 55.2461 110.786 55.2301 109.937C55.2141 109.094 54.5204 108.427 53.6719 108.443C52.8288 108.459 52.1564 109.153 52.1777 109.996C52.1937 110.839 52.8875 111.511 53.7306 111.495Z"
                            fill="#F3777E"/>
                        <path
                            d="M60.7584 110.935C61.6015 110.919 62.2739 110.225 62.2579 109.382C62.2419 108.539 61.5482 107.867 60.6997 107.883C59.8566 107.899 59.1842 108.593 59.2056 109.441C59.2216 110.284 59.9153 110.951 60.7584 110.935Z"
                            fill="#F3777E"/>
                        <path
                            d="M66.4789 106.986C67.322 106.97 67.9944 106.277 67.9784 105.433C67.9624 104.59 67.2687 103.918 66.4255 103.934C65.5824 103.95 64.9101 104.649 64.9261 105.492C64.9421 106.335 65.6358 107.008 66.4842 106.986"
                            fill="#F3777E"/>
                        <path
                            d="M69.6757 101.138C70.5188 101.122 71.1911 100.428 71.1751 99.5796C71.1591 98.7365 70.4654 98.0641 69.617 98.0855C68.7738 98.1015 68.1015 98.7952 68.1175 99.6383C68.1335 100.481 68.8272 101.154 69.6757 101.138Z"
                            fill="#F3777E"/>
                        <path
                            d="M69.4349 94.1046C70.2781 94.0886 70.9504 93.3949 70.9344 92.5518C70.9184 91.7087 70.2247 91.0363 69.3762 91.0576C68.5331 91.0683 67.8607 91.7674 67.8767 92.6105C67.8928 93.4536 68.5865 94.126 69.4349 94.1046Z"
                            fill="#F3777E"/>
                        <path d="M45.833 87.8132L66.3989 105.514" stroke="#F3777E" strokeWidth="0.0800436"/>
                        <path d="M51.8735 83.7523L60.6783 109.35" stroke="#F3777E" strokeWidth="0.0800436"/>
                        <path d="M59.2324 83.6189L53.5493 110.023" stroke="#F3777E" strokeWidth="0.0800436"/>
                        <path d="M65.8386 86.7993L47.2632 106.944" stroke="#F3777E" strokeWidth="0.0800436"/>
                        <path d="M69.4621 92.5198L43.3198 101.335" stroke="#F3777E" strokeWidth="0.0800436"/>
                        <path d="M69.6913 99.3395L42.7646 94.5262" stroke="#F3777E" strokeWidth="0.0800436"/>
                        <path
                            d="M124.244 156.821C103.24 157.371 93.0962 145.797 93.0962 145.797C93.0962 145.797 102.317 133.689 123.625 132.84C145.615 131.96 154.783 144.175 154.783 144.175C154.783 144.175 143.924 156.304 124.239 156.821"
                            fill="#00AA83"/>
                        <path
                            d="M124.628 153.7C106.784 154.164 97.0718 145.749 97.0718 145.749C97.0718 145.749 105.348 136.416 123.459 135.695C142.152 134.948 150.589 144.249 150.589 144.249C150.589 144.249 141.363 153.262 124.628 153.7Z"
                            fill="#84CCB0"/>
                        <path
                            d="M57.5732 156.821C37.1088 157.355 27.2954 145.477 27.2954 145.477C27.2954 145.477 38.5549 133.603 57.6053 132.84C78.0217 132.024 88.5501 145.904 88.5501 145.904C88.5501 145.904 77.2586 156.304 57.5732 156.821Z"
                            fill="#00AA83"/>
                        <path
                            d="M57.9629 153.7C40.1132 154.164 31.2764 145.749 31.2764 145.749C31.2764 145.749 40.5561 136.17 57.8722 135.477C73.5287 134.852 85.007 145.759 85.007 145.759C85.007 145.759 74.692 153.262 57.9576 153.7"
                            fill="#84CCB0"/>
                        <path
                            d="M91.3619 145.69C130.375 138.241 152.403 144.5 152.403 144.5C152.403 144.5 116.037 140.578 91.0418 148.502C90.903 148.545 91.3619 145.69 91.3619 145.69Z"
                            fill="#00AA83"/>
                        <path
                            d="M90.9032 145.904C52.5729 136.715 28.5278 146.341 28.5278 146.341C28.5278 146.341 67.1089 138.993 91.234 148.726C91.3674 148.774 90.9085 145.909 90.9085 145.909"
                            fill="#00AA83"/>
                        <path d="M70.7109 143.017C67.9841 146.704 62.285 152.542 58.9712 154.708" stroke="#00AA83"
                              strokeWidth="0.160087"/>
                        <path d="M52.0441 142.878C49.5361 146.266 44.7335 150.498 40.9341 152.974" stroke="#00AA83"
                              strokeWidth="0.160087"/>
                        <path d="M50.6196 143.043C49.1628 140.69 44.3922 137.36 43.8159 137.109" stroke="#00AA83"
                              strokeWidth="0.160087"/>
                        <path d="M69.0724 143.31C67.6156 140.957 60.7746 134.745 60.1982 134.489" stroke="#00AA83"
                              strokeWidth="0.160087"/>
                        <path d="M110.487 144.1C113.176 146.72 124.777 153.113 127.227 154.708" stroke="#00AA83"
                              strokeWidth="0.160087"/>
                        <path d="M126.794 142.803C128.491 144.639 136.928 149.324 140.871 151.896" stroke="#00AA83"
                              strokeWidth="0.160087"/>
                        <path d="M126.507 142.744C128.225 141.032 136.027 135.77 136.608 135.514" stroke="#00AA83"
                              strokeWidth="0.160087"/>
                        <path d="M109.874 143.726C111.33 141.373 120.156 134.617 120.733 134.361" stroke="#00AA83"
                              strokeWidth="0.160087"/>
                    </g>
                </g>
            </g>
            <defs>
                <clipPath id={`clip0_4060_13355_${width}`}>
                    <rect width="181.64" height="181" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}