import React from 'react'

export const IconFootprints = ({ width, height }) => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width ? width : "24"} height={height ? height : "25"} fill="none">
            <path fill="currentColor"
                  d="M19.506 17.567H14.25a.75.75 0 0 0-.75.75v1.125a3.377 3.377 0 1 0 6.755 0v-1.125a.75.75 0 0 0-.75-.75Zm-.75 1.875a1.875 1.875 0 1 1-3.75 0v-.375h3.75v.375ZM9.75 15.317H4.5a.75.75 0 0 0-.75.75v1.125a3.375 3.375 0 1 0 6.75 0v-1.125a.75.75 0 0 0-.75-.75ZM9 17.192a1.875 1.875 0 0 1-3.75 0v-.375H9v.375ZM7.125 1.817c-1.091 0-2.15.967-2.981 2.719-1.306 2.754-1.74 6.844.027 9a.75.75 0 0 0 .581.275h4.74a.75.75 0 0 0 .58-.275c1.768-2.156 1.334-6.248.028-9-.833-1.752-1.89-2.719-2.975-2.719Zm1.983 10.5H5.136c-1.069-1.697-.676-4.94.364-7.135.607-1.282 1.266-1.865 1.625-1.865.36 0 1.014.583 1.622 1.865 1.037 2.195 1.43 5.438.36 7.135Zm5.4 3.75h4.74a.75.75 0 0 0 .581-.274c1.767-2.157 1.333-6.249.027-9-.831-1.76-1.89-2.726-2.98-2.726-1.092 0-2.147.967-2.98 2.719-1.305 2.754-1.74 6.844.028 9a.754.754 0 0 0 .585.281Zm.75-8.635c.607-1.282 1.265-1.865 1.617-1.865.352 0 1.015.583 1.621 1.865 1.04 2.195 1.434 5.437.366 7.135h-3.97c-1.069-1.697-.676-4.94.365-7.135h.002Z"/>
        </svg>
    )
}