import React from 'react'

export const IconArrowDown = ({ width, height }) => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg"  width={ width ? width : "33" } height={ height ? height : "32" } viewBox="0 0 33 32" fill="none">
            <path fill="currentColor"
                  d="M16.5 4a1 1 0 0 1 1 1v19.586l7.292-7.294a1 1 0 0 1 1.415 1.415l-9 9a1 1 0 0 1-1.415 0l-8.999-9a1 1 0 1 1 1.415-1.415l7.292 7.294V5a1 1 0 0 1 1-1Z"/>
        </svg>
    )
}