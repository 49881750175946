import React from 'react';
import {Combobox, Input, NumberInput, useCombobox} from '@mantine/core';
import { IconChevron } from "../../SVGs/IconChevron";
import {EARLIEST_YEAR, LATEST_YEAR} from "../../../constants";


export function YearSelect({label, selectOptions, value, setValue, classNames=''}) {
    const combobox = useCombobox({
        onDropdownClose: () => combobox.resetSelectedOption(),
        onDropdownOpen: () => combobox.updateSelectedOptionIndex('active'),
    });

    const handleChangeMin = (val) => setValue([val, value[1]]);

    const handleChangeMax = (val) => setValue([value[0], val]);

    const Description = () => {
        return <span className="pr-1 text-purple-10 truncate max-w-32 sm:max-w-48">{value[0]} - {value[1]}</span>
    }
    const RightSide = ({dropdownOpened}) => {

        return (
            <div className="flex pr-4">
                <Description />
                <IconChevron classname={dropdownOpened ? '' : 'rotate-180'} colour={value > 0 ? '#A48EFF' : 'white'} />
            </div>
        )
    };

    return (
        <Combobox store={combobox} withinPortal={false}>
            <Combobox.DropdownTarget>
                <Input
                    component="button"
                    classNames={{input: 'bg-grey-gradient h5 px-4 py-2 h-fit ' + classNames, option: 'h5' }}
                    rightSection={<RightSide dropdownOpened={combobox.dropdownOpened} />}
                    rightSectionWidth={'fit-content'}
                    pointer
                    onClick={() => combobox.toggleDropdown()}
                >
                    { label }
                </Input>
            </Combobox.DropdownTarget>

            <Combobox.Dropdown className='static bg-grey-gradient'>
                <div className="flex">
                    <NumberInput
                        placeholder="YYYY"
                        label="From"
                        value={value[0]}
                        onChange={handleChangeMin}
                        min={EARLIEST_YEAR}
                        max={LATEST_YEAR}
                        classNames={{wrapper: 'mr-8', control: 'min-w-fit', input: 'w-24 bg-grey-gradient'}}
                    />
                    <NumberInput
                        placeholder="YYYY"
                        label="To"
                        value={value[1]}
                        onChange={handleChangeMax}
                        min={EARLIEST_YEAR}
                        max={LATEST_YEAR}
                        classNames={{control: 'min-w-fit', input: 'w-24 bg-grey-gradient'}}
                    />
                </div>
            </Combobox.Dropdown>
        </Combobox>
    );
}
