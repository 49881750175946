import React from 'react'

export const BlackFernsWordMark = ({ width, height }) => {

    return (
        <svg width={ width ? width : "390" } height={ height ? height : "49" } viewBox="0 0 390 49" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_164_22903)">
                <path
                    d="M203.3 48.3998H216.6V35.0999H228V26.7999H216.6V21.3999H229.6V13.0999H200.5V15.3999H201.6C202.7 15.5999 203.2 16.2999 203.2 17.1999V48.3998H203.3Z"
                    fill="white"/>
                <path
                    d="M235.1 48.3998H261.5V40.0999H248.5V35.0999H259.9V26.7999H248.5V21.3999H261.5V13.0999H232.4V15.3999H233.5C234.6 15.5999 235.1 16.2999 235.1 17.1999V48.3998Z"
                    fill="white"/>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M280.5 28.4999V20.6999H282.4C285.2 20.6999 286.6 21.8999 286.6 24.7999C286.6 27.6999 285.3 28.5999 282.7 28.5999H280.4L280.5 28.4999ZM299.9 38.7999C299.9 34.7999 297.4 32.7999 292.3 31.9999V31.7999C297.6 30.5999 300 27.7999 300 22.6999C300 17.5999 295.8 13.0999 288.5 13.0999H264.4V15.3999H265.5C266.6 15.5999 267.1 16.2999 267.1 17.1999V48.3998H280.4V36.6999H282.7C285.3 36.6999 286.5 38.1999 286.5 41.2999V44.7999C286.5 46.6999 287.3 48.2999 289.5 48.2999H302.5V45.9999H301.4C300.3 45.7999 299.8 45.0999 299.8 44.1999V38.6999L299.9 38.7999Z"
                      fill="white"/>
                <path
                    d="M303.1 15.3999H304.2C305.3 15.5999 305.8 16.2999 305.8 17.1999V48.3998H319.1V32.9999H319.2L327.6 48.3998H340.7V13.0999H327.4V30.4999H327.2L318.8 13.0999H303V15.3999H303.1Z"
                    fill="white"/>
                <path
                    d="M375.1 23.3999C374.8 14.1999 367.8 12.3999 359.6 12.3999C351.4 12.3999 345.2 15.8999 345.2 23.0999C345.2 30.2999 349.2 32.2999 357.8 36.0999C360.7 37.3999 362.7 38.0999 362.7 39.9999C362.7 41.8999 361.6 42.0999 360 42.0999C357.6 42.0999 357.1 40.4999 357 38.0999H344.8C344.5 48.4999 352.1 49.1999 360.5 49.1999C368.9 49.1999 375.9 45.4999 375.9 38.5999C375.9 31.6999 368.8 28.2999 362.1 25.4999C359.3 24.2999 358 23.5999 358 21.5999C358 19.5999 359 19.6999 360.2 19.6999C362.5 19.6999 363 21.2999 363 23.3999H375H375.1Z"
                    fill="white"/>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M140.1 25.6C140.1 17.5 137.3 12.2 123.9 12.2C110.5 12.2 107.3 16.4 107.3 30.8C107.3 44.1 110 49.3999 123.9 49.3999C137.8 49.3999 140.1 43.1 140.1 35.3H126.8V39.1C126.8 40.9 125.9 42 123.9 42C121.9 42 121 40.8 121 39.1V22.3C121 20.5 121.9 19.4 123.9 19.4C125.9 19.4 126.8 20.6 126.8 22.3V25.5H140.1V25.6Z"
                      fill="white"/>
                <path
                    d="M2.7 48.3V17.8C2.7 16.8 2.2 16.2 1.6 15.9C1.3 15.7 0.9 15.6 0.6 15.5C0.4 15.5 0.2 15.5 0 15.4V13.2H21.4C31.5 13.2 34.5 16.1 34.5 21.6C34.5 27.1 31.1 29.4 25.9 30.3C31.4 31 35.2 33.2 35.2 39.1C35.2 45 32.7 48.1 23.8 48.1H2.7V48.3ZM15.8 34V41.1H18.5C20.5 41.1 21.5 39.2 21.5 37.1C21.5 35 20.4 34.1 18.7 34.1H15.8V34ZM15.8 20.5V27H18.5C20.2 27 21.2 25.5 21.2 23.8C21.2 22.1 20.2 20.6 18.5 20.6H15.8V20.5Z"
                    fill="white"/>
                <path
                    d="M40.6999 48.3V18.1C40.6999 17 40.1999 16.3 39.4999 15.9C39.1999 15.7 38.7999 15.6 38.4999 15.5C38.2999 15.5 38.0999 15.5 37.8999 15.4V13.2H54.1999C54.1999 13.1 54.1999 39.8 54.1999 39.8H65.7999V48.2H40.6999V48.3Z"
                    fill="white"/>
                <path
                    d="M87.4 20.5L84.7 36.1H90L87.4 20.5ZM68.7 48.3L76.8 17.9C77 16.8 76.8 16.1 76.2 15.8C75.9 15.6 75.7 15.6 75.4 15.5C75.2 15.5 75 15.5 74.8 15.4V13.2H96.6L105.8 48.3H92L91 42.8H83.6L82.6 48.3H68.7Z"
                    fill="white"/>
                <path
                    d="M142.4 13.0999H158.7V22.7999L168.3 13.0999H184.1L170.5 26.4999L183.5 48.1999H169.2L161.6 35.1999L158.7 38.0999V48.1999H145.2V17.9999C145.2 16.8999 144.7 16.1999 144 15.7999C143.7 15.5999 143.3 15.4999 143 15.3999C142.8 15.3999 142.6 15.3999 142.4 15.2999V13.0999Z"
                    fill="white"/>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M380.9 8.1001H381.6L383.4 11.4001H385.2L383.2 8.0001C384.6 8.0001 385.4 7.4001 385.4 5.9001C385.4 4.4001 384.2 3.5001 382.1 3.5001H379.1V11.4001H380.9V8.1001ZM380.9 6.7001V4.9001H382C382.6 4.9001 383.4 4.9001 383.4 5.7001C383.4 6.5001 383 6.7001 382.4 6.7001H380.9ZM382.1 14.7001C386 14.7001 389.7 12.1001 389.7 7.4001C389.7 2.7001 386 0.100098 382.1 0.100098C378.2 0.100098 374.5 2.7001 374.5 7.4001C374.5 12.1001 378.1 14.7001 382.1 14.7001ZM387.2 7.4001C387.2 10.7001 384.9 12.9001 382.1 12.9001C379.3 12.9001 377 10.7001 377 7.4001C377 4.1001 379.3 1.9001 382.1 1.9001C384.9 1.9001 387.2 4.1001 387.2 7.4001Z"
                      fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_164_22903">
                    <rect width="390" height="49" fill="white"/>
                </clipPath>
            </defs>
        </svg>

    )
}