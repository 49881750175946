import React from "react";
import Points from "./Points";
import Rivalry from "./Rivalry";
import Results from "./Results";
import WinRate from "./WinRate";

export const summaryColumns = [
        {
            accessorFn: (row) => [`${row.team}`, `${row.opponent}`],
            id: 'team',
            header: 'Team',
            mantineTableBodyCellProps: {
                className: 'px-0 py-0',
            },
            minSize: 200,
            size: 200,
            Cell: ({renderedCellValue, row}) => (
                <Rivalry renderedCellValue={renderedCellValue} />

            ),
        },
        {
            accessorKey: 'tests',
            header: 'Test matches',
            size: 150,
            mantineTableHeadCellProps: {
                className: 'pl-8 bg-transparent',
            },
            mantineTableBodyCellProps: {
                className: 'pl-8',
            },
        },
        {
            accessorFn: (row) => [`${row.wins}`, `${row.draws}`, `${row.losses}`],
            id: 'results',
            header: 'Results',
            size: 180,
            Cell: ({renderedCellValue, row}) => (
                <Results renderedCellValue={renderedCellValue} />
            )
        },
        {
            accessorFn: (row) => [`${row.pointsFor}`, `${row.pointsAgainst}`],
            id: 'points',
            header: 'Points',
            size: 180,
            Cell: ({renderedCellValue, row}) => (
                <Points renderedCellValue={renderedCellValue} />
            )
        },
        {
            accessorKey: 'winRate',
            header: 'Win rate',
            size: 180,
            Cell: ({renderedCellValue, row}) => (
                <WinRate renderedCellValue={renderedCellValue} />
            ),
        }
    ];