import React from 'react'
import {
    MantineReactTable, useMantineReactTable
} from "mantine-react-table";
import TeamToggle from "../shared/TeamToggle";

function BaseTable({ title, data, columns, pinnedColumn, selectedTeams, setSelectedTeams=null }) {

    const table = useMantineReactTable({
        columns,
        data,
        enableColumnPinning: true,
        enableColumnActions: false,
        enableFilterMatchHighlighting: false,
        initialState: {
            columnPinning: {
                left: [pinnedColumn],
            },
            density: 'xs',
            showGlobalFilter: true,
        },

        // Only render the rows in view
        enablePagination: false,
        enableRowVirtualization: true,
        rowVirtualizerOptions: {
            overscan: 8,
            gap: 12,
        },

        enableStickyHeader: true,
        enableTopToolbar: false,
        enableBottomToolbar: false,
        enableDensityToggle: false,
        mantineTableHeadProps: {
            className: 'bg-transparent max-710:bg-black-100 backdrop-blur-md rounded-xl text-md',
        },
        mantineTableHeadRowProps: {
            className: 'bg-transparent rounded-xl mb-3',
        },
        mantineTableHeadCellProps: {
            align: 'left',
            className: 'px-2 py-2 bg-transparent',
        },
        mantineTableContainerProps: {
            className: 'bg-transparent',
            style: {
                maxHeight: '1000px',
            },
        },
        mantineTableBodyProps: {
            className: 'bg-transparent',
        },
        mantineTableBodyRowProps: ({ row }) => ({
            style: {
                clipPath: 'inset(0% round 12px)',
                cursor: 'pointer',
            },
            onClick: (event) => {
                window.location = row.original.pageLink;
            },
        }),
        mantineTableBodyCellProps: {
            align: 'left',
            className: 'block !truncate place-content-center px-2 py-0 lg:h-[94px]',
        },
        enableSorting: false,
        withTableBorder: false,
        mantinePaperProps: {
            className: 'bg-transparent'
        },
        mantineTableProps: {
            highlightOnHover: true,
            withColumnBorders: false,
            withRowBorders: false,
            withTableBorder: false,
            className: 'bg-transparent',
            style: {
                borderCollapse: 'separate',
                borderSpacing: '0 12px',
            }
        }
    });

    return (
        <div className="md:mb-16 max-w-[1500px] mx-4 md:mx-8 lg:mx-16 2xl:mx-auto lg:custom-block">
            <div className="flex flex-col sm:flex-row justify-between items-center mb-6 sm:mb-10">
                <h3 className='text-[28px] mb-6 sm:mb-0'>{title}</h3>
                {setSelectedTeams && (
                    <TeamToggle selectedTeams={selectedTeams} setSelectedTeams={setSelectedTeams}/>
                )}
            </div>
            <div className="bg-transparent h-full overflow-hidden max-h-[90vh] lg:max-h-[1000px]">
                <MantineReactTable table={table}/>
            </div>
            <div className="w-full h-48 relative -top-48 bg-gradient-to-t from-black-100 pointer-events-none"></div>
        </div>
    );
}

export default BaseTable;