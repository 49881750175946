import {Button, FloatingIndicator, Menu, Tabs} from "@mantine/core";
import React, {useState} from "react";
import {IconFilter} from "../SVGs/IconFilter";
import {MultiSelectCheckbox} from "./shared/MultiSelect";
import {FILTER_LOCATION_TYPE, FILTER_MATCH_TYPE, FILTER_RESULTS, MATCH_SORT_OPTIONS} from "../../constants";
import {RadioSelect} from "./shared/RadioSelect";
import {YearSelect} from "./shared/YearSelect";
import {IconChevron} from "../SVGs/IconChevron";

function TableTabs({ tabOptions, tabDisplayNames, setCurrentColumns }) {
    const [rootRef, setRootRef] = useState(null);
    const [value, setValue] = useState(Object.values(tabOptions)[0]);
    const [controlsRefs, setControlsRefs] = useState({});

    const setControlRef = (val) => (node) => {
        controlsRefs[val] = node;
        setControlsRefs(controlsRefs);
    };

    const setActiveTab = (val) => {
        setValue(val)
        setCurrentColumns(val);
    }

    // For Mobile
    const [opened, setOpened] = useState(false);

    return (
        <>
            {/* MOBILE */}
            <Menu className='block lg:hidden' opened={opened} onChange={setOpened} shadow="md" width={160}>
                <Menu.Target>
                    <div className='button px-2 min-w-[138px] capitalize text-sm lg:text-base flex items-center justify-center'>
                        {value}
                        <IconChevron classname={`${opened ? '' : 'rotate-180'} !px-0`} colour={'white'} />
                    </div>
                </Menu.Target>
                <Menu.Dropdown className="p-2 bg-darkGrey rounded-lg">
                    {Object.entries(tabOptions).map(([key, val]) => (
                        <div onClick={() => setActiveTab(val)}
                             className={`button ${val === value ? '' : 'button--tertiary'} text-base leading-7 m-1.5 min-w-32 z-10 px-4`}
                             key={`Tab-${key}`}>
                            {tabDisplayNames[key]}
                        </div>
                    ))}
                </Menu.Dropdown>
            </Menu>
            {/* DESKTOP */}
            <Tabs className='hidden lg:block' variant="none" value={value} onChange={setActiveTab}>
                <Tabs.List ref={setRootRef} className='relative'>
                    {Object.entries(tabOptions).map(([key, value]) => (
                        <Tabs.Tab value={value} ref={setControlRef(value)} classNames={{tab: 'button button--tertiary tab text-base mx-1.5 min-w-32 px-0 py-0 z-10', tabLabel: 'px-4'}} key={`Tab-${key}`}>
                            { tabDisplayNames[key] }
                        </Tabs.Tab>
                    )) }

                    <FloatingIndicator
                        target={value ? controlsRefs[value] : null}
                        parent={rootRef}
                        className='bg-purple-50 rounded-xl shadow-inner-selected z-0'
                    />
                </Tabs.List>
            </Tabs>
        </>
    )
}

export default TableTabs;