import React, { useEffect } from 'react';

function MobileNav({ isMenuOpen, links }) {
    const header = document.querySelector('header');

    useEffect(() => {
        if (isMenuOpen) {
            document.documentElement.classList.add('mobile-nav--visible');
            header?.classList.add('fixed','w-full','h-screen','overflow-y-scroll','invisible-scrollbars');
        } else {
            document.documentElement.classList.remove('mobile-nav--visible');
            header?.classList.remove('fixed','w-full','h-screen','overflow-y-scroll','invisible-scrollbars');
        }
    }, [isMenuOpen]);

    return (
      <>
          {isMenuOpen && (
            <div className="mobile-nav md:hidden h-screen w-full inset-0 bg-black-100 text-white flex flex-col items-center pt-28">
                <nav className="flex flex-col text-center">
                    <a href="/" className="text-grey-1 font-display hover:underline text-3xl mb-9">Home</a>
                    { links.map((item) => (
                        <>
                            <div className="menu-divider"></div>
                            <a key={item.key} href={item.href} className="text-grey-1 font-display hover:underline text-3xl my-9">{item.title}</a>
                        </>
                    ))}
                    {/* @TODO: add correct arrow icon below - Simon */}
                    <a href="https://allblacks.com"
                       className="text-grey-1 font-medium hover:underline uppercase my-9">&larr; Back to
                        allblacks.com</a>
                </nav>
            </div>
          )}
      </>
    )
}

export default MobileNav