import React from 'react'
import {createRoot} from "react-dom/client";
import {MantineProvider} from "@mantine/core";
import MatchTable from "./tables/MatchTable";
import RivalryStatsBlock from "./rivalry_stats_block/RivalryStatsBlock";
import MatchCenterCTA from "./match_center_cta/MatchCenterCTA";
import RivalryPageMainBlock from "./rivalry_page_main_block/RivalryPageMainBlock";
import RivalryTopScorers from "./rivalry_top_scorers/RivalryTopScorers";
import RivalryContentBlock from "./rivalry_content_block/RivalryContentBlock";
import RivalryHero from "./rivalry_hero/RivalryHero";
import {removeLoader} from "../utils/removeLoader";

function RivalryPage({data, allRivalsLink}) {
    const { summaryData, rivalryData, stats, tests, topScorers} = data
    return (
        <div className="content-container mt-32 relative z-20">
            <RivalryHero data={summaryData} allRivalsLink={allRivalsLink} />
            <RivalryStatsBlock data={stats}/>
            {/*<RivalryContentBlock*/}
            {/*    imgSrc={imgSrc}*/}
            {/*    heading={heading}*/}
            {/*    content={content}*/}
            {/*    imagePosition={imagePosition}*/}
            {/*/>*/}
            <RivalryPageMainBlock data={rivalryData} />
            <RivalryTopScorers data={topScorers} opposition={rivalryData.country} />
            <MatchTable allMatches={tests}/>
            <MatchCenterCTA allRivalsLink={allRivalsLink} />
        </div>

    )
}

export default function init(mountNode, theme) {
    const dataNode = document.getElementById('RivalryPageData');
    const pageData = JSON.parse(dataNode.textContent);

    removeLoader();
    if (mountNode) {
        createRoot(mountNode).render(
            <MantineProvider theme={theme} forceColorScheme={'dark'}>
                <RivalryPage data={pageData.rivalry[0]} allRivalsLink={pageData.allRivalsLink} />
            </MantineProvider>
        )
    }
}
