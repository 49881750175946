import React from 'react'
import {IconRain} from "../SVGs/IconRain";
import {IconMapPin} from "../SVGs/IconMapPin";
import {IconFootprints} from "../SVGs/IconFootprints";
import {IconMoon} from "../SVGs/IconMoon";
import {IconSun} from "../SVGs/IconSun";
import {IconArrowLeft} from "../SVGs/IconArrowLeft";

function MatchPageHeader({match, allMatchesLink}) {
  return (
      <>
          <div className="max-w-screen-xl grid grid-cols-6 lg:grid-cols-12 gap-4 mx-auto px-4 custom-block">
            {/* @TODO: add link to back button */}
            <button
                className="button--icon button--secondary"
                onClick={() => window.location.href = allMatchesLink}
            >
            <IconArrowLeft/>
            </button>
              <div className="col-span-6 lg:col-span-12 flex flex-col items-center relative">
                  {match.test === 'Test' && (
                      <div
                          className="uppercase flex items-center bg-white-1 text-2xs rounded px-4 text-black-100 font-bold mb-6">
                          Test Match
                      </div>
                  )}
                  <h1 className="h2 mb-12 text-center">{match.title}</h1>
                  <span className="block">{match.date}</span>
                  <span className="block text-grey-10 mb-12">{match.location}</span>
                  <ul className="grid grid-cols-2 gap-x-4 gap-y-2 justify-center text-center md:flex md:gap-8 md:text-left text-grey-10">
                      {match.weather && (
                          <li className="flex items-center justify-center">
                              {match.weather.toLowerCase().includes('rain') || match.weather.toLowerCase().includes('wet') ? (
                                  <IconRain width={24} height={25}/>
                              ) : match.weather.toLowerCase().includes('fine') || match.weather.toLowerCase().includes('clear') ? (
                                  <IconSun width={24} height={25}/>
                              ) : null}
                              <span className="ml-1 inline-block">{match.weather}</span>
                          </li>
                      )}
                      {match.ground && (
                          <li className="flex items-center justify-center">
                              <IconFootprints width={24} height={25}/> <span
                              className="ml-1 inline-block">{match.ground}</span>
                          </li>
                      )}
                      {match.stadium && (
                          <li className="flex items-center justify-center">
                              <IconMapPin width={24} height={25}/> <span
                              className="ml-1 inline-block">{match.stadium}</span>
                          </li>
                      )}
                      <li className="flex items-center justify-center">
                          {match.night === 1 ? (
                              <>
                                  <IconMoon width={24} height={25}/>
                                  <span className="ml-1 inline-block">Night</span>
                              </>
                          ) : (
                              <>
                                  <IconSun width={24} height={25}/>
                                  <span className="ml-1 inline-block">Day</span>
                              </>
                          )}
                      </li>
                  </ul>
              </div>
          </div>
      </>
  )
}

export default MatchPageHeader
