import React from 'react'
import { IconArrowsRandomise } from '../SVGs/IconArrowsRandomise';

function PlayerCTA({nextPlayer, randomPlayer}) {

  const imageSrc = '/assets/images/blurred-player-cards-static-2.png';

  return (
      <>
          <div className="max-w-screen-xl grid grid-cols-6 lg:grid-cols-12 gap-4 mx-auto px-4 custom-block mt-6 md:mt-0 min-h-[200px]">
              <div className="col-span-6 lg:col-span-12 flex flex-col items-center relative">
                  <img
                      src={imageSrc}
                      className="w-full"
                      alt=""
                  />
                  <div className="text-center absolute left-0 top-[50%] -translate-y-[50%] right-0 z-1">
                      <h2>Who was next?</h2>
                      <p className="text-lg">Discover the next legend to wear the jersey.</p>
                      <div className="md:flex md:justify-center">
                          <a className="button block w-full md:w-auto md:inline-block mb-1 md:mb-0 md:mr-2" href={nextPlayer}>Next player</a>
                          <a className="button button--secondary flex items-center justify-center px-2" href={randomPlayer}>
                              <span className="whitespace-nowrap">Random player
                                  <IconArrowsRandomise
                                    width={24}
                                    height={25}
                                  />
                              </span>
                          </a>
                      </div>
                  </div>
              </div>
          </div>
      </>
  )
}

export default PlayerCTA
