import React from 'react'
import PlayersTable from "./tables/PlayerTable";
import {createRoot} from "react-dom/client";
import {MantineProvider} from "@mantine/core";
import CardCarousel from "./card_carousel/CardCarousel";
import {removeLoader} from "../utils/removeLoader";

function AllPlayers({allPlayers, title, carouselPlayers}) {
    return (
        <div className="content-container mt-64 relative z-20">
            <div className="z-10 mt-80 text-center custom-block">
                <h1 className="col-span-12 text-white-100 text-4xl md:text-6xl lg:text-[88px] leading-none text-center">{title}</h1>
            </div>
            <CardCarousel dataType="players" cardData={carouselPlayers}/>
            <PlayersTable allPlayers={allPlayers}/>
        </div>

    )
}

export default function init(mountNode, theme) {
    const dataNode = document.getElementById('AllPlayersPageData');
    const carouselNode = document.getElementById('AllPlayersCarouselData');
    const pageData = JSON.parse(dataNode.textContent);
    const carouselData = JSON.parse(carouselNode.textContent);

    removeLoader();
    if (mountNode) {
        createRoot(mountNode).render(
            <MantineProvider theme={theme} forceColorScheme={'dark'}>
                <AllPlayers allPlayers={pageData.allPlayers} title={pageData.title} carouselPlayers={carouselData.carouselPlayers}/>
            </MantineProvider>
        )
    }
}
