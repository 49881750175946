import React, { useEffect, useRef } from 'react';
import { IconArrowDown } from "../SVGs/IconArrowDown";
import {IconArrowLeft} from "../SVGs/IconArrowLeft";
import Opponent from "../shared/Opponent";

function RivalryHero({ data, allRivalsLink }) {
    const { country, gender, teamName, against, tests, winRate, points, tries } = data
    const cardRef = useRef(null); // Ref for the team card
    const shineRef = useRef(null); // Ref for the shine effect
    const statsColRef = useRef(null); // Ref for the stats column
    const isVerySmallScreen = window.innerWidth < 640;
    const isSafari = () => {
        return (
            /^((?!chrome|android).)*safari/i.test(navigator.userAgent) ||
            navigator.userAgent.includes("Safari") && !navigator.userAgent.includes("Chrome")
        );
    };

    let lastRotation = 0;

   useEffect(() => {
       if (isSafari()) {
            return; // Do not add the scroll event listener for Safari browsers as effect is buggy
        }

        let lastScrollY = window.scrollY;
        let currentRotation = 0;

        const handleScroll = () => {
            if (cardRef.current) {
                const rect = cardRef.current.getBoundingClientRect();
                const viewportHeight = window.innerHeight;

                // Define positions
                const elementBottom = rect.top + rect.height;
                const viewportTop = 0;

                let rotation = lastRotation;

                if (elementBottom > viewportTop && elementBottom < viewportHeight) {
                    const maxRotation = 20; // Increased rotation angle
                    const distanceFromCenter = Math.min(viewportHeight - elementBottom, viewportHeight);

                    rotation = (distanceFromCenter / viewportHeight) * maxRotation;
                }

                else if (rect.top < viewportTop) {
                    const maxRotation = 20;
                    const distanceFromCenter = Math.min(-rect.top, viewportHeight);

                    rotation = (distanceFromCenter / viewportHeight) * maxRotation;
                }

                if (Math.abs(window.scrollY - lastScrollY) > 1) {
                    currentRotation = rotation;
                    cardRef.current.style.transform = `perspective(1000px) rotateX(${currentRotation}deg)`;
                    lastRotation = currentRotation;
                }
                lastScrollY = window.scrollY;
            }

            if (shineRef.current) {
                const shineOffset = (window.scrollY - lastScrollY) * 0.1; // Increased effect intensity
                shineRef.current.style.transform = `translateY(${shineOffset}px)`;
            }

            if (statsColRef.current) {
                const statsOffset = (window.scrollY - lastScrollY) * 0.2; // Increased movement intensity
                statsColRef.current.style.transform = `translateY(${statsOffset}px)`;
            }
        };

        handleScroll();

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <>
            <div className="max-w-screen-xl grid grid-cols-6 lg:grid-cols-12 gap-1 sm:gap-4 mx-auto px-4 custom-block">
                <button
                   className="button--icon button--secondary"
                   onClick={() => window.location.href = allRivalsLink}
                >
                    <IconArrowLeft width={isVerySmallScreen ? 20 : 32} height={isVerySmallScreen ? 20 : 32} />
                </button>
                <div className="grid col-span-6 lg:col-span-12 mx-auto">
                    <div className="col-start-2 col-span-4 lg:col-start-5 relative text-center flex flex-col items-center">
                        <div ref={cardRef}
                             className="relative player-photo card--team z-10 w-[267px] sm:w-[400px] h-[390px] sm:h-[516px] mb-4 md:mb-2 will-change-transform transition-transform duration-300 ease">
                            <Opponent size={'fullCard'} opponent={country} teamName={teamName}
                                      className="w-[267px] sm:w-[400px] h-[390px] sm:h-[516px] rounded-lg p-8"/>
                            <div
                                ref={shineRef}
                                className="hidden md:block absolute top-0 left-0 w-full h-full bg-gradient-to-r from-transparent via-white/30 to-transparent"
                            ></div>
                        </div>

                        <div className="md:mt-12 lg:mt-0 lg:absolute lg:left-[85%] lg:top-[43%] text-left z-20">
                            <span className="h6 block mb-2">{gender}</span>
                            <h1 className="2xl:inline md:mb-1">{country}</h1>
                            <span className="h4 text-grey-1 text-lg md:text-2xl block">{teamName}</span>
                        </div>

                        <div ref={statsColRef}
                             className={`stats-col z-20 flex flex-col mt-4 md:mt-0 md:absolute md:h-full ${country.length > 9 ? 'md:left-0 ' : 'md:-left-[120px] '} lg:-left-[120px] md:top-[50px] md:max-w-[193px]`}>
                            <div className="rounded-lg overflow-hidden">
                                <div className="transparent-gradient-border bg-black-10/50 text-left py-5 px-8">
                                    <h6 className="mb-2 md:mb-5">Results against {against}</h6>
                                    <ul className="flex md:flex-col justify-between items-start">
                                        <li className="md:mb-5">
                                            <span
                                                className="h4 md:body block mb-2 md:mb-0 font-normal text-grey-1 opacity-60">Tests</span>
                                            <div className="flex items-baseline">
                                                <span className="h4 text-[18px] md:text-lg mr-1">{tests}</span>
                                            </div>
                                        </li>
                                        <li className="md:mb-5">
                                            <span
                                                className="h4 md:body block mb-2 md:mb-0 font-normal text-grey-1 opacity-60">Win rate</span>
                                            <div className="flex items-baseline">
                                                <span className="h4 text-[18px] md:text-lg mr-1">{winRate}</span>
                                                <span className="h6 text-grey-1 opacity-60">%</span>
                                            </div>
                                        </li>
                                        <li className="md:mb-5">
                                            <span
                                                className="h4 md:body block mb-2 md:mb-0 font-normal text-grey-1 opacity-60">Points</span>
                                            <span className="h4 text-[18px] md:text-lg">{points}</span>
                                        </li>
                                        <li className="md:mb-5">
                                            <span
                                                className="h4 md:body block mb-2 md:mb-0 font-normal text-grey-1 opacity-60">Tries</span>
                                            <span className="h4 text-[18px] md:text-lg">{tries}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*<div className="hidden lg:block max-w-screen-xl grid grid-cols-12 gap-4 mx-auto mb-44">*/}
                {/*<div className="col-span-12 flex flex-col items-center">*/}
                {/*    <h6 className="text-white-1">Learn More</h6>*/}
                {/*    <span className="table-row-name block mb-6">About the rivalry</span>*/}
                {/*    <button className="button--icon">*/}
                {/*        <IconArrowDown width={33} height={32}/>*/}
                {/*    </button>*/}
                {/*</div>*/}
            {/*</div>*/}
        </>
    )
}

export default RivalryHero
