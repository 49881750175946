import React from 'react'
import MatchTable from "./tables/MatchTable";
import {createRoot} from "react-dom/client";
import {MantineProvider} from "@mantine/core";
import StatsBlock from "./stats_block/StatsBlock";
import LatestMatches from "./latest_matches/LatestMatches";
import MatchCenterCTA from "./match_center_cta/MatchCenterCTA";
import {removeLoader} from "../utils/removeLoader";

function AllMatches({allMatches, title, latestMatches, stats, allRivalsLink}) {
    return (
        <div className="content-container mt-64 relative z-20">
            <div className="z-10 mt-80 mb-28 text-center">
                <h1 className="col-span-12 text-white text-4xl md:text-6xl lg:text-[88px] uppercase leading-none text-center">{title}</h1>
            </div>
            <StatsBlock data={stats} />
            <LatestMatches matches={latestMatches} />
            <MatchTable allMatches={ allMatches } withFilters />
            <MatchCenterCTA allRivalsLink={allRivalsLink} />
        </div>
    )
}

export default function init(mountNode, theme) {
    const dataNode = document.getElementById('AllMatchesPageData');
    const pageData = JSON.parse(dataNode.textContent);

    removeLoader()
    if (mountNode) {
        createRoot(mountNode).render(
            <MantineProvider theme={theme} forceColorScheme={'dark'}>
                <AllMatches allMatches={pageData.allMatches} title={pageData.title} latestMatches={pageData.latestMatches} stats={pageData.stats} allRivalsLink={pageData.allRivalsLink}/>
            </MantineProvider>
        )
    }
}
