import React from "react";
import {Box} from "@mantine/core";

function Height({ renderedCellValue }) {

    return (
        <Box className='flex items-center'>
            { renderedCellValue ? (
                <>
                    <span>{renderedCellValue}</span>
                    <span className='ml-1 text-xs text-grey-10'>CM</span>
                </>
            ) : <span>Unknown</span>}
        </Box>
    )
}

export default Height;
