import React from "react";
import { Menu } from "@mantine/core";
import { MultiSelectCheckbox } from "../shared/MultiSelect";
import { IconFilter } from "../../SVGs/IconFilter";
import {RadioSelect} from "../shared/RadioSelect";
import {
    EARLIEST_YEAR,
    FILTER_POSITIONS,
    LATEST_YEAR,
    PLAYER_SORT_OPTIONS, REGION_OPTIONS
} from "../../../constants";
import {YearSelect} from "../shared/YearSelect";
import {SectionedMultiSelectCheckbox} from "../shared/SectionedMultiSelect";

function PlayersFilter({
       selectedPositions,
       setSelectedPositions,
       selectedRegions,
       setSelectedRegions,
       statusOptions,
       selectedStatus,
       setSelectedStatus,
       selectedDates,
       setSelectedDates,
       selectedSort,
       setSelectedSort
    }) {
    const handleReset = () => {

        setSelectedPositions([]);
        setSelectedStatus(null);
        setSelectedRegions([]);
        setSelectedDates([EARLIEST_YEAR, LATEST_YEAR]);
        setSelectedSort('Alphabetical (A - Z)');
    }

    return (
        <Menu shadow="md" middlewares={{ flip: false, shift: true }}>
            <Menu.Target>
                <button className='button button--tertiary min-w-[100px] sm:min-w-auto px-2 sm:px-6 text-sm lg:text-base'>
                    <span className='!px-0'>Filter<span className='!hidden md:!inline-block !pl-1 !pr-0'> & sort</span><IconFilter /></span>
                </button>
            </Menu.Target>
            <Menu.Dropdown className="p-4 sm:p-8 bg-darkGrey rounded-xl" >
                <div className="flex justify-between mb-3">
                    <h5>Filter by</h5>
                    <a className='underline text-purple-10 cursor-pointer' onClick={() => handleReset()}>Reset</a>
                </div>
                <MultiSelectCheckbox label='Position' selectOptions={FILTER_POSITIONS} value={selectedPositions} setValue={setSelectedPositions} classNames={'mb-2 min-w-72 sm:min-w-96'} />
                <SectionedMultiSelectCheckbox label='Birth region' options={REGION_OPTIONS} value={selectedRegions} setValue={setSelectedRegions} classNames={'mb-2'} />
                <RadioSelect label='Playing Status' selectOptions={statusOptions} value={selectedStatus} setValue={setSelectedStatus} clearable classNames={'mb-2'} />
                <YearSelect label='Debut' value={selectedDates} setValue={setSelectedDates} />
                <Menu.Label className={'mt-10 mb-3'}>
                    <h5>Sort by</h5>
                </Menu.Label>
                <RadioSelect label='Order' selectOptions={PLAYER_SORT_OPTIONS} value={selectedSort} setValue={setSelectedSort} />
            </Menu.Dropdown>
        </Menu>
    )
}

export default PlayersFilter;
