import React from "react";
import {Box} from "@mantine/core";
import Opponent from "../../shared/Opponent";
import NZTeam from "../../shared/NZTeam";

function Rivalry({ renderedCellValue }) {
    const [nzTeam, oppTeam] = renderedCellValue;

    return (
        <Box className='w-full gradient-border--nested'>
            <div className="flex items-center justify-between w-full rounded-lg !py-1.5 !px-2 !bg-black-50">
                <NZTeam nzTeam={nzTeam} size='box'/>
                <div className='flex items-center px-1 md:px-2 lg:px-3'>
                    <span className="text-md md:text-xl lg:text-2xl text-grey-10">vs</span>
                </div>
                <Opponent opponent={oppTeam} size='box'/>
            </div>
        </Box>
    )
}

export default Rivalry;
