import React from 'react'

export const IconMoon = ({ width, height }) => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width ? width : "24"} height={height ? height : "25"} fill="none">
            <path fill="currentColor"
                d="M21.895 13.651a.75.75 0 0 0-.75-.187A8.258 8.258 0 0 1 10.85 3.17a.75.75 0 0 0-.937-.937A9.829 9.829 0 0 0 4.954 5.7a9.75 9.75 0 0 0 7.796 15.616 9.664 9.664 0 0 0 5.862-1.957 9.828 9.828 0 0 0 3.468-4.96.75.75 0 0 0-.185-.749Zm-4.185 4.51A8.25 8.25 0 0 1 6.156 6.61 8.344 8.344 0 0 1 9.1 4.17a9.76 9.76 0 0 0 11.05 11.047 8.343 8.343 0 0 1-2.44 2.944Z"/>
        </svg>
    )
}