import React from 'react'

export const CardNoLogo = ({teamName, country, textColorClass}) => {

    return (
        <div className='flex flex-col justify-center items-center h-full min-h-[200px]'>
            <div className={textColorClass === 'text-black-100' ? 'no-logo-line-black' : 'no-logo-line'}/>
            <div className="flex flex-col my-8 text-center">
                {teamName && <span className={`h4 text-center ${textColorClass}`}>{teamName}</span>}
                <span className={`card__display-name text-center ${textColorClass}`}>{country}</span>
            </div>
            <div className={textColorClass === 'text-black-100' ? 'no-logo-line-black' : 'no-logo-line'}/>
        </div>
    )
}