import React from "react";
import {AllBlacksLogo} from "../SVGs/NZLogos/AllBlacksLogo";
import {BlackFernsLogo} from "../SVGs/NZLogos/BlackFernsLogo";
import {AllBlacksWordMark} from "../SVGs/NZLogos/AllBlacksWordMark";
import {BlackFernsWordMark} from "../SVGs/NZLogos/BlackFernsWordMark";

function NZTeam({ nzTeam, size, className = '' }) {
    const background = teamBackgroundColors[nzTeam] || '#333';
    const Logo = teamLogos[nzTeam] || null;

    if (size === 'box') {
        return (
            <div className={`flex justify-center items-center w-10 md:w-16 lg:w-20 h-10 md:h-16 lg:h-20 rounded-md ${nzTeam === 'All Blacks' ? 'logo-container--ab' : 'logo-container--bf'} ${className}`}
                style={nzTeam !== 'All Blacks' ? { background } : {}}
            >
                <div className="flex justify-center items-center logo-container--inner pl-1 md:p-2 rounded-md">
                    <Logo width={70} height={70}/>
                </div>
            </div>
        )
    }
    if (size === 'statsIconDouble') {
        return (
            <div
                className={`double bg-gray-800 px-2 py-4 mb-3 max-h-[45px] w-[90px] overflow-visible ${nzTeam === 'All Blacks' ? 'logo-container--ab' : 'logo-container--bf'}`}
            >
                <div
                    className={`logo-container--inner px-2 py-4 h-[45px]`}>
                    {nzTeam === 'All Blacks' ? (
                        <AllBlacksWordMark width={74} height={11}/>
                    ) : (
                        <BlackFernsWordMark width={74} height={11}/>
                    )}
                </div>
            </div>
        )
    }
    if (size === 'statsIconSingle') {
        return (
            <div
                className={`single bg-gray-800 px-2 py-4 mb-3 max-h-[45px] w-[90px] overflow-visible rounded ${nzTeam === 'All Blacks' ? 'logo-container--ab' : 'logo-container--bf'}`}
            >
                <div
                    className={`logo-container--inner px-2 py-4 h-[45px] rounded`}>
                    {nzTeam === 'All Blacks' ? (
                        <AllBlacksWordMark width={74} height={11}/>
                    ) : (
                        <BlackFernsWordMark width={74} height={11}/>
                    )}
                </div>
            </div>
        )
    }
    if (size === 'playerImage') {
        return (
            <div className="object-cover w-[77px] h-[128px] rounded-l-xl hidden md:flex items-center justify-center"
                 style={{background}}>
                <Logo width={50} height={50}/>
            </div>
        )
    }
}

export default NZTeam;


export const teamBackgroundColors = {
    "All Blacks": "#000000",
    "Black Ferns": "linear-gradient(338deg, #222 35.5%, #555 90.04%)"
};


export const teamLogos = {
    "All Blacks": AllBlacksLogo,
    "Black Ferns": BlackFernsLogo
};
