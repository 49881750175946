import React from "react";
import {Box, Image} from "@mantine/core";
import {BlackFernsLogo} from "../../SVGs/NZLogos/BlackFernsLogo";
import {AllBlacksLogo} from "../../SVGs/NZLogos/AllBlacksLogo";
import {BorderGradient} from "../../SVGs/BorderGradient";

function MatchCount({ row, renderedCellValue }) {

    return (
        <Box className='flex items-center'>
            <div className='flex flex-col'>
                <span>{renderedCellValue[0]} total</span>
                <span className='text-xs text-grey-10'>{renderedCellValue[1]} TESTS</span>
            </div>
        </Box>
    )
}

export default MatchCount;
