import React from 'react'

export const AllBlacksWordMark = ({width, height}) => {

    return (
        <svg width={ width ? width : "351" } height={ height ? height : "52" } viewBox="0 0 351 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_164_22892)">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M341.8 8.2999H342.5L344.3 11.5999H346.1L344.1 8.19992C345.5 8.19992 346.3 7.59991 346.3 6.09991C346.3 4.59991 345.1 3.69992 343 3.69992H340V11.5999H341.8V8.2999ZM341.8 6.8999V5.09991H342.9C343.5 5.09991 344.3 5.0999 344.3 5.8999C344.3 6.6999 343.9 6.8999 343.3 6.8999H341.8ZM343 14.9999C346.9 14.9999 350.6 12.3999 350.6 7.69992C350.6 2.99992 346.9 0.399902 343 0.399902C339.1 0.399902 335.4 2.99992 335.4 7.69992C335.4 12.3999 339 14.9999 343 14.9999ZM348.1 7.69992C348.1 10.9999 345.8 13.1999 343 13.1999C340.2 13.1999 337.9 10.9999 337.9 7.69992C337.9 4.39992 340.2 2.19992 343 2.19992C345.8 2.19992 348.1 4.39992 348.1 7.69992Z"
                      fill="white"/>
                <path
                    d="M258.2 28.2001C258.2 20.1001 255.4 14.8 242 14.8C228.6 14.8 225.4 19.0001 225.4 33.4001C225.4 46.7001 228.1 52.0001 242 52.0001C255.9 52.0001 258.2 45.7001 258.2 37.9001H244.9V41.7001C244.9 43.5001 244 44.7001 242 44.7001C240 44.7001 239.1 43.5001 239.1 41.7001V24.9001C239.1 23.1001 240 21.9001 242 21.9001C244 21.9001 244.9 23.1001 244.9 24.9001V28.1001H258.2V28.2001Z"
                    fill="white"/>
                <path
                    d="M120.8 50.9999V20.4999C120.8 19.4999 120.3 18.8999 119.7 18.5999C119.4 18.3999 119 18.2999 118.7 18.1999C118.5 18.1999 118.3 18.1999 118.1 18.0999V15.8999H139.5C149.6 15.8999 152.6 18.7999 152.6 24.2999C152.6 29.7999 149.2 32.0999 144 32.9999C149.5 33.6999 153.3 35.8999 153.3 41.8999C153.3 47.8999 150.8 50.8999 141.9 50.8999H120.8V50.9999ZM133.9 36.5999V43.6999H136.6C138.6 43.6999 139.6 41.7999 139.6 39.6999C139.6 37.5999 138.5 36.6999 136.8 36.6999H133.9V36.5999ZM133.9 23.1999V29.6999H136.6C138.3 29.6999 139.3 28.1999 139.3 26.4999C139.3 24.7999 138.3 23.2999 136.6 23.2999H133.9V23.1999Z"
                    fill="white"/>
                <path
                    d="M158.7 50.9999V20.7999C158.7 19.6999 158.2 19 157.5 18.6C157.2 18.4 156.8 18.3 156.5 18.2C156.3 18.2 156.1 18.2 155.9 18.1V15.8999H172.2C172.2 15.7999 172.2 42.4999 172.2 42.4999H183.8V50.8999H158.7V50.9999Z"
                    fill="white"/>
                <path
                    d="M47.8 50.9999V20.7999C47.8 19.6999 47.3 19 46.6 18.6C46.3 18.4 45.9 18.3 45.6 18.2C45.4 18.2 45.2 18.2 45 18.1V15.8999H61.3C61.3 15.7999 61.3 42.4999 61.3 42.4999H72.9V50.8999H47.8V50.9999Z"
                    fill="white"/>
                <path
                    d="M76.8 50.9999V20.7999C76.8 19.6999 76.3 19 75.6 18.6C75.3 18.4 74.9 18.3 74.6 18.2C74.4 18.2 74.2 18.2 74 18.1V15.8999H90.3C90.3 15.7999 90.3 42.4999 90.3 42.4999H101.9V50.8999H76.8V50.9999Z"
                    fill="white"/>
                <path
                    d="M205.5 23.0999L202.8 38.6999H208.1L205.5 23.0999ZM186.8 50.9999L194.9 20.5999C195.1 19.4999 194.9 18.7999 194.3 18.4999C194 18.2999 193.8 18.2999 193.5 18.1999C193.3 18.1999 193.1 18.1999 192.9 18.0999V15.8999H214.7L223.9 50.9999H210.1L209.1 45.4999H201.7L200.7 50.9999H186.8Z"
                    fill="white"/>
                <path
                    d="M25.3001 23.0999L22.6001 38.6999H27.9001L25.3001 23.0999ZM6.6001 50.9999L14.7001 20.5999C14.9001 19.4999 14.7001 18.7999 14.1001 18.4999C13.8001 18.2999 13.6001 18.2999 13.3001 18.1999C13.1001 18.1999 12.9001 18.1999 12.7001 18.0999V15.8999H34.5001L43.7001 50.9999H29.9001L28.9001 45.4999H21.5001L20.5001 50.9999H6.6001Z"
                    fill="white"/>
                <path
                    d="M260.4 15.7H276.7V25.3999L286.3 15.7H302.1L288.5 29.0999L301.5 50.7999H287.2L279.6 37.7999L276.7 40.7V50.7999H263.2V20.5999C263.2 19.4999 262.7 18.7999 262 18.3999C261.7 18.1999 261.3 18.0999 261 17.9999C260.8 17.9999 260.6 17.9999 260.4 17.8999V15.7Z"
                    fill="white"/>
                <path
                    d="M333.309 26C333.009 16.8 326.009 15 317.809 15C309.609 15 303.409 18.5 303.409 25.7C303.409 32.9 307.409 34.9 316.009 38.7C318.909 40 320.909 40.7 320.909 42.6C320.909 44.5 319.809 44.7 318.209 44.7C315.809 44.7 315.309 43.1 315.209 40.7H303.009C302.709 51.1 310.309 51.8 318.709 51.8C327.109 51.8 334.109 48.1 334.109 41.2C334.109 34.3 327.009 30.9 320.309 28.1C317.509 26.9 316.209 26.2 316.209 24.2C316.209 22.2 317.209 22.3 318.409 22.3C320.709 22.3 321.209 23.9 321.209 26H333.209H333.309Z"
                    fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_164_22892">
                    <rect width="351" height="52" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}