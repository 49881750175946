import React, { useEffect } from 'react';
import Glide from '@glidejs/glide';
import { AllBlacksWordMark } from '../SVGs/NZLogos/AllBlacksWordMark';
import { BlackFernsWordMark } from '../SVGs/NZLogos/BlackFernsWordMark';

function StatsBlock({ data }) {
  const stats = data.concat(data); // Duplicate stats for a continuous scroll effect

  useEffect(() => {
    const glide = new Glide('.glide--stats', {
      type: 'carousel',
      startAt: 0,
      gap: 15,
      perView: 5,
      peek: {
        before: 20,
        after: 20
      },
      autoplay: 4000,
      hoverpause: true,
      animationTimingFunc: 'linear',
      animationDuration: 1000,
      breakpoints: {
        600: {
          perView: 1,
          focusAt: 'center',
          gap: 15,
          peek: {
            before: 0,
            after: 0,
          },
        },
        400: {
          perView: 1,
          focusAt: 'center',
          gap: 15,
          peek: {
            before: 0,
            after: 0,
          },
        }
      },
    });
    glide.mount();

    return () => {
      glide.destroy();
    };
  }, []);

  return (
    <div className="glide glide--stats bg-black text-white w-full overflow-visible px-4 sm:px-6 custom-block">
      <div className="glide__track overflow-visible" data-glide-el="track">
        <ul className="glide__slides flex items-center overflow-visible">
          {stats.map((stat, index) => (
              <li key={index} className="glide__slide md:max-w-none max-399:min-w-[90vw] min-w-[360px] flex justify-center mr-[10px] overflow-visible">
                <div className="bg-black-50 p-5 rounded-lg text-center max-399:min-w-[90vw] min-w-[360px] min-h-[170px] relative overflow-visible flex flex-col justify-center items-center">
                  <div
                      className={`bg-gray-800 px-2 py-4 rounded mb-3 max-h-[45px] absolute top-[-23px] left-1/2 -translate-x-1/2 overflow-visible ${
                          stat.team === 'All Blacks' ? 'logo-container--ab' : 'logo-container--bf'
                      }`}
                  >
                    <div className="logo-container--inner rounded px-2 py-4 h-[45px]">
                      {stat.team === 'All Blacks' ? (
                          <AllBlacksWordMark width={74} height={11}/>
                      ) : stat.team === 'Black Ferns' ? (
                          <BlackFernsWordMark width={74} height={11}/>
                      ) : (
                          stat.team.toUpperCase()
                      )}
                    </div>
                  </div>
                  <div className="text-grey-1 opacity-60 text-2xl mb-1">{stat.stat}</div>
                  <div>
                    {stat.value.includes('%') ? (
                        <>
                          <span className="text-4xl text-white font-bold">{stat.value.replace('%', '')}</span>
                        <span className="text-grey-1 text-2xl">%</span>
                      </>
                    ) : (
                        <span className="text-4xl text-white font-bold">{parseInt(stat.value).toLocaleString()}</span>
                    )}
                  </div>
                  {stat.subtext && (
                      <div className="h6 text-grey-1 opacity-60">{stat.subtext}</div>
                  )}
                </div>
              </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default StatsBlock;
