import React from 'react';
import { Checkbox, Combobox, Group, Input, useCombobox } from '@mantine/core';
import { IconChevron } from "../../SVGs/IconChevron";

export function SectionedMultiSelectCheckbox({
        label,
        options = {},  // The options now come as a grouped object
        value = [],
        setValue,
        classNames = '',
        onSelectionChange = () => {} // Callback for value change
    }) {
    // Combobox hook initialization
    const combobox = useCombobox({
        onDropdownClose: () => combobox.resetSelectedOption(),
        onDropdownOpen: () => combobox.updateSelectedOptionIndex('active'),
    });

    // Handle selection toggle for checkboxes
    const handleValueSelect = (val) => {
        const newValue = value.includes(val)
            ? value.filter((v) => v !== val)
            : [...value, val];

        setValue(newValue);
        onSelectionChange(newValue); // Trigger callback
    };

    const handleValueRemove = (val) => {
        const newValue = value.filter((v) => v !== val);
        setValue(newValue);
        onSelectionChange(newValue); // Trigger callback
    };

    const values = value.map((item) => (
        <span key={item}>
            {item}
        </span>
    ));

    // Generate options for each group dynamically based on the passed options object
    const generateOptions = (options, groupLabel) => (
        <Combobox.Group label={groupLabel}>
            <Combobox.Options classNames="h5 p-4">
                {options.map((item) => (
                    <Combobox.Option value={item} key={item} active={value.includes(item)}>
                        <Group gap="sm" className='flex-nowrap'>
                            <Checkbox
                                checked={value.includes(item)}
                                onChange={() => handleValueSelect(item)}
                                aria-hidden
                                tabIndex={-1}
                                style={{ pointerEvents: 'none' }}
                                iconColor="dark.8"
                                color="violet.6"
                            />
                            <span>{item}</span>
                        </Group>
                    </Combobox.Option>
                ))}
            </Combobox.Options>
        </Combobox.Group>
    );

    const Description = ({ length }) => {
        if (length === 1) {
            return <span className="pr-1 text-purple-10 truncate max-w-32 sm:max-w-48">{values[0]}</span>;
        } else if (length > 1) {
            return <span className="pr-1 text-purple-10 truncate max-w-32 sm:max-w-48">{values[0]} + {values.length - 1}</span>;
        } else {
            return <span className="pr-1">All</span>;
        }
    };

    const RightSide = ({ dropdownOpened }) => (
        <div className="flex pr-4">
            <Description length={values.length} />
            <IconChevron
                classname={dropdownOpened ? '' : 'rotate-180'}
                colour={values.length > 0 ? '#A48EFF' : 'white'}
            />
        </div>
    );

    return (
        <Combobox store={combobox} onOptionSubmit={handleValueSelect} withinPortal={false}>
            <Combobox.DropdownTarget>
                <Input
                    component="button"
                    classNames={{ input: 'bg-grey-gradient h5 px-4 py-2 h-fit ' + classNames, option: 'h5' }}
                    rightSection={<RightSide dropdownOpened={combobox.dropdownOpened} />}
                    rightSectionWidth="fit-content"
                    pointer
                    onClick={() => combobox.toggleDropdown()}
                >
                    {label}
                </Input>
            </Combobox.DropdownTarget>

            <Combobox.Dropdown className="static bg-grey-gradient">
                {Object.entries(options).map(([groupLabel, groupOptions]) => (
                    generateOptions(groupOptions, groupLabel)
                ))}
            </Combobox.Dropdown>
        </Combobox>
    );
}
