import React from "react";
import {Menu} from "@mantine/core";
import {MultiSelectCheckbox} from "../shared/MultiSelect";
import {IconFilter} from "../../SVGs/IconFilter";
import {RadioSelect} from "../shared/RadioSelect";
import { FILTER_LOCATION_TYPE, FILTER_MATCH_TYPE, FILTER_RESULTS,
    MATCH_SORT_OPTIONS,
} from "../../../constants";
import {YearSelect} from "../shared/YearSelect";

function MatchesFilter({
    opponents,
    selectedOpponents,
    setSelectedOpponents,
    selectedResult,
    setSelectedResult,
    selectedType,
    setSelectedType,
    selectedLocationType,
    setSelectedLocationType,
    series,
    selectedSeries,
    setSelectedSeries,
    selectedDates,
    setSelectedDates,
    selectedSort,
    setSelectedSort
                       }) {
    const handleReset = () => {
        setSelectedOpponents([]);
        setSelectedResult([]);
        setSelectedType(null);
        setSelectedLocationType(null);
        setSelectedSeries([]);
        setSelectedSort('Latest');
    }

    return (
        <Menu shadow="md" middlewares={{ flip: false, shift: true }}>
            <Menu.Target>
                <button className='button button--tertiary min-w-[100px] sm:min-w-auto px-2 sm:px-6 text-sm lg:text-base'>
                    <span className='!px-0'>Filter<span className='!hidden md:!inline-block !pl-1 !pr-0'> & sort</span><IconFilter /></span>
                </button>
            </Menu.Target>
            <Menu.Dropdown className="p-4 sm:p-8 bg-darkGrey rounded-xl">
                <div className="flex justify-between mb-3">
                    <h5>Filter by</h5>
                    <a className='underline text-purple-10 cursor-pointer' onClick={() => handleReset()}>Reset</a>
                </div>
                <MultiSelectCheckbox label='Opponent' selectOptions={opponents} value={selectedOpponents}
                                     setValue={setSelectedOpponents} classNames={'mb-2 min-w-72 sm:min-w-96'} />
                <MultiSelectCheckbox label='Result' selectOptions={FILTER_RESULTS} value={selectedResult}
                                     setValue={setSelectedResult} classNames={'mb-2'}/>
                <RadioSelect label='Type' selectOptions={FILTER_MATCH_TYPE} value={selectedType}
                             setValue={setSelectedType} clearable classNames={'mb-2'}/>
                <RadioSelect label='Location' selectOptions={FILTER_LOCATION_TYPE} value={selectedLocationType}
                             setValue={setSelectedLocationType} clearable classNames={'mb-2'}/>
                <MultiSelectCheckbox label='Series' selectOptions={series} value={selectedSeries}
                                     setValue={setSelectedSeries} classNames={'mb-2'}/>
                <YearSelect label='Date' value={selectedDates} setValue={setSelectedDates} />
                <Menu.Label className={'mt-10 mb-3'}>
                    <h5>Sort by</h5>
                </Menu.Label>
                <RadioSelect label='Order' selectOptions={MATCH_SORT_OPTIONS} value={selectedSort}
                             setValue={setSelectedSort}/>
            </Menu.Dropdown>
        </Menu>
    )
}

export default MatchesFilter;
