import React from "react";
import {Box} from "@mantine/core";
import {BorderGradient} from "../../SVGs/BorderGradient";

function Age({ row, renderedCellValue }) {

    return (
        <Box className='flex items-center'>
            <BorderGradient/>
            <div className="ml-12">
                {row.original.dod ? (
                    <div className='flex flex-col'>
                        <span>{renderedCellValue}</span>
                        <span className='text-xs text-grey-10'>{row.original.dod}</span>
                    </div>
                ) : (
                    <span>{renderedCellValue}</span>
                )}
            </div>
        </Box>
    )
}

export default Age;
