import React, { useEffect, useState, useRef } from 'react';
import { IconArrowDown } from "../SVGs/IconArrowDown";
import {IconArrowLeft} from "../SVGs/IconArrowLeft";

function PlayerPageHero({player, allPlayersLink}) {

    const photoRef = useRef(null);
    const shineRef = useRef(null);
    const statsColRef = useRef(null);
    const playerNameRef = useRef(null);
    const [minHeightClass, setMinHeightClass] = useState("min-h-[800px]");

    const badgeUrl = player.team === 'Black Ferns' ? '/assets/images/team_badges/badge--black-ferns.svg': '/assets/images/team_badges/badge--all-blacks.svg';

    useEffect(() => {
        if (playerNameRef.current) {
          const h1Height = playerNameRef.current.offsetHeight;
          if (h1Height > 100) {
            setMinHeightClass("min-h-[900px]");
          } else {
            setMinHeightClass("min-h-[800px]");
          }
        }
    }, []);

    return (
        <>
            <div className={`${minHeightClass} lg:min-h-0 md:max-w-screen-xl flex flex-col lg:grid lg:grid-cols-12 gap-4 mx-auto px-4 custom-block`}>
                <button
                    className="button--icon button--secondary mb-8 md:mb-0 self-start"
                    onClick={() => window.location.href = allPlayersLink}
                >
                    <IconArrowLeft/>
                </button>
                <div className="grid col-span-6 lg:col-span-12 self-center">
                    <div
                        className="bg-photo-radial-gradient rounded-lg col-start-2 col-span-4 lg:col-start-5 relative text-center  w-[267px] max-w-[90vw] max-h-[390px] md:w-[410px] md:max-w-[410px] md:h-[485px] md:max-h-[485px]">
                            <span
                                className="h2 absolute -top-[16px] -left-[30px] md:-top-[20px] md:-left-[65px] lg:-top-[20px] lg:-left-[98px] text-shadow-custom">#{player.playerNumber}</span>

                        <div ref={photoRef}
                             className="relative player-photo z-[-1] w-[267px] max-w-[267px] h-[390px] max-h-[390px] md:w-[410px] md:max-w-[410px] md:h-[485px] md:max-h-[485px] mb-4 lg:mb-2 transition-transform duration-300 ease">
                            <img
                                className="w-[267px] max-w-[267px] h-[390px] max-h-[390px] md:w-[410px] md:max-w-[410px] md:h-[485px] md:max-h-[485px] object-cover object-top rounded-lg"
                                src={player.profilePhoto} alt={player.playerName}/>
                            <div
                                ref={shineRef}
                                className="hidden lg:block absolute top-0 left-0 w-full h-full bg-gradient-to-r from-transparent via-white/30 to-transparent"
                            ></div>
                            <div className="lg:hidden mobile-gradient absolute top-0 left-0 right-0 bottom-0"></div>
                            {player.nickName && (
                                <div
                                    className="hidden lg:inline-block text-grey-10 text-3xl font-handwritten absolute -bottom-[70px] left-1/2 -translate-x-1/2 text-shadow-custom">
                                    {player.nickName}
                                </div>
                            )}
                        </div>

                        <div className="lg:absolute lg:left-[85%] lg:top-1/3 text-left">
                            <span className="h6 block mb-2 text-shadow-custom--darker lg:pl-2.5">{player.career}</span>
                            <h1 ref={playerNameRef} className="!text-4xl lg:!text-6xl lg:mb-1 lg:max-w-[459px] lg:break-words lg:pl-2.5 lg:pb-0.5">
                                {player.playerName}
                            </h1>
                            <span className="quote-text lg:hidden text-shadow-custom--darker">{player.nickName}</span>
                            <span
                                className="h4 text-grey-1 text-lg lg:text-2xl block text-shadow-custom mb-6 lg:pl-2.5">{player.position}</span>
                            <button className="!hidden !lg:block button--icon">
                                <IconArrowDown width={33} height={32}/>
                            </button>
                        </div>

                        <div ref={statsColRef}
                             className="stats-col flex flex-col mt-4 lg:mt-0 lg:absolute lg:h-full lg:-left-[120px] lg:top-[50px]">
                        <div className="gradient-border mb-4 py-5 px-8">
                                <ul className="flex lg:flex-col justify-between items-start">
                                    <li className="lg:mb-5 text-left">
                                        <span
                                            className="h4 lg:body block mb-2 lg:mb-0 font-normal text-grey-1">Height</span>
                                        <div className="flex items-baseline">
                                            {player.height ? (
                                                <>
                                                    <span
                                                        className="h4 text-[18px] lg:text-lg mr-1">{player.height}</span>
                                                    <span className="h6 text-grey-1">CM</span>
                                                </>
                                            ) : (
                                                <span className="h4 text-[18px] lg:text-lg mr-1">-</span>
                                            )}
                                        </div>
                                    </li>
                                    {/* Don’t show weight if Black Ferns player */}
                                    {player.team !== "Black Ferns" && (
                                        <li className="lg:mb-5 text-left">
                                        <span
                                            className="h4 lg:body block mb-2 lg:mb-0 font-normal text-grey-1">Weight</span>
                                            <div className="flex items-baseline">
                                                {player.weight ? (
                                                    <>
                                                        <span
                                                            className="h4 text-[18px] lg:text-lg mr-1">{player.weight}</span>
                                                        <span className="h6 text-grey-1">KG</span>
                                                    </>
                                                ) : (
                                                    <span className="h4 text-[18px] lg:text-lg mr-1">-</span>
                                                )}
                                            </div>
                                        </li>
                                    )}
                                    <li className="text-left">
                                            <span
                                                className="h4 lg:body block mb-2 lg:mb-0 font-normal text-grey-1">Age</span>
                                        <span className="h4 text-[18px] lg:text-lg">{player.age}</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="gradient-border flex lg:flex-col justify-between py-5 px-8">
                                <span className="hidden lg:block text-left text-grey-1 mb-1">Matches</span>
                                <div className="flex flex-col items-baseline mb-1">
                                        <span
                                            className="lg:hidden h4 lg:h6 font-normal text-grey-1 text-left">Matches</span>
                                    <div className="lg:flex items-baseline">
                                        <span className="h4 mr-1">{player.caps}</span>
                                        <span className="hidden lg:block h6 text-grey-1">Total</span>
                                    </div>
                                </div>


                                <div className="flex flex-col-reverse lg:flex-row items-baseline lg:mb-1">
                                    <span className="h4 mr-1 block lg:order-1">{player.testCaps}</span>
                                    <span className="h4 lg:h6 !text-grey-1 font-normal lg:order-2">Tests</span>
                                </div>
                                <div className="flex flex-col-reverse lg:flex-row items-baseline lg:mb-6">
                                    <span className="h4 mr-1 block lg:order-1">{player.nonTestCaps}</span>
                                    <span className="h4 lg:h6 !text-grey-1 font-normal lg:order-2">Games</span>
                                </div>
                                <div className="text-left hidden lg:block">
                                    <span className="text-grey-1 block">Total Points</span>
                                    <span className="h4 mr-1">{player.points}</span>
                                </div>
                            </div>
                        </div>

                        <img
                            className="w-[48px] h-[48px] lg:w-[120px] lg:h-[120px] team-badge absolute -right-[15px] -top-[15px] md:-top-[25px] md:-right-[25px] lg:-top-[50px] lg:-right-[50px]"
                            src={badgeUrl} alt={player.team}/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PlayerPageHero
